import React, { useState } from "react";
const energyOptions = [
  {
    name: "undefined kV",
    color: "#7a7a85",
  },
  {
    name: "<11kV",
    color: "#7696b8",
  },

  {
    name: "~33kV",
    color: "#64b652",
  },

  {
    name: "~66kV",
    color: "#b0a004",
  },
  {
    name: "~132kV",
    color: "#ac5e00",
  },
  {
    name: "~275kV",
    color: "#bb3132",
  },
  {
    name: "~400kV",
    color: "#ae4bb3",
  },
  {
    name: ">550kV",
    color: "#4dc1cf",
  },
  {
    name: "HVDC",
    color: "#5100b6",
  },
];

const PowerLinesLegend = () => {

  return (
    <div className="legend-main">
      {energyOptions.map((option, index) => (
        <div
          key={option.name}
          style={{
            // width: "286px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <div>{option.name}</div>
          <div style={{ justifyContent: "flex", marginRight: "5px" }}>
            <div
              className="legend-color"
              style={{ backgroundColor: option.color }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PowerLinesLegend;
