import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import "./myMap.css";
import { useEffect, useRef, useState } from "react";
import Button from "../Common/Button/Button";
import { DrowpdownIdon } from "../SVG";
import { styled } from '@mui/system';

const BasemapControl = ({ setShowSat, showSat }) => {
    const [showModal, setShowModal] = useState(false);

    const modalRef = useRef(null);

    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleShowAllSubstations = (e) => {
        setShowSat(e.target.value === 'true' ? true : false);
    };

    const SmallRadio = styled(Radio)(({ theme }) => ({
        '& .MuiSvgIcon-root': {
            fontSize: '1rem', // Adjust radio button size
        },
        '&.Mui-checked': {
            color: '#1F443A', // Custom color for checked state
        },
        '& .MuiRadio-colorPrimary.Mui-checked': {
            color: '#1F443A', // Custom color for checked state
        },
    }));

    const SmallFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
        '& .MuiFormControlLabel-label': {
            fontFamily: 'Eudoxus Sans', // Set font family
            fontSize: '12px', // Set font size
            fontWeight: 500, // Set font weight
            lineHeight: '16px', // Set line height
            textAlign: 'left', // Set text alignment
            color: '#151616',
        },
    }));

    return (
        <div ref={modalRef}>
            <div className="baseButton">
                <Button
                    height='32px'
                    width='120px'
                    bgColor='#fff'
                    color='#1E232E'
                    radius='5px'
                    borderColor='#fff'
                    fontSize='12.5px'
                    icon1={<DrowpdownIdon />}
                    text={showSat === true ? "Satellite View" : "Map View"}
                    handleClick={() => setShowModal((prev) => !prev)}
                />
            </div>

            {showModal && (
                <div className="baseModal">
                    <div className="modalContent">
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={showSat}
                                name="radio-buttons-group"
                                onChange={handleShowAllSubstations}
                            >
                                <SmallFormControlLabel
                                    value={false}
                                    control={<SmallRadio />}
                                    label="Map View"
                                />
                                <SmallFormControlLabel
                                    value={true}
                                    control={<SmallRadio />}
                                    label="Satellite View"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BasemapControl;