import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Flag from "react-world-flags";
import {
  setRightSideBarOpen,
  setTransmission,
  setSelectedSecondaryAquisitionLocation,
  setPromethousModal,
} from "../../Redux/reducers/projectsSlice";
import { useDispatch } from "react-redux";
import SatView from "../../MapCard/SatView";
import { setSelectedSubstation } from "../../Redux/reducers/selectedSubstation";
import {
  setPlanningData,
  setLandOwnerData,
  setSecondaryAcquisition,
} from "../../Redux/reducers/substationSlice";
import { setFilterValues } from "../../Redux/reducers/filterSlice";
import {
  fetchClosestLocalPlanningProjects,
  getNearestNcpr,
  getEnclosingPolygon,
  userSubstationClicked,
} from "../../Api/API";
import {
  BatteryIcon,
  DownIcon,
  FinanceIcon,
  OffOutlineClose,
  SuccessIcon,
} from "../../SVG";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { firstLetterCapital, fixed } from "../../Utils/Formats";
import axios from "axios";
import formatName from "../../Utils/NamesFormat";
import { findCountryCode } from "../../Utils/CountryList";
import { setButtonText } from "../../Redux/reducers/wireSchemeSlice";
import AppButton from "../../Common/Button/AppButton";

const SecondaryAquisition = () => {
  const dispatch = useDispatch();
  const substation = useSelector(
    (state) => state.selectedSubstation.substation
  );
  const wireScheme = useSelector((state) => state.wireScheme);

  const [landInfo, setLandInfo] = useState([]);
  const [loadingLandInfo, setLoadingLandInfo] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [subData, setSubData] = useState("");
  const [companyData, setCompanyData] = useState({ financialData: {} });
  const [expandedFinance, setExpandedFinance] = useState(false);
  const [expandedOfficers, setExpandedOfficers] = useState(false);
  const [expandedLand, setExpandedLand] = useState(false);
  const [processedCompanyData, setProcessedData] = useState([]);

  const handleLandChange = (panel, sub) => (event, isExpanded) => {
    setExpandedLand(isExpanded ? panel : false);
  };

  const handleChange = (panel, sub, companyName) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setSubData(sub);
    if (isExpanded) {
      const body = {
        company_name: companyName,
        // company_name: "Azeem Limited",
      };
      axios
        .post("https://companyapi.voltquant.uk/company", body)
        .then((res) => {
          const data = handleCompaniesData(res?.data?.financialData);
          setProcessedData(data);
          console.log(data, "asasasas");
          setCompanyData(res.data);
        })
        .catch((error) => {
          console.error("There was an error making the request:", error);
        });
    }
  };

  const handleFinanceChange = (panel, sub) => (event, isExpanded) => {
    setExpandedFinance(isExpanded ? panel : false);
  };
  const handleOfficersChange = (panel, sub) => (event, isExpanded) => {
    setExpandedOfficers(isExpanded ? panel : false);
  };
  const handleButtons = (text) => {
    if (text !== wireScheme.privateWireButtonText) {
      dispatch(setButtonText(text));
      dispatch(setSecondaryAcquisition([]));
    }
  };
  const handleFillingHistory = () => {
    window.open(
      `https://find-and-update.company-information.service.gov.uk/company/${companyData?.companyProfile?.company_number}/filing-history`,
      "_blank"
    );
  };
  const calculatePercentageChange = (previous, current) => {
    const prev = parseFloat(previous.replace(/,/g, ""));
    const curr = parseFloat(current.replace(/,/g, ""));

    if (isNaN(prev) || isNaN(curr)) {
      return "N/A";
    }

    const change = ((curr - prev) / prev) * 100;
    return change.toFixed(2) + "%";
  };

  const handleCompaniesData = (data) => {
    if (!data) return [];
    return Object.entries(data)?.map(([key, values]) => {
      let current;
      let previous;

      if (values?.length > 0) {
        // If there are two arrays, consider the first as current and the second as previous
        current = values?.[0]?.[1] || "";
        previous = values?.[1]?.[1] || "";
      }
      // const previous =
      //   values.find(
      //     ([period]) =>
      //       period === "PREVIOUS_FY_END" ||
      //       period === "PREVIOUS_FY_PERIOD" ||
      //       period === "PY_END"
      //   )?.[1] || "";
      // const current =
      //   values.find(
      //     ([period]) =>
      //       period === "CURRENT_FY_END" ||
      //       period === "CURRENT_FY_PERIOD" ||
      //       period === "CY_END" ||
      //       period === "CY"
      //   )?.[1] || "";
      const percentageChange = calculatePercentageChange(previous, current);
      return {
        name: formatName(key),
        previous,
        current,
        percentageChange,
      };
    });
  };

  const projects = useSelector((state) => state.projects);
  const filters = useSelector((state) => state.filters);
  const substations = useSelector((state) => state.substations);
  const promethousAIClick = useSelector(
    (state) => state.projects.promethousAIClick
  );
  const Substations = useSelector((state) => state.substations);

  console.log("this setPromethousAIClick:", substations);
  useEffect(() => {
    if (substation) {
      dispatch(setRightSideBarOpen(true));
      let body = {
        substationId: substation?.SubstationID,
      };
      userSubstationClicked(body).then((res) => {
        if (res.success) {
          console.log("Substation Clicked");
        }
      });
    }
  }, [substation]); // eslint-disable-line react-hooks/exhaustive-deps

  const findLocalPlanningClosestPoints = (substation) => {
    let body = {
      substation,
    };

    fetchClosestLocalPlanningProjects(body)
      .then((response) => {
        if (response.success) {
          dispatch(setPlanningData(response?.data?.closestProjects));
        }
      })
      .catch((err) => {
        dispatch(setPlanningData([]));
        console.log(err);
      });
  };
  const findNearestNcpr = (substation) => {
    let body = {
      latitude: substation.Lattitude,
      longitude: substation.Longitute,
    };
    getNearestNcpr(body)
      .then((response) => {
        if (response.success) {
          dispatch(setPlanningData(response?.data));
        } else {
          dispatch(setPlanningData({}));
        }
      })
      .catch((err) => {
        dispatch(setPlanningData({}));
        console.log(err);
      });
  };

  const findEnclosingPolygon = (substation) => {
    setLoadingLandInfo((prev) => !prev);
    let body = {
      latitude: substation?.location_y
        ? substation?.location_y
        : substation.Lattitude,
      longitude: substation?.location_x
        ? substation?.location_x
        : substation.Longitute,
    };

    getEnclosingPolygon(body)
      .then((response) => {
        if (response.success) {
          console.log("Enclosing Api response", response);
          dispatch(setLandOwnerData(response?.data));
          setLandInfo(response?.data);
        } else {
          dispatch(setLandOwnerData([]));
          setLandInfo([]);
        }
      })
      .catch((err) => {
        dispatch(setLandOwnerData([]));
        setLandInfo([]);
        console.log("Error in Polygon Api: ", err);
      });
    setLoadingLandInfo((prev) => !prev);
  };

  useEffect(() => {
    if (promethousAIClick && substations.substations.length > 0) {
      const sorted = [...substations.substations].sort(
        (a, b) => b.iq_score - a.iq_score
      );
      if (sorted.length > 0) {
        if (filters.assetType === "EV Infrastructure") {
          findNearestNcpr(sorted[0]);
        } else {
          findLocalPlanningClosestPoints(sorted[0]);
        }
        dispatch(
          setFilterValues({
            source: sorted[0]?.Source,
            voltageLine: sorted[0]?.VoltageLine,
          })
        );
        dispatch(setSelectedSubstation(sorted[0]));
      }
    }
  }, [promethousAIClick]);

  return (
    <div className={projects?.rightSidebarOpen ? styles.open : ""}>
      <div
        className={styles.right}
        style={{
          display: substations?.substations?.length > 0 ? "block" : "none",
        }}
      >
        <div className={styles.top}>
          <div className={styles.result}>
            <div className={styles.result__left}>
              <p className={styles.result__left__heading}>
                Secondary Acquisitions
              </p>
            </div>

            <div className={styles.result__right}>
              <OffOutlineClose
                onClick={() => {
                  dispatch(setTransmission(""));
                  dispatch(setPromethousModal(true));
                  dispatch(setSelectedSecondaryAquisitionLocation(undefined));
                }}
              />
            </div>
          </div>
        </div>
        {/* <div style={{ paddingRight: "10px" }}>
          <Divider />
        </div> */}

        <div style={{ paddingRight: "10px" }}>
          <div>
            <div
              style={{
                display: "flex",
                gap: 5,
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <AppButton
                key="01"
                text={"Accepted To Connect"}
                onClick={() => handleButtons("Accepted To Connect")}
                isSelected={
                  wireScheme.privateWireButtonText === "Accepted To Connect"
                    ? true
                    : false
                }
                variant={"small"}
                fontSize={10}
                id={"23"}
              />

              <AppButton
                key="01"
                text={"Connected"}
                onClick={() => handleButtons("Connected")}
                isSelected={
                  wireScheme.privateWireButtonText === "Connected"
                    ? true
                    : false
                }
                variant={"small"}
                fontSize={10}
                id={"24"}
              />
              <AppButton
                key="01"
                text={"Planning"}
                onClick={() => handleButtons("Planning Only")}
                isSelected={
                  wireScheme.privateWireButtonText === "Planning Only"
                    ? true
                    : false
                }
                fontSize={10}
                variant={"small"}
                id={"24"}
              />
            </div>
            {substations?.secondaryAcquisitions?.length > 0 ? (
              <div style={{ width: "100%", paddingLeft: "1px" }}>
                {substations.secondaryAcquisitions?.map((data, index) => {
                  return (
                    <div
                      style={{ marginBottom: "20px" }}
                      onClick={() => {
                        findEnclosingPolygon(data);
                        dispatch(setSelectedSecondaryAquisitionLocation(data));
                      }}
                    >
                      <Accordion
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(
                          `panel${index}`,
                          data.distance,
                          data?.["Customer Name"]
                        )}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <BatteryIcon
                              color={
                                data?.Source === "ECR"
                                  ? "#0AED24"
                                  : data?.Source === "REPD"
                                    ? "#1D5538"
                                    : data?.Source === "TEC"
                                      ? "#141517"
                                      : "#0AED24"
                              }
                            />
                            <div>
                              <p className={styles.planningCardz__heading}>
                                {fixed(
                                  Number(
                                    data?.[
                                    "Energy Source & Energy Conversion Technology 1 - Registered Capacity (MW)"
                                    ]
                                  ),
                                  2
                                )}{" "}
                                MW {data?.["Energy Conversion Technology 1"]}{" "}
                                Site
                              </p>
                              <p
                                className={styles.planningCardz__heading__sub1}
                              >
                                {data.townCity === "data not available"
                                  ? ""
                                  : data.la_name}{" "}
                                <li>{fixed(data.distance, 2)} km</li>
                              </p>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          {data.distance === subData && (
                            <div
                              className={styles.planningCardz__imageContainer}
                            >
                              <SatView data={data} />
                              {/* <h3>Residential building</h3> */}
                            </div>
                          )}

                          <div className={styles.planningCardz__roadContainer}>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Grid Supply Point
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.["Grid Supply Point"]?.length > 0
                                  ? data?.["Grid Supply Point"]
                                  : "--"}
                              </p>
                            </div>

                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Bulk Supply Point
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.["Bulk Supply Point"]?.length > 0
                                  ? data?.["Bulk Supply Point"]
                                  : "--"}
                                {/* {data?.["Bulk Supply Point"]} */}
                              </p>
                            </div>

                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Primary Substation
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data.Primary?.length > 0 ? data.Primary : "--"}
                              </p>
                            </div>

                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Point of Connection
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.[
                                  "Point of Connection (POC) Voltage (kV)"
                                ].length > 0
                                  ? fixed(
                                    Number(
                                      data?.[
                                      "Point of Connection (POC) Voltage (kV)"
                                      ]
                                    ),
                                    2
                                  )
                                  : "--"}
                                kV
                              </p>
                            </div>

                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Project Type
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.["Energy Conversion Technology 1"]
                                  .length > 0
                                  ? data?.["Energy Conversion Technology 1"]
                                  : "--"}
                              </p>
                            </div>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Capacity
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.[
                                  "Energy Source & Energy Conversion Technology 1 - Registered Capacity (MW)"
                                ].length > 0
                                  ? fixed(
                                    Number(
                                      data?.[
                                      "Energy Source & Energy Conversion Technology 1 - Registered Capacity (MW)"
                                      ]
                                    ),
                                    2
                                  )
                                  : "--"}
                                MW
                              </p>
                            </div>

                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Accepted Date
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.DateAcceptedCorrect?.length > 0
                                  ? data?.DateAcceptedCorrect
                                  : "--"}
                              </p>
                            </div>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Connection Date
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {/* £ {Number(data?.EnergisationDateCorrect).toLocaleString()} */}
                                {data.EnergisationDateCorrect?.length > 0
                                  ? data.EnergisationDateCorrect
                                  : "--"}
                              </p>
                            </div>
                            <br />

                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Licence Area
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.["Licence Area"]?.length > 0
                                  ? data?.["Licence Area"]
                                  : "--"}
                              </p>
                            </div>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Grid Status
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.["Connection Status"]?.length > 0
                                  ? data?.["Connection Status"]
                                  : "--"}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                margin: "15px 0px 10px 0px",
                              }}
                            >
                              <Divider />
                            </div>

                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Customer Name
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.["Customer Name"]?.length > 0
                                  ? data?.["Customer Name"]
                                  : "--"}
                              </p>
                            </div>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Site Name
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.["Customer Site"]?.length > 0
                                  ? data?.["Customer Site"]
                                  : "--"}
                              </p>
                            </div>

                            {data.addressLine1 !== "data not available" && (
                              <div
                                className={
                                  styles.planningCardz__roadContainer__ppp
                                }
                              >
                                <p
                                  className={
                                    styles.planningCardz__roadContainer__ppp__pone
                                  }
                                >
                                  Address line 1
                                </p>
                                <p
                                  className={
                                    styles.planningCardz__roadContainer__ppp__ptwo
                                  }
                                >
                                  {data?.["Address Line 1"]?.length > 0
                                    ? data?.["Address Line 1"]
                                    : "--"}
                                </p>
                              </div>
                            )}
                            {data.addressLine2 !== "data not available" && (
                              <div
                                className={
                                  styles.planningCardz__roadContainer__ppp
                                }
                              >
                                <p
                                  className={
                                    styles.planningCardz__roadContainer__ppp__pone
                                  }
                                >
                                  Address line 2
                                </p>
                                <p
                                  className={
                                    styles.planningCardz__roadContainer__ppp__ptwo
                                  }
                                >
                                  {data?.["Address Line 2"]?.length > 0
                                    ? data?.["Address Line 2"]
                                    : "--"}
                                </p>
                              </div>
                            )}
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Town/City
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.["Town/ City"]?.length > 0
                                  ? data?.["Town/ City"]
                                  : "--"}
                              </p>
                            </div>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Country
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.Country?.length > 0
                                  ? data?.Country
                                  : "--"}
                              </p>
                            </div>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Post Code
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.Postcode?.length > 0
                                  ? data?.Postcode
                                  : "--"}
                              </p>
                            </div>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Local Authority
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.la_name?.length > 0
                                  ? data?.la_name
                                  : "--"}
                              </p>
                            </div>

                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Planning Status
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {data?.["Development Status (short)"]?.length >
                                  0
                                  ? data?.["Development Status (short)"]
                                  : "--"}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                margin: "15px 0px 10px 0px",
                              }}
                            >
                              <Divider />
                            </div>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Company Name
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {companyData?.companyProfile?.company_name
                                  ?.length > 0
                                  ? companyData?.companyProfile?.company_name
                                  : "--"}
                              </p>
                            </div>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Company ID
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {companyData?.companyId?.length > 0
                                  ? companyData?.companyId
                                  : "--"}
                              </p>
                            </div>

                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Company Status
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {companyData?.companyProfile?.company_status
                                  ?.length > 0
                                  ? companyData?.companyProfile?.company_status
                                  : "--"}
                              </p>
                            </div>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Company Type
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {companyData?.companyProfile?.type?.length > 0
                                  ? companyData?.companyProfile?.type
                                  : "--"}
                              </p>
                            </div>
                            <div
                              className={
                                styles.planningCardz__roadContainer__ppp
                              }
                            >
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__pone
                                }
                              >
                                Previous Name
                              </p>
                              <p
                                className={
                                  styles.planningCardz__roadContainer__ppp__ptwo
                                }
                              >
                                {companyData?.companyProfile
                                  ?.previous_company_names?.length > 0
                                  ? companyData?.companyProfile
                                    ?.previous_company_names?.[0].name
                                  : "--"}
                              </p>
                            </div>

                            <div
                              style={{
                                width: "100%",
                                margin: "15px 0px 10px 0px",
                              }}
                            >
                              <Divider />
                            </div>
                            <Accordion
                              expanded={expandedFinance === `panel${index}0`}
                              onChange={handleFinanceChange(`panel${index}0`)}
                              style={{ width: '100%' }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel2bh-header"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <FinanceIcon
                                    color={
                                      companyData?.companyProfile?.accounts
                                        ?.last_accounts?.type == "full" &&
                                        processedCompanyData?.length <= 0
                                        ? "#FFA500"
                                        : companyData?.companyProfile?.accounts
                                          ?.last_accounts?.type != "null" &&
                                          processedCompanyData?.length <= 0
                                          ? "#FFA500"
                                          : companyData?.companyProfile?.accounts
                                            ?.last_accounts?.type == "null" &&
                                            processedCompanyData?.length <= 0
                                            ? "#ff0000"
                                            : "#0AED24"
                                    }
                                  />

                                  <div>
                                    <p
                                      className={styles.planningCardz__heading}
                                    >
                                      Financial Data
                                    </p>
                                  </div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {processedCompanyData?.length > 0 ? (
                                    processedCompanyData
                                      ?.filter(
                                        (data) =>
                                          data.name !==
                                          "U K Companies House Registered Number" &&
                                          data.name !==
                                          "Average Number Employees During Period"
                                      )
                                      .map((data, index) => {
                                        return (
                                          <>
                                            {data.percentageChange !== "N/A" &&
                                              data.percentageChange !==
                                              "NaN%" && (
                                                <div
                                                  className={
                                                    styles.planningCardz__roadContainer__ppp
                                                  }
                                                  key={index}
                                                >
                                                  <p
                                                    className={
                                                      styles.planningCardz__roadContainer__ppp__pone
                                                    }
                                                  >
                                                    {data.name}
                                                  </p>

                                                  <p
                                                    className={
                                                      styles.planningCardz__roadContainer__ppp__ptwo
                                                    }
                                                  // style={{ fontSize: "20px" }}
                                                  >
                                                    £ {data?.current}
                                                  </p>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      gap: "5px",
                                                    }}
                                                  >
                                                    <div>
                                                      {data?.percentageChange >
                                                        "0" ? (
                                                        <SuccessIcon />
                                                      ) : (
                                                        <DownIcon />
                                                      )}
                                                    </div>
                                                    <p
                                                      style={{
                                                        fontSize: "14px",
                                                        margin: "0px",
                                                      }}
                                                    >
                                                      £
                                                      {(
                                                        parseFloat(
                                                          data.current.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        ) -
                                                        parseFloat(
                                                          data.previous.replace(
                                                            /,/g,
                                                            ""
                                                          )
                                                        )
                                                      ).toLocaleString()}
                                                      {`(${data?.percentageChange})`}
                                                    </p>
                                                  </div>
                                                  <p
                                                    style={{
                                                      color: "grey",
                                                      fontSize: "12px",
                                                      paddingTop: 0,
                                                      marginTop: 0,
                                                    }}
                                                  >
                                                    vs previous year
                                                  </p>
                                                </div>
                                              )}
                                          </>
                                        );
                                      })
                                  ) : (companyData?.companyProfile?.accounts
                                    ?.last_accounts?.type == "full" ||
                                    companyData?.companyProfile?.accounts
                                      ?.last_accounts?.type != "null") &&
                                    processedCompanyData?.length <= 0 ? (
                                    <div>
                                      <p style={{ color: "grey" }}>
                                        Sorry, there are no filings available
                                        for this company. This is common for
                                        newly incorporated companies and dormant
                                        companies. Some micro-entities qualify
                                        for exemptions. Alternatively this could
                                        be due to a recently struck
                                        off/dissolved business or where there
                                        were filling errors. Please confirm
                                        through the below button.
                                      </p>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: 5,
                                          // justifyContent: "center",
                                        }}
                                      >
                                        <AppButton
                                          key="01"
                                          text={"See filings history"}
                                          onClick={handleFillingHistory}
                                          isSelected={true}
                                          variant={"small"}
                                          id={"23"}
                                        />
                                      </div>
                                    </div>
                                  ) : (companyData?.companyProfile?.accounts
                                    ?.last_accounts?.type == "null" ||
                                    companyData?.companyProfile?.accounts
                                      ?.last_accounts?.type != "full") &&
                                    processedCompanyData?.length <= 0 ? (
                                    <>
                                      <div>
                                        <p style={{ color: "grey" }}>
                                          Sorry, there is no fillings available
                                          for this company. This is common for
                                          newly incorporated companies and
                                          dormant companies. Some micro-entities
                                          qualify for exemptions. Alternatively
                                          this could be due to a recently struck
                                          off/dissolved business or where there
                                          was filing errors.
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </AccordionDetails>
                            </Accordion>

                            <div
                              style={{
                                width: "100%",
                                margin: "15px 0px 10px 0px",
                              }}
                            >
                              <Divider />
                            </div>
                            <Accordion
                              expanded={expandedOfficers === `panel${index}1`}
                              onChange={handleOfficersChange(`panel${index}1`)}
                              style={{ width: '100%' }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel3bh-header"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <FinanceIcon
                                    color={
                                      companyData?.officers?.items?.length > 0
                                        ? "#0AED24"
                                        : "#FFA500"
                                    }
                                  />

                                  <div>
                                    <p
                                      className={styles.planningCardz__heading}
                                    >
                                      Officers & People
                                    </p>
                                  </div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div
                                  style={{
                                    // display: "flex",
                                    // flexWrap: "wrap",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {companyData?.officers?.items?.map(
                                    (data, index) => {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            background: "#F5F5F4",
                                            marginTop: "4px",
                                            borderRadius: "10px",
                                            padding: "10px",
                                          }}
                                        >
                                          <div style={{ width: "10%" }}>
                                            <div
                                              className="circle"
                                              style={{
                                                height: "15px",
                                                width: "15px",
                                                background: `${data?.resigned_on
                                                  ? "grey"
                                                  : "green"
                                                  }`,
                                                borderRadius: "50%",
                                                margin: "5px",
                                              }}
                                            ></div>
                                          </div>
                                          <div style={{ width: "90%" }}>
                                            <p
                                              style={{ margin: 0 }}
                                              className={
                                                styles.planningCardz__roadContainer__ppp__ptwo
                                              }
                                            >
                                              {data.name}
                                            </p>
                                            <div
                                              style={{
                                                display: "flex",
                                                marginTop: "3px",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Flag
                                                height={"12px"}
                                                code={findCountryCode(
                                                  data?.address?.country
                                                )}
                                              />
                                              <span
                                                style={{
                                                  color: "grey",
                                                  fontSize: "12px",
                                                  paddingTop: 0,
                                                  marginTop: 0,
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                {data?.country_of_residence}
                                              </span>
                                            </div>
                                            <p
                                              style={{
                                                fontSize: "14px",
                                                margin: "0px",
                                              }}
                                            >
                                              {data?.occupation}
                                            </p>
                                            <div
                                              style={{
                                                width: "100%",
                                                margin: "2px 0px 10px 0px",
                                              }}
                                            >
                                              <Divider />
                                            </div>

                                            <p
                                              style={{
                                                fontSize: "14px",
                                                margin: "0px",
                                              }}
                                            >
                                              {firstLetterCapital(
                                                data?.officer_role
                                              )}
                                            </p>
                                            {data?.resigned_on ? (
                                              <p
                                                style={{
                                                  color: "grey",
                                                  fontSize: "12px",
                                                  paddingTop: 0,
                                                  marginTop: 0,
                                                }}
                                              >
                                                Period:{" "}
                                                {data?.appointed_on ||
                                                  data?.appointed_before}{" "}
                                                to {data?.resigned_on}
                                              </p>
                                            ) : (
                                              <p
                                                style={{
                                                  color: "grey",
                                                  fontSize: "12px",
                                                  paddingTop: 0,
                                                  marginTop: 0,
                                                }}
                                              >
                                                Period: {data?.appointed_on} --
                                                Active
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}

                                </div>
                              </AccordionDetails>
                            </Accordion>

                            <div
                              style={{
                                width: "100%",
                                margin: "15px 0px 10px 0px",
                              }}
                            >
                              <Divider />
                            </div>
                            {landInfo.length > 0 ? (
                              <div
                                style={{ width: "100%", paddingLeft: "1px" }}
                              >
                                {landInfo?.map((data, index) => {
                                  return (
                                    <div style={{ marginBottom: "20px" }}>
                                      <Accordion
                                        expanded={
                                          expandedLand === `panel${index}1`
                                        }
                                        onChange={handleLandChange(
                                          `panel${index}1`
                                        )}
                                        style={{ width: '100%' }}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel3bh-header"
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            <FinanceIcon color={"#0AED24"} />
                                            <div>
                                              <p
                                                className={
                                                  styles.planningCardz__heading
                                                }
                                              >
                                                {expandedLand
                                                  ? data?.titleNumber
                                                  : "Land Ownership"}
                                              </p>
                                            </div>
                                          </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <div
                                            className={
                                              styles.planningCardz__roadContainer__ppp
                                            }
                                          >
                                            <p
                                              className={
                                                styles.planningCardz__roadContainer__ppp__ptwo
                                              }
                                            >
                                              {data?.ccod[0]?.Tenure.length > 0
                                                ? data?.ccod[0]?.Tenure
                                                : data?.ocod[0]?.Tenure
                                                  ? data?.ocod[0]?.Tenure
                                                  : "Privately Owned"}
                                            </p>
                                          </div>
                                          <div
                                            className={
                                              styles.planningCardz__roadContainer__ppp
                                            }
                                          >
                                            <p
                                              className={
                                                styles.planningCardz__roadContainer__ppp__pone
                                              }
                                            >
                                              Updated on:
                                            </p>
                                            <p
                                              className={
                                                styles.planningCardz__roadContainer__ppp__ptwo
                                              }
                                            >
                                              {data?.["lastUpdate"]?.length > 0
                                                ? data?.["lastUpdate"].split(
                                                  "T"
                                                )[0]
                                                : "--"}
                                            </p>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingBottom: "30px",
                                }}
                              >
                                <p style={{ marginTop: "30px" }}>
                                  {loadingLandInfo === "true"
                                    ? "Loading"
                                    : "No Land Data Available"}
                                </p>
                              </div>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "300px",
                }}
              >
                <p style={{ marginTop: "300px" }}>
                  {wireScheme.isLoading === "true"
                    ? "Loading"
                    : "No Data Available"}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondaryAquisition;
