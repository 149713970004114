import React, { useState } from "react";
import comms_tower from "../../Images/power/comms_tower.svg";
const energyOptions = [
  {
    name: "Cable",
    image:
      "/icons/cable.PNG",
  },
  {
    name: "Tower/Mast",
    image: comms_tower,
  },

  {
    name: "Datacenter/Exchange",
    image:
      "/icons/datacenter.PNG",
  },
];

const TelecomLegend = () => {
  return (
    <div className="legend-main">
      {energyOptions.map((option, index) => (
        <div
          key={option.name}
          style={{
            // width: "286px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <div>{option.name}</div>
          <div style={{ justifyContent: "flex", marginRight: "10px" }}>
            <img height={28} width={40} src={option.image} alt="icon-power" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TelecomLegend;
