import { createSlice } from "@reduxjs/toolkit";

const LLMSlice = createSlice({
  name: "LLM",
  initialState: {
    projectName: "",
    formData: "",
    activeTab: "HAR2",
    loading: "false",
    gridMessages: [
      {
        user: "assistant",
        message:
          "Hello! How can I assist you today with your hydrogen project? Whether you need information on grid connection requirements, application processes, or any other aspect, feel free to ask!",
      },
    ],
    planningMessages: [
      {
        user: "assistant",
        message:
          "Hello! How can I assist you today? If you have any questions about hydrogen projects or need help navigating related information, feel free to ask!",
      },
    ],
    offtakersMessages: [
      {
        user: "assistant",
        message:
          "Hello! How can I assist you today? If you have any questions regarding finding high-use industrial areas or need help with something specific, feel free to let me know!",
      },
    ],
    pipelineMessages: [
      {
        user: "assistant",
        message:
          "Please upload the application guide for the HAR2, and any other relevant documents",
      },
    ],
    tableData: [],
    threads: [],
    activeThread: [{}],
    leftOpen: true,
    textEditor: [],
    selectedText: '',
    editorText: ''
  },
  reducers: {
    setProjectName: (state, action) => {
      state.projectName = action.payload;
    },
    setFormData: (state, action) => {
      return { ...state, formData: action.payload };
    },
    setGrdMessages: (state, action) => {
      return { ...state, gridMessages: action.payload };
    },
    setPlanningMessages: (state, action) => {
      return { ...state, planningMessages: action.payload };
    },
    setOfftakersMessages: (state, action) => {
      return { ...state, offtakersMessages: action.payload };
    },
    setPipelineMessages: (state, action) => {
      return { ...state, pipelineMessages: action.payload };
    },
    setActiveTab: (state, action) => {
      return { ...state, activeTab: action.payload };
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLeftOpen: (state, action) => {
      state.leftOpen = action.payload;
    },
    setThreads: (state, action) => {
      // return { ...state, threads: [...state.threads, action.payload] };
      return { ...state, threads: action.payload };
    },
    updateThread: (state, action) => {
      return { ...state, threads: [...state.threads, action.payload] };
    },
    setActiveThread: (state, action) => {
      return { ...state, activeThread: [action.payload] };
    },
    setMessageToThread: (state, action) => {
      // Find the thread by id
      const updatedThreads = state.threads?.map((thread) => {
        if (thread.id === action.payload.threadId) {
          // Append new message to the content array of the matched thread
          return {
            ...thread,
            content: [...thread.content, action.payload.newMessage],
          };
        }
        return thread; // Return other threads unchanged
      });
      const activeThread = updatedThreads.find(
        (thread) => thread.id === action.payload.threadId
      );

      return {
        ...state,
        threads: updatedThreads, // Update the threads with the new message
        activeThread: activeThread ? [activeThread] : state.activeThread, // Update the activeThread if it matches the threadId
      };
    },
    deleteThread: (state, action) => {
      // Filter out the thread with the given ID
      const updatedThreads = state.threads.filter(
        (thread) => thread.id !== action.payload.threadId
      );

      // Update activeThread if the currently active thread is the one being deleted
      let updatedActiveThread = state.activeThread;
      if (state.activeThread[0]?.id === action.payload.threadId) {
        updatedActiveThread = [{}]; // Reset the activeThread if it was deleted
      }

      return {
        ...state,
        threads: updatedThreads, // Update the threads array
        activeThread: updatedActiveThread, // Update the activeThread if necessary
      };
    },
    setThreadName: (state, action) => {
      // Update the name of the thread in the threads array
      const updatedThreads = state.threads.map((thread) => {
        if (thread.id === action.payload.threadId) {
          return {
            ...thread,
            name: action.payload.newName, // Update the thread name
          };
        }
        return thread;
      });

      // Update the active thread if the current active thread is the one being updated
      const activeThread = updatedThreads.find(
        (thread) => thread.id === action.payload.threadId
      );

      return {
        ...state,
        threads: updatedThreads, // Update the threads array with the updated name
        activeThread: activeThread ? [activeThread] : state.activeThread, // Update the activeThread if necessary
      };
    },
    setTextEditor: (state, action) => {
      state.textEditor = action.payload;
    },
    setSelectedText: (state, action) => {
      state.selectedText = action.payload;
    },
    setEditorText: (state, action) => {
      state.editorText = action.payload;
    },
  },
});
export const {
  setProjectName,
  setFormData,
  setGrdMessages,
  setPlanningMessages,
  setOfftakersMessages,
  setPipelineMessages,
  setActiveTab,
  setTableData,
  setActiveThread,
  setThreads,
  setMessageToThread,
  deleteThread,
  setLoading,
  setThreadName,
  setLeftOpen,
  setTextEditor,
  setSelectedText,
  updateThread,
  setEditorText,
} = LLMSlice.actions;
export default LLMSlice.reducer;
