import React from "react";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { VLogo } from "../../SVG";

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    // fontSize: "60px",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderLeft: "10px solid black",
  },
  "& .after": {
    borderLeft: "10px solid black",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    content: '""',
    display: "block",
    position: "absolute",
    top: "50%",
    left: "-10px",
    width: "10px",
    height: "10px",
    transform: "translate(-50%, -50%)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderRadius: 10,
    // margin: margin,
    boxShadow: theme.shadows[1],
    // border: "1px solid black",

    fontSize: 14,
    fontWeight: "bold",
    padding: "15px",
  },
}));
const Stamford = (props) => {
  const {
    name,
    percentage,
    active,
    padding,
    voltageLine,
    source,
    constrainedType
  } = props;
  const filters = useSelector((state) => state.filters);
  const isIQAvailable = filters.riskAndIQ.includes("IQ");

  return (
    <div
      className={active ? styles.active : ""}
      style={{
        marginTop: !padding ? "20px" : "",
        padding: padding ? "20px" : "",
      }}
    >
      <div className={styles.mainStamford}>
        <div style={{ display: "flex", alignItems: "center", width: "100%", gap: '10px' }}>
          <VLogo />
          <div style={{ width: "100%" }}>
            <div className={styles.stamford}>
              <p className={styles.stamford__heading}>{name}</p>
              <LightTooltip
                title="IQ Score determines the most suitable substation for a specific search. It evaluates various factors, including proximity to the desired location, the route for connection, estimated connection time, among others."
                placement="left-start"
                arrow
                margin="20px"
              >
                {isIQAvailable && (
                  <button className={styles.stamford__button}>
                    IQ: {percentage}%
                  </button>
                )}
              </LightTooltip>
            </div>
            <div className={styles.stamford}>
              {voltageLine !== "undefined" && voltageLine && (
                <p className={styles.stamford__para}>
                  {`${voltageLine} kV Site Voltage`}
                </p>
              )}
              <p className={styles.stamford__price}>{source}</p>
            </div>
            <p
              style={{
                margin: 0,
                fontSize: "14px",
                fontWeight: 600,
                color: `${
                  constrainedType === "Constrained"
                    ? "#C42C22"
                    : constrainedType === "Partially Constrained"
                    ? "#FAB239"
                    : constrainedType === "Unconstrained"
                    ? "#1D443A"
                    : "black"
                }`,
              }}
            >
              {constrainedType}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Stamford;
