import { createSlice } from "@reduxjs/toolkit";

const selectedLayersSlice = createSlice({
  name: "selectedLayers",
  initialState: {
    categorizedLayers: [],
    filteredLayers: [],
    selectedLayers: [],
    geoSpatialRisk: [],
    layerButtons: []
  },
  reducers: {
    setCategorizedLayers: (state, action) => {
      state.categorizedLayers = action.payload;
    },
    setFilteredLayers: (state, action) => {
      state.filteredLayers = action.payload;
    },
    setSelectedLayers: (state, action) => {
      state.selectedLayers = action.payload;
    },
    setGeospatialLayers: (state, action) => {
      state.geoSpatialRisk = action.payload;
    },
    setLayersButton: (state, action) => {
      state.layerButtons = action.payload;
    },
  },
});

export const { setCategorizedLayers,setFilteredLayers,setSelectedLayers,setGeospatialLayers,setLayersButton } = selectedLayersSlice.actions;
export default selectedLayersSlice.reducer;