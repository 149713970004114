import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  setRightSideBarOpen,
  setSelectedSecondaryAquisitionLocation,
  setPromethousAIClick,
} from "../../Redux/reducers/projectsSlice";
import { useDispatch } from "react-redux";
import { setSelectedSubstation } from "../../Redux/reducers/selectedSubstation";
import { setPlanningData } from "../../Redux/reducers/substationSlice";
import { setFilterValues } from "../../Redux/reducers/filterSlice";
import {
  fetchClosestLocalPlanningProjects,
  getNearestNcpr,
  userSubstationClicked,
} from "../../Api/API";
import {
  ExpandCircleDownIcon,
  OffOutlineClose,
} from "../../SVG";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { fixed } from "../../Utils/Formats";
import { setGeospatialLayers } from "../../Redux/reducers/selectedLayers";

const Planning = () => {
  const dispatch = useDispatch();
  const substation = useSelector(
    (state) => state.selectedSubstation.substation
  );
  const [expanded, setExpanded] = useState(false);
  const [subData, setSubData] = useState("");
  const handleChange = (panel, sub) => (event, isExpanded) => {
    // console.log("selectedSubstation in working")
    setExpanded(isExpanded ? panel : false);
    setSubData(sub);
  };

  const projects = useSelector((state) => state.projects);
  const filters = useSelector((state) => state.filters);
  const substations = useSelector((state) => state.substations);
  const promethousAIClick = useSelector(
    (state) => state.projects.promethousAIClick
  );
  // console.log("this setPromethousAIClick:", substations);
  useEffect(() => {
    if (substation?.name?.length > 0) {
      handleChange(substation?.name, substation?.distance)(null, true);
    }

  }, [substation])

  useEffect(() => {
    if (substation?.name?.length > 0) {
      dispatch(setRightSideBarOpen(true));
      let body = {
        substationId: substation?.SubstationID,
      };
      userSubstationClicked(body).then((res) => {
        if (res.success) {
          console.log("Substation Clicked");
        }
      });
    }
  }, [substation]); // eslint-disable-line react-hooks/exhaustive-deps

  const findLocalPlanningClosestPoints = (substation) => {
    let body = {
      substation,
    };

    fetchClosestLocalPlanningProjects(body)
      .then((response) => {
        if (response.success) {
          dispatch(setPlanningData(response?.data?.closestProjects));
        }
      })
      .catch((err) => {
        dispatch(setPlanningData([]));
        console.log(err);
      });
  };
  const findNearestNcpr = (substation) => {
    let body = {
      latitude: substation.Lattitude,
      longitude: substation.Longitute,
    };
    getNearestNcpr(body)
      .then((response) => {
        if (response.success) {
          dispatch(setPlanningData(response?.data));
        } else {
          dispatch(setPlanningData({}));
        }
      })
      .catch((err) => {
        dispatch(setPlanningData({}));
        console.log(err);
      });
  };

  const geoRisk = {
    NGED1006: 77.7,
    NGED1065: 86.4,
    NGED311: 83.7,
  };
  useEffect(() => {
    if (promethousAIClick && substations?.substations?.length > 0) {
      const sorted = [...substations.substations].sort(
        (a, b) => b.iq_score - a.iq_score
      );
      if (sorted?.length > 0) {
        if (filters.assetType === "EV Infrastructure") {
          findNearestNcpr(sorted[0]);
        } else {
          findLocalPlanningClosestPoints(sorted[0]);
        }
        dispatch(
          setFilterValues({
            source: sorted[0]?.Source,
            voltageLine: sorted[0]?.VoltageLine,
          })
        );
        dispatch(setSelectedSubstation(sorted[0]));
      }
    }
  }, [promethousAIClick]);

  return (
    <div className={projects?.rightSidebarOpen ? styles.open : ""}>
      <div
        className={styles.right}
        style={{
          display: substations?.substations?.length > 0 ? "block" : "none",
        }}
      >
        <div className={styles.top}>
          <div className={styles.result}>
            <div className={styles.result__left}>
              {substation !== null && substation?.length !== 0 && (
                <div
                  className={styles.result__left__backButton}
                  onClick={() => {
                    console.log("click in working")
                    dispatch(setSelectedSubstation(null));
                    dispatch(setRightSideBarOpen(false));
                    dispatch(setGeospatialLayers([]));
                    dispatch(setSelectedSecondaryAquisitionLocation(null));
                    // dispatch(setPromethousAIClick(!promethousAIClick));
                  }}
                >
                  Back
                </div>
              )}
              <p className={styles.result__left__heading}>Insight Results</p>

              <div className={styles.result__left__badge}>
                {substations?.substations?.length}
              </div>
            </div>

            <div className={styles.result__right}>
              {!projects.rightSidebarOpen ? (
                <ExpandCircleDownIcon
                  onClick={() => dispatch(setRightSideBarOpen(true))}
                />
              ) : (
                <OffOutlineClose
                  onClick={() => {
                    dispatch(setSelectedSubstation(null));
                    dispatch(setRightSideBarOpen(false));
                    dispatch(setGeospatialLayers([]));
                    dispatch(setSelectedSecondaryAquisitionLocation(null));
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {/* <div style={{ paddingRight: "10px" }}>
          <Divider />
        </div> */}

        <div style={{ paddingRight: "10px" }}>
          <div>
            <div style={{ width: "100%", paddingLeft: "1px" }}>
              {/* data.sort((a, b) => new Date(a.start_date) - new Date(b.start_date)); */}
              {substations?.substations?.map((data, index) => {

                // const coordinates = {
                //   Longitute: data?.location?.coordinates?.[0],
                //   Lattitude: data?.location?.coordinates?.[1]
                // }
                return (
                  <div
                    key={index}
                    style={{ marginBottom: "20px" }}
                    onClick={() => {
                      dispatch(setSelectedSecondaryAquisitionLocation(data));
                      dispatch(setSelectedSubstation(data));
                    }}
                  >
                    <Accordion
                      expanded={expanded === data?.name}
                      onChange={handleChange(data?.name, data.distance)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {/* <BatteryIcon /> */}
                          <div>
                            <p className={styles.planningCardz__heading}>
                              {data?.address}
                            </p>
                            <p className={styles.planningCardz__heading__sub1}>
                              {data?.start_date}
                              <li>{data?.app_state}</li>
                              <li>{fixed(data.distance, 2)} km</li>
                            </p>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {data.distance === subData && (
                          <div className={styles.planningCardz__imageContainer}>
                            {/* <SatView data={coordinates} /> */}
                            {/* <h3>Residential building</h3> */}
                          </div>
                        )}

                        <div className={styles.planningCardz__roadContainer}>

                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Planning ID
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.name?.length > 0 ? data?.name : '--'}
                            </p>
                          </div>

                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Alternative ID
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.altid?.length > 0 ? data?.altid : '--'}
                            </p>
                          </div>

                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              App Size
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.app_size?.length > 0 ? data?.app_size : '--'}
                            </p>
                          </div>

                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              App Type
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.app_type?.length > 0 ? data?.app_type : '--'}
                            </p>
                          </div>

                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Area Name
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.area_name?.length > 0 ? data?.area_name : '--'}
                            </p>
                          </div>
                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Agent Address
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.agent_address?.length > 0 ? data?.other_fields?.agent_address : '--'}
                            </p>
                          </div>
                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                            style={{ width: '100%' }}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Description
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.description?.length > 0 ? data?.description : '--'}
                            </p>
                          </div>
                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Agent Name
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.agent_name?.length > 0 ? data?.other_fields?.agent_name : '--'}
                            </p>
                          </div>

                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Applicant Name
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.applicant_name?.length > 0 ? data?.other_fields?.applicant_name : '--'}
                            </p>
                          </div>
                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Application Type
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.application_type?.length > 0 ? data?.other_fields?.application_type : '--'}
                            </p>
                          </div>
                          {/* <br /> */}

                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Case Officer
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.case_officer?.length > 0 ? data?.other_fields?.case_officer : '--'}
                            </p>
                          </div>
                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Decided By
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.decided_by?.length > 0 ? data?.other_fields?.decided_by : '--'}
                            </p>
                          </div>
                          {/* <div
                            style={{
                              width: "100%",
                              margin: "15px 0px 10px 0px",
                            }}
                          >
                            <Divider />
                          </div> */}

                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Status
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.status?.length > 0 ? data?.other_fields?.status : '--'}
                            </p>
                          </div>
                          <div
                            className={styles.planningCardz__roadContainer__ppp}
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Ward Name
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.ward_name?.length > 0 ? data?.other_fields?.ward_name : '--'}
                            </p>
                          </div>

                          <div
                            className={
                              styles.planningCardz__roadContainer__ppp
                            }
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Consulted Date
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.consulted_date?.length > 0 ? data?.consulted_date : '--'}
                            </p>
                          </div>

                          <div
                            className={
                              styles.planningCardz__roadContainer__ppp
                            }
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Decided Date
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.decided_date?.length > 0 ? data?.decided_date : '--'}
                            </p>
                          </div>



                          <div
                            className={
                              styles.planningCardz__roadContainer__ppp
                            }
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Date Received
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.date_received?.length > 0 ? data?.other_fields?.date_received : '--'}
                            </p>
                          </div>

                          <div
                            className={
                              styles.planningCardz__roadContainer__ppp
                            }
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Date Validated
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.date_validated?.length > 0 ? data?.other_fields?.date_validated : '--'}
                            </p>
                          </div>



                          <div
                            className={
                              styles.planningCardz__roadContainer__ppp
                            }
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Comments
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.n_comments?.length > 0 ? data?.other_fields?.n_comments : '--'}
                            </p>
                          </div>

                          <div
                            className={
                              styles.planningCardz__roadContainer__ppp
                            }
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Documents
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.n_documents?.length > 0 ? data?.other_fields?.n_documents : '--'}
                            </p>
                          </div>

                          <div
                            className={
                              styles.planningCardz__roadContainer__ppp
                            }
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Statutory Days
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.n_statutory_days?.length > 0 ? data?.other_fields?.n_statutory_days : '--'}
                            </p>
                          </div>

                          <div
                            className={
                              styles.planningCardz__roadContainer__ppp
                            }
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Target Decision Date
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.other_fields?.target_decision_date?.length > 0 ? data?.other_fields?.target_decision_date : '--'}
                            </p>
                          </div>

                          <div
                            className={
                              styles.planningCardz__roadContainer__ppp
                            }
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Postcode
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.postcode?.length > 0 ? data?.postcode : '--'}
                            </p>
                          </div>

                          <div
                            className={
                              styles.planningCardz__roadContainer__ppp
                            }
                          >
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__pone
                              }
                            >
                              Reference
                            </p>
                            <p
                              className={
                                styles.planningCardz__roadContainer__ppp__ptwo
                              }
                            >
                              {data?.reference?.length > 0 ? data?.reference : '--'}
                            </p>
                          </div>



                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Planning;
