import React from 'react'
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const InputSelect = (props) => {
    const { width, margin, title, value, handler, height, option, radius, m = 1, variant } = props
    return (
        <FormControl
            variant={variant}
            sx={{
                m: m,
                width: width,
                marginBottom: margin,
                '& .Mui-focused': {
                    color: 'black', // Change the color of the label when the dropdown is opened
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                        borderColor: '#1F443A', // Change the border color of the button when focused
                    },
                },
                '& .MuiInputLabel-root': {
                    color: 'gray',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                    color: 'green',
                },
            }}
            size="small">
            <InputLabel id="demo-select-small-label">{title}</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                defaultValue={value}
                value={value}
                label={title}
                onChange={handler}
                style={{ height: height, borderRadius: radius }}
            >
                {option?.map((item) => {
                    return (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default InputSelect
