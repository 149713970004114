import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import findStyle from "../Utils/MapColors";
import findLogo from "../Utils/NcprLayerLogos";
// import { getOverlayFeatures } from "../Api/API";
import Typography from "@mui/material/Typography";
// import { powerline } from "./style/gptLayerColors";
import { Popup, useMap } from "react-map-gl/maplibre";
import { useState, useEffect, useCallback } from "react";
import ConvertToTitleCase from "../Utils/TitleCaseConvertor";
import PolygonFeatureModal from "../Modals/PolygonModal/PolygonFeatureModal";

const MapEvents = ({showNPS, showCOD, showPrivate, showHeatmap, showCompleted, showProgressing, viewLarge, searchedParcel}) => {
  const { current: mymap } = useMap();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [features, setFeatures] = useState([]);
  const [searchedParcelData, setSearchedParcelData] = useState({});
  const [featureCount, setfeatureCount] = useState(0);
  const [selectedlayerData, setSelectedLayerData] = useState({});
  const [polygonFeatureModal, setPolygonFeatureModal] = useState(false);

  const isDrawing = useSelector((state) => state.gptOsmData.isDrawing);
  const { activeLayers, activeRenewableLayers } = useSelector((state) => state.dataset);

  useEffect(() => {
    mymap.on('click', handleClick);
    return () => {
      mymap.off('click', handleClick);
    };
  }, [activeLayers, activeRenewableLayers, showCOD, showNPS, showPrivate, showHeatmap, showCompleted, showProgressing, viewLarge, searchedParcel]) // eslint-disable-line react-hooks/exhaustive-deps

  // const reversePortion = (arr, count) => {
  //   let reversedPortion = arr.slice(0, -count).reverse();
  //   return reversedPortion.concat(arr.slice(-count));
  // };

  const handleClick = useCallback((e) => {
    // e.preventDefault();
    // e.originalEvent.stopPropagation();
    const technologyTypeIndex = activeLayers.indexOf("tidal-lagoon-renewable");
    let activeLayersNew = [...activeLayers];
    console.log("newActive: ", activeLayersNew);
    if (showNPS) {
      activeLayersNew = activeLayersNew.concat(["nps"]);
    }
    if (showPrivate) {
      activeLayersNew = activeLayersNew.concat(["private_land"]);
    }
    if(searchedParcel?.data?.features?.length > 0){
      activeLayersNew = activeLayersNew.concat(["Searched_Parcel_Fill"]);
    }
    if (showCOD) {
      activeLayersNew = activeLayersNew.concat(["ccod_nps"]);
      activeLayersNew = activeLayersNew.concat(["ocod_nps"]);
    }
    if (showHeatmap) {
      activeLayersNew = activeLayersNew.concat(["reinforcementHeatmapLayer"]);
    }
    if (showProgressing) {
      activeLayersNew = activeLayersNew.concat(["reinforcementProgressingLayer"]);
    }
    if (showCompleted) {
      activeLayersNew = activeLayersNew.concat(["reinforcementCompletedLayer"]);
    }
    // if (viewLarge) {
    //   activeLayersNew = activeLayersNew.concat(["largeProjectLayer"]);
    // }
    // console.log("activeLayersNew", activeLayersNew, mymap.getStyle().layers);
    if (technologyTypeIndex !== -1) {
      activeLayersNew.splice(technologyTypeIndex, 1);
      activeLayersNew = activeLayersNew.concat(activeRenewableLayers);
    }
    const qfeatures = mymap.queryRenderedFeatures(e.point, {
      layers: activeLayersNew // replace with your layer name
    });
    if (!qfeatures.length) {
      return;
    } else {
      if (qfeatures[0]?.layer?.source === "Searched_Parcel") {
        const parcelProperties = {
          "Title Number": qfeatures[0]?.properties?.title_no,
          "Updated On": qfeatures[0]?.properties?.update,
        };
        if (searchedParcel?.ownership?.ocod?.length > 0) {
          parcelProperties["Additional Proprietor Indicator(Oversea)"] =  searchedParcel?.ownership?.ocod[0]?.["Additional Proprietor Indicator"];
          parcelProperties["Country Incorporated(Oversea)"] =  searchedParcel?.ownership?.ocod[0]?.["Country Incorporated (1)"];
          parcelProperties["County(Oversea)"] =  searchedParcel?.ownership?.ocod[0]?.County;
          parcelProperties["Multiple Address Indicator(Oversea)"] =  searchedParcel?.ownership?.ocod[0]?.["Multiple Address Indicator"];
          parcelProperties["Postcode(Oversea)"] =  searchedParcel?.ownership?.ocod[0]?.Postcode;
          parcelProperties["Property Address(Oversea)"] =  searchedParcel?.ownership?.ocod[0]?.["Property Address"];
          parcelProperties["Proprietor (1) Address(Oversea)"] =  searchedParcel?.ownership?.ocod[0]?.["Proprietor (1) Address (1)"];
          parcelProperties["Proprietor Name(Oversea)"] =  searchedParcel?.ownership?.ocod[0]?.["Proprietor Name (1)"];
          parcelProperties["Region(Oversea)"] =  searchedParcel?.ownership?.ocod[0]?.Region;
          parcelProperties["Tenure(Oversea)"] =  searchedParcel?.ownership?.ocod[0]?.Tenure;
          parcelProperties["Updated On(Oversea)"] =  searchedParcel?.ownership?.ocod[0]?.["Date Proprietor Added"];
        }
        if (searchedParcel?.ownership?.ccod?.length > 0) {
          parcelProperties["Additional Proprietor Indicator(UK)"] =  searchedParcel?.ownership?.ccod[0]?.["Additional Proprietor Indicator"];
          parcelProperties["Proprietorship Category(UK)"] = searchedParcel?.ownership?.ccod[0]?.["Proprietorship Category (1)"];
          parcelProperties["County(UK)"] =  searchedParcel?.ownership?.ccod[0]?.County;
          parcelProperties["Multiple Address Indicator(UK)"] =  searchedParcel?.ownership?.ccod[0]?.["Multiple Address Indicator"];
          parcelProperties["Postcode(UK)"] =  searchedParcel?.ownership?.ccod[0]?.Postcode;
          parcelProperties["Property Address(UK)"] =  searchedParcel?.ownership?.ccod[0]?.["Property Address"];
          parcelProperties["Proprietor (1) Address(UK)"] =  searchedParcel?.ownership?.ccod[0]?.["Proprietor (1) Address (1)"];
          parcelProperties["Proprietor Name(UK)"] =  searchedParcel?.ownership?.ccod[0]?.["Proprietor Name (1)"];
          parcelProperties["Region(UK)"] =  searchedParcel?.ownership?.ccod[0]?.Region;
          parcelProperties["Tenure(UK)"] =  searchedParcel?.ownership?.ccod[0]?.Tenure;
          parcelProperties["Updated On(UK)"] =  searchedParcel?.ownership?.ccod[0]?.["Date Proprietor Added"];
        }
        setSearchedParcelData({
          properties: {...parcelProperties}
        });
      }
      const coords = e.lngLat;
      setLat(coords.lat);
      setLng(coords.lng);
      setFeatures(qfeatures);
      setfeatureCount(qfeatures.length);
      // if (activeLayers?.length === 0) {
      //   const newFeat = [...qfeatures].reverse();        
      //   console.log("features reversed", newFeat);
      //   setFeatures(qfeatures);
      // } else {
      //   const featLength = qfeatures.length;
      //   const reverseFeatures = reversePortion([...qfeatures], featLength);
      //   setFeatures(reverseFeatures);
      //   console.log("features final", reverseFeatures);
      // }
    }
    // const features = mymap.queryRenderedFeatures(e.point);
    // const filterFeatures = [];
    // let count = 0;
    // if (features.length > 0) {
    //   features.forEach((feature, i) => {
    //     const properties = feature.properties;
    //     const layer = powerline.includes(feature?.layer?.id) ?  ConvertToTitleCase(feature?.layer?.id) : feature?.properties?.amenity ? ConvertToTitleCase(feature?.properties?.amenity) : feature?.properties?.cuisine ?ConvertToTitleCase(feature?.properties?.cuisine) : feature?.properties?.building ? ConvertToTitleCase(feature?.properties?.building) : feature?.properties?.operator  ? ConvertToTitleCase(feature?.properties?.operator) : 'Unnamed entity'
    //     const featuren = [
    //       {
    //         properties: properties,
    //         layer:layer
    //       }
    //     ]
    //     const smallData = {
    //       layer: layer,
    //       data: {
    //         features: featuren
    //       }
    //     }
    //     if(powerline.includes(feature?.layer?.id) || feature?.properties?.amenity ||feature?.properties?.cuisine || feature?.properties?.operator || feature?.properties?.building || 'Unnamed entity' ){
    //       if(  feature?.properties?.mode !== "draw_polygon" && feature?.properties?.mode !== "draw_line_string" 
    //       && feature?.properties?.mode !== "simple_select" && Object.keys(feature?.properties)?.length > 0 
    //       && feature?.properties?.mode !== "direct_select" && feature?.properties?.meta !== "vertex" ){
    //         count=count+1
    //         filterFeatures.push(smallData)}
    //     }
    //   });
    // }
    // const technologyTypeIndex = activeLayers.indexOf("tidal-lagoon-renewable");
    // let activeLayersNew = [...activeLayers]
    // if (technologyTypeIndex !== -1) {
    //   activeLayersNew.splice(technologyTypeIndex, 1);
    //   activeLayersNew = activeLayersNew.concat(activeRenewableLayers);
    // }
    // const payload = {
    //   latitude: coords.lng,
    //   longitude: coords.lat,
    //   layers: activeLayersNew,
    //   zoom: zoom,
    // };
    // setLoading(true);
    // if(activeLayers.length > 0){
    //   getOverlayFeatures(payload)
    //   .then((response) => {
    //     response?.features?.forEach((feature) => {
    //       if (feature?.data?.features?.length > 0) {
    //         count = count + 1;
    //         filterFeatures.push(feature);
    //       }
    //     });
    //     setfeatureCount(count);
    //     setFeatures(filterFeatures);
    //   })
    //   .catch((err) => { })
    //   .finally(() => {
    //     setLoading(false);
    //   });
    // }else{
    //     setfeatureCount(count);
    //     setLoading(false)
    //     setFeatures(filterFeatures)
    //   }
  });

  const handleTallyValue = (value) => {
    const stringArray = value.split(", ");
    return stringArray.join("\n");
  };

  const handlePolygonLayer = (data) => {
    setPolygonFeatureModal(!polygonFeatureModal);
    setSelectedLayerData(data);
  };

  return (
    <>
      {lng && lat && features?.length > 0 && !isDrawing && (
        <>
          {features[0]?.sourceLayer === "clearview" ? (
            <Popup
              longitude={lng}
              latitude={lat}
              anchor="bottom"
            >
              <div style={{ height: "100%", width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // background: "#bbb",
                  }}
                >
                  <Box style={{ fontWeight: "700", color: "#000" }}>
                    National Grid Energy Distribution
                  </Box>
                  <Box>
                    <img
                      width={"170px"}
                      src="/icons/nged.png"
                      alt=""
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", marginTop: "10px" }}>
                    <Box
                      sx={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                        background: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "10px",
                      }}
                    >
                      <img
                        width={"25px"}
                        src="/icons/electric-factory+2.png"
                        alt=""
                      />
                    </Box>
                    <Box>
                      <Box>Grid Supply Point</Box>
                      <Box style={{ fontWeight: "700" }}>
                        {
                          features[0]?.properties
                            ?.Substation
                        }
                      </Box>
                    </Box>
                  </Box>
                  <Box></Box>
                </Box>
                {features?.[0] &&
                  Object.entries(features[0]?.properties)
                    ?.filter(
                      ([key, value]) =>
                        value !== null &&
                        value !== "" &&
                        key !== "name" &&
                        key !== "typology" &&
                        key !== "LAT" &&
                        key !== "LON"
                    )
                    .map(([key, value], index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            color: "#1F443A",
                            borderRadius: "10px",
                          }}
                        >
                          {/* properties names length is limited to 10 character on geoServer so we set it manually here  */}
                          <Typography sx={{ fontSize: 12 }}>
                            {key === "Connected"
                              ? "Connected Capacity to date (MW)"
                              : key === "Average MW"
                                ? "Average MW per Project"
                                : key === "Cumulative"
                                  ? "Cumulative Queue Export Capacity (MW)"
                                  : key === "Queue Leng"
                                    ? "Queue Length (Pipeline)"
                                    : ConvertToTitleCase(key)}
                            :{value}
                          </Typography>
                        </Box>
                      );
                    })}
              </div>
            </Popup>
          ) : (features[0]?.source === "completed_reinforcement" || features[0]?.source === "progressing_reinforcement") ? (
            <>
              <Popup
                longitude={lng}
                latitude={lat}
                anchor="bottom"
                key={`${lat}-${lng}-${features.length}-`}
              >
                <div style={{ height: "100%", width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // background: "#bbb",
                    }}
                  >
                    <Box style={{ fontWeight: "700", color: "#000" }}>{features[0]?.properties["Grid Supply Point"]}</Box>
                    {/* <Box>
                      <img
                        width={"70px"}
                        src={findLogo(
                          features[0]?.properties?.devicecont
                        )}
                        alt=""
                      />
                    </Box> */}
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", marginTop: "10px" }}>
                      <Box
                        sx={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                          background: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          width={"35px"}
                          src={features[0]?.source === "completed_reinforcement" ? "https://voltquant-images.s3.eu-west-2.amazonaws.com/hammer-completed.png" : "https://voltquant-images.s3.eu-west-2.amazonaws.com/hammer+Loader+3.png"}
                          alt=""
                        />
                      </Box>
                      <Box>
                        <Box>Reinforcement</Box>
                        <Box style={{ fontWeight: "700" }}>{features[0]?.source === "completed_reinforcement" ? "Completed" : "Progressing"}</Box>
                      </Box>
                    </Box>
                    <Box></Box>
                  </Box>
                  <Box
                    sx={{
                      color: "#1F443A",
                      borderRadius: "10px",
                      paddingTop: "10px",
                      maxWidth: "500px"
                    }}
                  >
                    {(features[0]?.properties["Licence"] !== "N/A") && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Licence:</strong> {features[0]?.properties["Licence"]}
                      </Typography>
                    )}
                    {features[0]?.properties["Substation or Circuit"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Substation or Circuit:</strong> {features[0]?.properties["Substation or Circuit"]}
                      </Typography>
                    )}
                    {features[0]?.properties["Planned Work"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Planned Work:</strong> {features[0]?.properties["Planned Work"]}
                      </Typography>
                    )}
                    {features[0]?.properties["Impact on Network Capability"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Impact on Network Capability:</strong> {features[0]?.properties["Impact on Network Capability"]}
                      </Typography>
                    )}
                    {features[0]?.properties["Status"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Status:</strong> {features[0]?.properties["Status"]}
                      </Typography>
                    )}
                    {features[0]?.properties["Completion"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "collapse" }}>
                        <strong>Completion:</strong> {features[0]?.properties["Completion"]}
                      </Typography>
                    )}
                  </Box>
                </div>
              </Popup>
            </>
          ) : (features[0]?.source === "large_projects") ? (
            <>
              <Popup
                longitude={lng}
                latitude={lat}
                anchor="bottom"
                key={`${lat}-${lng}-${features.length}-`}
              >
                <div style={{ height: "100%", width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // background: "#bbb",
                    }}
                  >
                    <Box style={{ fontWeight: "700", color: "#000" }}>{features[0]?.properties["name"]}</Box>
                    {/* <Box>
                      <img
                        width={"70px"}
                        src={findLogo(
                          features[0]?.properties?.devicecont
                        )}
                        alt=""
                      />
                    </Box> */}
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", marginTop: "10px" }}>
                      <Box
                        sx={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                          background: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          width={"35px"}
                          src="https://voltquant-images.s3.eu-west-2.amazonaws.com/doc-icon.png"
                          alt=""
                        />
                      </Box>
                      <Box>
                        <Box>Large Project</Box>
                        <Box style={{ fontWeight: "700" }}>Planning Application</Box>
                      </Box>
                    </Box>
                    <Box></Box>
                  </Box>
                  <Box
                    sx={{
                      color: "#1F443A",
                      borderRadius: "10px",
                      paddingTop: "10px",
                      maxWidth: "500px"
                    }}
                  >
                    {(features[0]?.properties["address"] !== "N/A") && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Address:</strong> {features[0]?.properties["address"]}
                      </Typography>
                    )}
                    {features[0]?.properties["postcode"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Postcode:</strong> {features[0]?.properties["postcode"]}
                      </Typography>
                    )}
                    {features[0]?.properties["app_size"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Application Size:</strong> {features[0]?.properties["app_size"]}
                      </Typography>
                    )}
                    {features[0]?.properties["app_state"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Application State:</strong> {features[0]?.properties["app_state"]}
                      </Typography>
                    )}
                    {features[0]?.properties["area_name"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Area:</strong> {features[0]?.properties["area_name"]}
                      </Typography>
                    )}
                  </Box>
                </div>
              </Popup>
            </>
          ) : (features[0]?.source === "heatmap_reinforcement") ? (
            <>
              <Popup
                longitude={lng}
                latitude={lat}
                anchor="bottom"
                key={`${lat}-${lng}-${features.length}-`}
              >
                <div style={{ height: "100%", width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // background: "#bbb",
                    }}
                  >
                    <Box style={{ fontWeight: "700", color: "#000" }}>{features[0]?.properties["Project Name"]}</Box>
                    {/* <Box>
                      <img
                        width={"70px"}
                        src={findLogo(
                          features[0]?.properties?.devicecont
                        )}
                        alt=""
                      />
                    </Box> */}
                  </Box>
                  {/* <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", marginTop: "10px" }}>
                      <Box
                        sx={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                          background: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          width={"35px"}
                          src="https://voltquant-images.s3.eu-west-2.amazonaws.com/diesel-icon.png"
                          alt=""
                        />
                      </Box>
                      <Box>
                        <Box>Heatmap</Box>
                        <Box style={{ fontWeight: "700" }}>Connected to the Grid</Box>
                      </Box>
                    </Box>
                    <Box></Box>
                  </Box> */}
                  <Box
                    sx={{
                      color: "#1F443A",
                      borderRadius: "10px",
                      paddingTop: "10px",
                      maxWidth: "500px"
                    }}
                  >
                    {features[0]?.properties["Category"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Category:</strong> {features[0]?.properties["Category"]}
                      </Typography>
                    )}
                    {features[0]?.properties["Location"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Location:</strong> {features[0]?.properties["Location"]}
                      </Typography>
                    )}
                    {features[0]?.properties["Description"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "collapse" }}>
                        <strong>Description:</strong> {features[0]?.properties["Description"]}
                      </Typography>
                    )}
                    {features[0]?.properties["Status"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Status:</strong> {features[0]?.properties["Status"]}
                      </Typography>
                    )}
                    {features[0]?.properties["Timeline"] !== "N/A" && (
                      <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                        <strong>Timeline:</strong> {features[0]?.properties["Timeline"]}
                      </Typography>
                    )}
                  </Box>
                </div>
              </Popup>
            </>
          ) : features[0]?.sourceLayer === "ncpr" ? (
            <>
              <Popup
                longitude={lng}
                latitude={lat}
                anchor="bottom"
              >
                <div style={{ height: "100%", width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // background: "#bbb",
                    }}
                  >
                    <Box style={{ fontWeight: "700", color: "#000" }}>
                      {features[0]?.properties?.devicecont}
                    </Box>
                    <Box>
                      <img
                        width={"70px"}
                        src={findLogo(
                          features[0]?.properties?.devicecont
                        )}
                        alt=""
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", marginTop: "10px" }}>
                      <Box
                        sx={{
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                          background: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          width={"25px"}
                          src="/icons/charging-station+3.png"
                          alt=""
                        />
                      </Box>
                      <Box>
                        <Box>EV Charging Station</Box>
                        <Box style={{ fontWeight: "700" }}>
                          {
                            features[0]?.properties
                              ?.chargede_1
                          }
                        </Box>
                      </Box>
                    </Box>
                    <Box></Box>
                  </Box>

                  <Box
                    sx={{
                      color: "#1F443A",
                      borderRadius: "10px",
                    }}
                  >
                    {/* properties names length is limited to 10 character on geoServer so we set it manually here  */}
                    <Typography sx={{ fontSize: 12, whiteSpace: "pre-line" }}>
                      {handleTallyValue(
                        features[0]?.properties?.tally
                      )}
                    </Typography>
                  </Box>
                </div>
              </Popup>
            </>
          ) : features[0]?.source === "Searched_Parcel" ? (
            <Popup
            key={`${lat}-${lng}-${features.length}-`}
              longitude={lng}
              latitude={lat}
            >
              <Typography variant="h6" component="h6" sx={{ mb: 1, pl: 1 }}>
             { `  ${featureCount} ${featureCount===1 ? 'feature' : 'features'} selected`}
              </Typography>
              {features?.map((feature, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      pl: 1,
                      gap: 2,
                      mb: 1,
                    }}
                    key={index}
                  >
                    <Box
                      sx={{
                        height: "50px",
                        width: "8px",
                        background: "#ef5350",
                      }}
                    ></Box>
                    <Box sx={{ fontSize: "16px" }}>
                      {"Searched Parcel"}
                      <Typography
                        onClick={() => handlePolygonLayer(searchedParcelData)}
                        sx={{
                          cursor: "pointer",
                          margin: "0px",
                          textDecoration: "underline",
                          color: "#123499",
                          fontWeight: "500",
                          marginTop: "0px",
                        }}
                      >
                        {
                          feature?.properties['gsp'] || feature?.properties['primary_group'] || feature?.properties['title'] || feature?.properties['title_no'] || feature?.properties['Title Number'] || 
                          feature?.properties['flood-ri_1'] || feature?.properties?.name || feature?.properties?.risk_cy ||
                          feature?.properties?.power ||feature?.properties['addr:street'] ||
                          feature?.properties?.Name ||feature?.properties?.substance ||
                          feature?.properties?.operator || feature?.properties?.rpt_jobnum || feature?.data?.layer?.id ||
                          feature?.properties?.operator?.brand ||
                          feature?.properties?.Substation || feature?.properties['site name'] || feature?.layer?.id || 
                          "Unnamed entity"}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Popup>
          ) : (
            <Popup
            key={`${lat}-${lng}-${features.length}-`}
              longitude={lng}
              latitude={lat}
            >
              <Typography variant="h6" component="h6" sx={{ mb: 1, pl: 1 }}>
             { `  ${featureCount} ${featureCount===1 ? 'feature' : 'features'} selected`}
              </Typography>
              {features?.map((feature, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      pl: 1,
                      gap: 2,
                      mb: 1,
                    }}
                    key={index}
                  >
                    <Box
                      sx={{
                        height: "50px",
                        width: "8px",
                        background: `${((feature.sourceLayer === 'ccod_nps' || feature.sourceLayer === 'ocod_nps') && feature?.properties['Tenure'] === 'Freehold') ? '#34c0eb' : ((feature.sourceLayer === 'ccod_nps' || feature.sourceLayer === 'ocod_nps') && feature?.properties['Tenure'] === 'Leasehold') ? '#e83313' : findStyle(feature.sourceLayer)}`,
                      }}
                    ></Box>
                    <Box sx={{ fontSize: "16px" }}>
                      {feature?.sourceLayer
                        ? ConvertToTitleCase(
                          feature?.sourceLayer === "clearview"
                            ? "Substations Queue"
                          : feature?.sourceLayer === "dmw-seafloodriskfromsea"
                          ? "Wales - Flood Risk from Sea"
                          : feature?.sourceLayer === "dmw-floodriskfromrivers"
                          ? "Wales - Flood Risk from Rivers"
                          : feature?.sourceLayer === "ccod_nps"
                          ? `${feature?.properties['Tenure'] === 'Freehold' ? 'Freehold Land Owned by UK Company' : 'Leasehold Land Owned by UK Company'}`
                          : feature?.sourceLayer === "ocod_nps"
                          ? `${feature?.properties['Tenure'] === 'Freehold' ? 'Freehold Land Owned by Overseas Company' : 'Leasehold Land Owned by Overseas Company'}`
                          : feature?.sourceLayer === "private_land"
                          ? "Land Owned by Private Individual"
                          : feature?.sourceLayer === "nps"
                          ? "Land Boundary Polygon"
                          : feature?.sourceLayer === "network_cables"
                          ? "Fibre Cables (NEOS)"
                          : feature?.sourceLayer === "pop_assets"
                          ? "Point-of-Presence (PoP)"
                          : feature?.sourceLayer === "exchange_assets"
                          ? "Exchanges"
                          : feature?.sourceLayer
                        )
                        : "Substations Queue"}

                      <Typography
                        onClick={() => handlePolygonLayer(feature)}
                        sx={{
                          cursor: "pointer",
                          margin: "0px",
                          textDecoration: "underline",
                          color: "#123499",
                          fontWeight: "500",
                          marginTop: "0px",
                        }}
                      >
                        {
                          feature?.properties['gsp'] || feature?.properties['primary_group'] || feature?.properties['title'] || feature?.properties['title_no'] || feature?.properties['Title Number'] || 
                          feature?.properties['flood-ri_1'] || feature?.properties?.name || feature?.properties?.risk_cy ||
                          feature?.properties?.power ||feature?.properties['addr:street'] ||
                          feature?.properties?.Name ||feature?.properties?.substance ||
                          feature?.properties?.operator || feature?.properties?.rpt_jobnum || feature?.data?.layer?.id ||
                          feature?.properties?.operator?.brand ||
                          feature?.properties?.Substation || feature?.properties['site name'] || feature?.layer?.id || 
                          "Unnamed entity"}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Popup>
          )}
         
        </>
      )}
      <PolygonFeatureModal
        open={polygonFeatureModal}
        setOpen={setPolygonFeatureModal}
        data={selectedlayerData}
      />
    </>
  );
};

export default MapEvents;
