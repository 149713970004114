import { toast } from "react-toastify";
export const ToastNotification = (type, message, position = "top-right" ) => {
  return toast[type](message, {
    position: position,
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
