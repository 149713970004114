import "./myMap.css";
import axios from "axios";
import * as React from "react";
import * as turf from "@turf/turf";
import MapEvents from "./MapEvents";
import html2canvas from "html2canvas";
import SpeedLegend from "./SpeedLegend";
import DrawControl from "./drawControl";
import heatmap_data from "./../../data/heatmap_data.js";
// import large_projects from "./../../data/large_projects.js";
import rein_completed from "./../../data/rein_completed.js";
import rein_inprogress from "./../../data/rein_inprogress.js";
import LPAPlanningData from "./../../data/LPAPlanning.js";
import { fixed, kilometersToMiles } from "../Utils/Formats";
import "maplibre-gl/dist/maplibre-gl.css";
import map_layers from "./style/map_layers";
import { baseURL } from "../Api/instance.js";
import Loader from "../Common/Loader/Loader";
import NpsControl from "./npsLayerControl.js";
import AreaCalculator from "./drawCalculations";
import BasemapControl from "./basemapControl.js";
import { LngLatBounds, Popup } from "maplibre-gl";
import evSearch from "../../Images/ev_search.png";
import bsSearch from "../../Images/battery_search.png";
import reSearch from "../../Images/realestate_search.png";
import rneSearch from "../../Images/renewable_search.png";
import LayerToggleControl from './LayerToggleControl';
import { useSelector, useDispatch } from "react-redux";
import { osmDataColor } from "./style/gptLayerColors.js";
import style_oim_power from "./style/style_oim_power.js";
import { setFeatues, appendNewLayer, removeLayer } from "../Redux/reducers/datasetSlice";
import { links } from "../Common/MapFilterCard/layer.config";
import ReinforcementControl from "./reinforceLayerControl.js";
import DeleteProjectModal from "../Modals/DeleteProjectModal";
import PolygonPopup from "../Modals/PolygonModal/PolygonPopup";
import style_oim_petroleum from "./style/style_oim_petroleum.js";
import { useState, useRef, useEffect, useLayoutEffect, useCallback } from "react";
import { ToastNotification } from "../Utils/ToastNotifications.js";
import LargeProjetLayerControl from "./largeProjectLayerControl.js";
import { GeospatialRiskLayers } from "./style/GeospatialRiskLayers.js";
import { setGptPrompt, setOsmData } from "../Redux/reducers/gptOsmSlice.js";
import PolygonFeatureModal from "../Modals/PolygonModal/PolygonFeatureModal";
import Map, { Marker, NavigationControl, Source, Layer } from "react-map-gl/maplibre";
import { setGeospatialLayers, setSelectedLayers } from "../Redux/reducers/selectedLayers";
import { setRightSideBarOpen, setMapImage, setTakeImage, setPromethousModal, setTransmission } from "../Redux/reducers/projectsSlice";
import { setSelectedSubstation, setRoute, setSecondarySubstations } from "../Redux/reducers/selectedSubstation";
import { setDrawnLine, setDrawnPolygon, setFilterValues, setCurrentCenter, setMapLoader } from "../Redux/reducers/filterSlice";
import { setSubstations, setPlanningData, triggerClosestPoints, setPolygonCentroid, setLandOwnerData, setHabitatData, setPrivateWire, setSecondaryAcquisition } from "../Redux/reducers/substationSlice";
import { fetchClosestSubstations, fetchClosestLocalPlanningProjects, getLayers, getNearestNcpr, getGptOsmData, getDirections, getHabitat, getEnclosingPolygon, updateActiveLayers, fetchPrometheusData } from "../Api/API";
import styles from "./styles.module.scss";
import { setLoading } from "../Redux/reducers/wireSchemeSlice.js";

import {
  // Close,
  OffOutlineClose,
  PrometheuesCircleCheck,
  PrometheuesEnergy,
  PrometheuesHome,
  PrometheuesMinus,
  PrometheuesTransfer,
  // PrometheuesTree,
  RadioActive,
} from "../SVG";
import { map } from "leaflet";
const MapArea = () => {
  const [buttonText, setButtonText] = useState("Transformer Analysis");

  const mapRef = useRef();

  const dispatch = useDispatch();

  const drawControlRef = useRef();

  const [line, setLine] = useState(null);

  const [idle, setIdle] = useState(false);

  const [mapZoom, setMapZoom] = useState(10);

  const [npsData, setNpsData] = useState({});

  const [visible, setVisible] = useState(true);

  const [dirDist, setDirDist] = useState(null);

  const [showSat, setShowSat] = useState(false);

  const [mapWidth, setMapWidth] = useState(460);

  const [showNPS, setShowNPS] = useState(false);

  const [showCOD, setShowCOD] = useState(false);

  const [dirMidWay, setDirMidWay] = useState(null);

  const [currentMode, setCurrentMode] = useState('');

  const [directions, setDirections] = useState(null);

  const [clippedDir, setClippedDir] = useState(null);

  const [popupModal, setPopupModal] = useState(false);

  const [drawFeatures, setDrawFeatures] = useState({});

  const [showPrivate, setShowPrivate] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [largeProjects, setLargeProjects] = useState(false);

  const filters = useSelector((state) => state.filters);

  const [getStations, setGetStations] = useState(false);

  const [vQInfralayers, setVQInfraLayers] = useState([]);

  const projects = useSelector((state) => state.projects);

  const [viewLarge, setViewLarge] = useState(false);

  const [showHeatmap, setShowHeatmap] = useState(false);

  const [showCompleted, setShowCompleted] = useState(false);

  const [showProgressing, setShowProgressing] = useState(false);

  const [polygonPopup, togglePolygonPopup] = useState(false);

  const [selectedPolygonData, setPolygonData] = useState({});

  const [isPlanningStations, setIsPlanningStations] = useState(false);

  const wireScheme = useSelector((state) => state.wireScheme);

  const substation = useSelector((state) => state.substations);

  const prompt = useSelector((state) => state.gptOsmData.prompt);

  const search_by = useSelector(state => state.filters.search_by);

  const drawnLine = useSelector(state => state.filters.drawnLine);

  const osmData = useSelector((state) => state.gptOsmData.osmData);

  const mapLoader = useSelector((state) => state.filters.mapLoader);
  
  const takeImage = useSelector((state) => state.projects.takeImage);

  const route = useSelector((state) => state.selectedSubstation.route);
  
  const activeLayers = useSelector((state) => state.dataset.activeLayers);

  const planningLPA = useSelector((state) => state.filters.LPALayer);

  const searchedPolygon = useSelector(state => state.filters.drawnPolygon);

  const substations = useSelector((state) => state.substations.substations);

  const currentCenter = useSelector((state) => state.filters.currentCenter);

  const userLocation = useSelector((state) => state.substations.userLocation);

  const searchedParcel = useSelector((state) => state.substations.searchedParcel);

  const allowSecSubs = useSelector((state) => state.selectedSubstation.allowSecSubs);

  const selectedSubstation = useSelector((state) => state.selectedSubstation.substation);

  const activeRenewableLayers = useSelector((state) => state.dataset.activeRenewableLayers);

  const secondarySubstations = useSelector((state) => state.selectedSubstation.secondarySubstations);

  const leftSidebarSelectedOption = useSelector((state) => state.substations.leftSidebarSelectedOption);


  const buttonTextArray = [
    "Private Wire Scheme",
    "Secondary Acquisitions",
    "Transformer Analysis",
    // "Mixed Approach",
  ];

  const analysisArray = [
    {
      id: 1,
      type: PrometheuesHome,
      text: "Bypass the grid, connect directly to nearby customers for cost savings and local renewable energy sharing.",
      points: [
        "Cost Savings",
        "Increased Energy Independence",
        "Environmental Benefits",
      ],
    },
    {
      id: 2,
      type: PrometheuesEnergy,
      text: "Acquire existing operational renewable energy projects for faster deployment.",
      points: [
        "Faster Deployment",
        "Proven Track Record",
        "Portfolio Diversification",
      ],
    },
    {
      id: 3,
      type: PrometheuesTransfer,
      text: "Evaluate transformer performance to ensure grid reliability.",
      points: [
        "Improved Grid Reliability",
        "Optimizing Efficiency",
        "Informed Investment Decisions",
      ],
    },
    // {
    //   id: 4,
    //   type: PrometheuesTree,
    //   text: "Combine strategies (new projects, auctions, storage) for flexible and cost-effective project energy development.",
    //   points: ["Reduced Risk", "Flexibility", "Cost Optimization"],
    // },
  ];

  const handleClose = (e) => {
    dispatch(setPromethousModal(false));
    // setOpen(false);
    e.stopPropagation();
  };

  const [viewport, setViewport] = useState({
    longitude: userLocation
      ? userLocation.lat
      : currentCenter
        ? currentCenter.lat
        : -0.1275862,
    latitude: userLocation
      ? userLocation.lng
      : currentCenter
        ? currentCenter.lng
        : 51.5072178,
    zoom: 10,
    bearing: 0,
    pitch: 0,
  });

  const myAPIKey = "x9XraodfUzhGDSaEGk2KpBF6S2nUEMpQ";

  const myGoogleKey = "AIzaSyCQFqXwp4QZGkJuCQr6SjPwiTr8QHckksg";

  // let hoveredStateId = null;

  // const hoverPopup = new Popup({
  //   closeButton: false,
  //   closeOnClick: false,
  //   anchor: 'bottom',
  //   dynamicPosition: false,
  // });

  // const wimdLayer = {
  //   "id": "wimd",
  //   "type": "fill",
  //   "source": "wimd",
  //   "layout": {},
  //   "paint": {
  //     "fill-color": "#ef5350",
  //     "fill-outline-color": "#b71c1c",
  //     "fill-opacity": 0.5
  //   },
  // };

  // const Large_Project_Layer = {
  //   "id": "large_projects",
  //   "type": "symbol",
  //   "source": "vq_postgis",
  //   "source-layer": "large_projects",
  //   "layout": {
  //     "icon-image": "planning_image",
  //     "icon-size": 0.025,
  //     "icon-allow-overlap": true,
  //   },
  // };

  const Reinforcement_Progressing_Layer = {
    id: "reinforcementProgressingLayer",
    type: "symbol",
    source: "progressing_reinforcement",
    layout: {
      "icon-image": "progressing_image",
      "icon-size": 0.0825,
      "icon-allow-overlap": true,
    },
  };

  const Reinforcement_Completed_Layer = {
    id: "reinforcementCompletedLayer",
    type: "symbol",
    source: "completed_reinforcement",
    layout: {
      "icon-image": "completed_image",
      "icon-size": 0.0825,
      "icon-allow-overlap": true,
    },
  };

  const Heatmap_Layer = {
    id: "reinforcementHeatmapLayer",
    type: "fill",
    source: "heatmap_reinforcement",
    layout: {},
    paint: {
      "fill-color": "#b71c1c",
      "fill-opacity": 0.33,
      "fill-outline-color": "#b71c1c",
    },
  };

  const privateLandLayer = {
    "id": "private_land",
    "type": "fill",
    "source": "vqprivatelandmap",
    "source-layer": "private_land",
    "layout": {},
    "paint": {
      "fill-color": "#81c784",
      "fill-outline-color": "#1b5e20",
      "fill-opacity": 0.5
    },
  };

  const npsFillLayer = {
    "id": "nps",
    "type": "fill",
    "source": "vqnpsmap",
    "source-layer": "nps",
    "layout": {},
    "paint": {
      "fill-color": "#ffcc80",
      "fill-outline-color": "#ff6d00",
      "fill-opacity": 0.5
      // "fill-color": ["match", ["get", "tenure"],
      //   "Freehold", "#13b3e8", 
      //   "Leasehold", "#e83313",
      //   "#e83313"
      // ],
      // "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 2, 0.5],
      // "fill-outline-color": ["match", ["get", "Tenure"],
      //   "Freehold", "#34c0eb", 
      //   "Leasehold", "#e83313",
      //   "#e83313"
      // ],
    },
  };

  // const npsLineLayer = {
  //   "id": "nps-outline-layer",
  //   "type": "line",
  //   "source": "nps",
  //   "layout": {},
  //   "paint": {
  //     'line-color': '#000',
  //     'line-width': 2,
  //   },
  // };

  // const codFillLayer = {
  //   id: "nps",
  //   type: "fill",
  //   source: "nps",
  //   layout: {},
  //   paint: {
  //     'fill-color': '#088',
  //     'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 2, 0.5]
  //   },
  // };

  const ccodLayer = {
    "id": "ccod_nps",
    "type": "fill",
    "source": "cod",
    "source-layer": "ccod_nps",
    "paint": {
      "fill-opacity": 0.5,
      "fill-color": ["match", ["get", "Tenure"],
        "Freehold", "#34c0eb",
        "Leasehold", "#e83313",
        "#e83313"
      ],
      // "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 2, 0.5],
      // "fill-outline-color": ["match", ["get", "Tenure"],
      //   "Freehold", "#e65100", 
      //   "Leasehold", "#0d47a1",
      //   "#e83313"
      // ],
    }
  };

  const ocodLayer = {
    "id": "ocod_nps",
    "type": "fill",
    "source": "cod",
    "source-layer": "ocod_nps",
    "paint": {
      "fill-opacity": 0.5,
      "fill-color": ["match", ["get", "Tenure"],
        "Freehold", "#34c0eb",
        "Leasehold", "#e83313",
        "#e83313"
      ],
      // "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 2, 0.5],
      // "fill-outline-color": ["match", ["get", "Tenure"],
      //   "Freehold", "#ff9100", 
      //   "Leasehold", "#ba68c8",
      //   "#e83313"
      // ],
    }
  };

  const Lpa_Layer = {
    id: "LPA_Layer",
    type: "fill",
    source: "LPA",
    layout: {},
    paint: {
      "fill-color": "#ef5350",
      "fill-opacity": 0.25
    },
  };

  const Lpa_Line = {
    id: "LPA_Line",
    type: "line",
    source: "LPA",
    layout: {},
    paint: {
      "line-color": "#d50000",
      "line-width": 3,
    },
  };

  const Searched_Parcel_Fill = {
    id: "Searched_Parcel_Fill",
    type: "fill",
    source: "Searched_Parcel",
    layout: {},
    paint: {
      "fill-color": "#ef5350",
      "fill-opacity": 0.25
    },
  };

  const Searched_Parcel_Line = {
    id: "Searched_Parcel_Line",
    type: "line",
    source: "Searched_Parcel",
    layout: {},
    paint: {
      "line-color": "#d50000",
      "line-width": 3,
    },
  };

  // const wmsLayer = {
  //   id: "wms-geoserver-layer",
  //   type: "raster",
  //   source: "wms",
  //   paint: {},
  // };

  const _3dLayer = {
    "id": "OS/TopographicArea_2/Building/1_3D",
    "type": "fill-extrusion",
    "source": "esri",
    "source-layer": "TopographicArea_2",
    "filter": [
      "==",
      "_symbol",
      4
    ],
    "minzoom": 16,
    "layout": {},
    "paint": {
      "fill-extrusion-color": "#DCD7C6",
      "fill-extrusion-height": [
        "interpolate",
        ["linear"],
        ["zoom"],
        16,
        0,
        16.05,
        ["get", "RelHMax"]
      ],
      "fill-extrusion-opacity": [
        "interpolate",
        ["linear"],
        ["zoom"],
        16,
        0,
        17,
        0.9
      ]
    }
  };

  const renewableLayer = {
    id: "wms-renewable-layers",
    type: "raster",
    source: "wmsRenewable",
    paint: {},
  };

  const mapStyle = {
    "version": 8,
    "sources": {
      "google-satellite": {
        "type": "raster",
        "tiles": ["https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=" + myGoogleKey],
        "tileSize": 256,
      }
    },
    "bounds": [
      -10.76418,
      50.528423,
      1.9134116,
      60.331151
    ],
    "center": [
      -0.1275862,
      51.5072178,
      10
    ],
    "layers": [{
      "id": "google-satellite",
      "type": "raster",
      "source": "google-satellite",
      "layout": { 'visibility': 'visible' },
      "paint": { "raster-opacity": 1 },
      "minzoom": 0,
      "maxzoom": 22
    }],
    // "sprite": "/vqSprites",
    // "glyphs": "/vq-hackathon/fonts/{fontstack}/{range}.pbf",
    "sprite": "https://api.os.uk/maps/vector/v1/vts/resources/sprites/sprite",
    "glyphs": "https://api.os.uk/maps/vector/v1/vts/resources/fonts/{fontstack}/{range}.pbf",
  };

  const map_style = 'https://raw.githubusercontent.com/OrdnanceSurvey/OS-Vector-Tile-API-Stylesheets/master/OS_VTS_3857_Light.json';
  // const map_style = 'https://gist.githubusercontent.com/Yasir-Voltquant/c034766db2197db5c7e1f3edcdff1e05/raw/dfbd584a966698e053b5dbc935b7fb8da2c328f8/BaseMap.json';

  const tegolaSource = {
    id: "mapLayers",
    type: "vector",
    tiles: ["https://backend.voltquant.uk/tegola/maps/vq_postgis/{z}/{x}/{y}.pbf?"]
  };

  const roadSpeedLayers = [
    "roadspeed_indicative",
    "roadspeed_avgday",
    "roadspeed_avgnight",
  ];

  const shouldRenderSpeedLegend = roadSpeedLayers.some((item) =>
    activeLayers.includes(item)
  );

  const shouldRenderRenewableLayers = activeLayers.includes("tidal-lagoon-renewable");
  
  const onUpdate = useCallback((e) => {
    setDrawFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
    const keys = Object.keys(e.features);
    const lastKey = keys[keys.length - 1];
    const geom = e.features[lastKey];
    const centroids = turf.centroid(geom);
    if (geom.geometry.type === 'Polygon') {
      dispatch(setDrawnPolygon(geom))
      dispatch(setPolygonCentroid({ lat: centroids?.geometry?.coordinates[1], lng: centroids?.geometry?.coordinates[0] }))
    } else if (geom.geometry.type === 'LineString') {
      dispatch(setDrawnLine(geom))
    }
  }, []);

  const onDelete = useCallback((e) => {
    setDrawFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
    const keys = Object.keys(e.features);
    const lastKey = keys[keys.length - 1];
    const geom = e.features[lastKey];
    if (geom.geometry.type === 'Polygon') {
      dispatch(setDrawnPolygon(null))
      dispatch(setPolygonCentroid(null))
    } else if (geom.geometry.type === 'LineString') {
      dispatch(setDrawnLine(null))
    }
  }, []);

  const findClosestSubstations = () => {
    const { userLocation } = substation;
    const {
      assetType,
      minRadius,
      maxRadius,
      minCapacity,
      // maxCapacity,
      demand,
      generation,
      radiusUnit,
      capacityUnit,
      showAllSubstations,
      search_by,
      substationType,
      siteVoltage,
      source,
      constrained,
      searchValue
    } = filters;

    let body = {
      assetType,
      userLocation,
      minRadius,
      maxRadius,
      minCapacity,
      // maxCapacity,
      demand,
      generation,
      radiusUnit,
      capacityUnit,
      search_by,
      searched_location: searchValue,
      getAll: showAllSubstations === "true" ? true : false,
    };
    if (leftSidebarSelectedOption === "search") {
      dispatch(setTransmission("Transformer Analysis"));
      fetchClosestSubstations(body)
        .then((response) => {
          if (response.success) {
            dispatch(setSelectedSubstation(""));
            if (response?.data?.closestSubstations?.length > 0) {
              dispatch(setRightSideBarOpen(true))
            }
            // setZoomValue(response?.data?.zoomValue);
            dispatch(setSubstations(response?.data?.closestSubstations));
            dispatch(setMapLoader(false));
            dispatch(triggerClosestPoints(false));
            setGetStations(!getStations);
          } else {
            dispatch(setSubstations([]));
            dispatch(setMapLoader(false));
            dispatch(triggerClosestPoints(false));
            setGetStations(!getStations);
          }
        })
        .catch((err) => {
          dispatch(setSubstations([]));
          console.log(err);
        });
        getPrometheusData()
      } else {
        const baseUrl = `https://www.planit.org.uk/api/applics/json?search=${constrained}&not_near=true&lat=${userLocation?.lat}&lng=${userLocation?.lng}&krad=${maxRadius}&recent=0`;
        let url =
        substationType === "All"
        ? baseUrl
        : `${baseUrl}&app_size=${substationType}`;
        url = source === "All" ? url : `${url}&app_state=${source}`;
        url = siteVoltage === "All" ? url : `${url}&app_type=${siteVoltage}`;
        axios
        .get(url)
        .then((response) => {
          if (response?.data?.total > 0) {
              console.log("response", response);
              dispatch(setSelectedSubstation(""));
              const sortedData = response?.data?.records?.sort((a, b) => new Date(b.start_date) - new Date(a.start_date))
              dispatch(setSubstations(sortedData));
              dispatch(setMapLoader(false));
              dispatch(triggerClosestPoints(false));
              setGetStations(!getStations);
          } else {
              ToastNotification("warn", "No planning applications found.");
              dispatch(setSubstations([]));
              dispatch(setMapLoader(false));
              dispatch(triggerClosestPoints(false));
              setGetStations(!getStations);
          }
        })
        .catch((err) => {
            // ToastNotification("error", "Search location not found");
            ToastNotification("warn", "Too many results, please reduce radius or select some filters");
            dispatch(setMapLoader(false));
            dispatch(triggerClosestPoints(false));
            dispatch(setSubstations([]));
            console.log(err);
        });
      }
  };

  const getPrometheusData = () => {
    dispatch(setLoading("true"));
    const { userLocation } = substation;
    const {
      minRadius,
      maxRadius,
      minCapacity,
      // maxCapacity,
      demand,
      generation,
      radiusUnit,
      capacityUnit,
      search_by,
      searchValue,
      assetType,
    } = filters;

    let body2 = {
      assetType,
      userLocation,
      minRadius,
      maxRadius,
      minCapacity,
      // maxCapacity,
      demand,
      generation,
      radiusUnit,
      capacityUnit,
      search_by,
      searched_location: searchValue,
      connection_status: wireScheme.privateWireButtonText,
      // getAll: showAllSubstations === "true" ? true : false,
      getAll: true,
    };
    fetchPrometheusData(body2)
      .then((response) => {
        if (response.success) {
          dispatch(setPrivateWire(response?.data?.privateWire));
          dispatch(
            setSecondaryAcquisition(response?.data?.secondaryAcquisition)
          );
          dispatch(setLoading("false"));
        } else {
          dispatch(setPrivateWire([]));
          dispatch(setSecondaryAcquisition([]));
          dispatch(setLoading("false"));
        }
      })
      .catch((err) => {
        dispatch(setPrivateWire([]));
        dispatch(setSecondaryAcquisition([]));
        dispatch(setLoading("false"));

        console.log(err);
      });
  };

  useEffect(() => {
    console.log("Search Parcel Data", mapRef?.current?.getMap(), searchedParcel);
      if(mapRef.current) {
        console.log("Zooming Search Parcel", searchedParcel?.data);
        const map = mapRef.current.getMap();
        const searchedParcelBBox = searchedParcel?.data?.features?.length > 0 ? turf.bbox(searchedParcel?.data) : undefined;
        console.log("Zoom Search Parcel", searchedParcelBBox);
        if(searchedParcelBBox) {
          const centerX = (searchedParcelBBox[0] + searchedParcelBBox[2]) / 2;
          const centerY = (searchedParcelBBox[1] + searchedParcelBBox[3]) / 2;
          const center = [centerX, centerY];
          // map.fitBounds(searchedParcelBBox, {padding: 520});
          // map.setZoom(map.getZoom() + 1);
          // map.setCenter(center);
          map.flyTo({
            center: center,
            zoom: map.getZoom() + 1,
          });
        }
      }
  }, [JSON.stringify(searchedParcel)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getPrometheusData();
  }, [wireScheme.privateWireButtonText]) // eslint-disable-line react-hooks/exhaustive-deps

  const findLocalPlanningClosestPoints = (substation) => {
    let body = {
      substation,
    };


    fetchClosestLocalPlanningProjects(body)
      .then((response) => {
        if (response.success) {
          dispatch(setPlanningData(response?.data?.closestProjects));
        }
      })
      .catch((err) => {
        dispatch(setPlanningData([]));
        console.log(err);
      });
  };

  const findNearestNcpr = (substation) => {

    let body = {
      latitude: substation?.location_y ? substation?.location_y : substation.Lattitude,
      longitude: substation?.location_x ? substation?.location_x : substation.Longitute,
    };

    getNearestNcpr(body)
      .then((response) => {
        if (response.success) {
          dispatch(setPlanningData(response?.data));
        } else {
          dispatch(setPlanningData({}));
        }
      })
      .catch((err) => {
        dispatch(setPlanningData({}));
        console.log(err);
      });

  };

  const findGeoSpatialRiskLayers = () => {
    const body = {
      longitude: userLocation?.lng,
      latitude: userLocation?.lat,
      bufferRadius: 1,
      layers: GeospatialRiskLayers,
    };
    getLayers(body).then((response) => {
      if (response.success) {
        // filter response.data array and exculde these two values: "brownfield-land", "brownfield-site"
        const layers = response.data.filter(layer => !["brownfield-land", "brownfield-site"].includes(layer));
        dispatch(setGeospatialLayers(layers))
      }
    });
  };

  const findDirections = async (substation, userLocation) => {
    let body = {
      origin: {lat: userLocation.lat, lng: userLocation.lng},
      destination: {lat: substation?.location_y ? substation?.location_y : parseFloat(substation.Lattitude), lng: substation?.location_x ? substation?.location_x : parseFloat(substation.Longitute)},
      capacity: filters?.capacityUnit === "kW" ? filters?.minCapacity / 1000 : filters?.minCapacity,
      allowSecSubs: allowSecSubs ? allowSecSubs : false,
    };
    getDirections(body)
      .then((response) => {
        if (response.success) {
          dispatch(setRoute(response?.data));
        } else {
          dispatch(setRoute({}));
        }
      })
      .catch((err) => {
        dispatch(setRoute({}));
        console.log(err);
      });

  };

  const findHabitatLayers = (userLocation) => {
    let body = {
      latitude: userLocation?.lat,
      longitude: userLocation?.lng,
    };

    getHabitat(body)
      .then((response) => {
        if (response.success) {
          console.log("Habitat Api response", response);
          dispatch(setHabitatData(response?.data));
        } else {
          dispatch(setHabitatData([]));
        }
      })
      .catch((err) => {
        dispatch(setHabitatData([]));
        console.log(err);
      });
  };

  const findEnclosingPolygon = (substation) => {
    let body = {
      latitude: substation?.location_y ? substation?.location_y : substation.Lattitude,
      longitude: substation?.location_x ? substation?.location_x : substation.Longitute,
    };

    getEnclosingPolygon(body)
      .then((response) => {
        if (response.success) {
          // console.log("Enclosing Api response", response);
          dispatch(setLandOwnerData(response?.data));
        } else {
          dispatch(setLandOwnerData([]));
        }
      })
      .catch((err) => {
        dispatch(setLandOwnerData([]));
        console.log(err);
      });
  };

  const handleClickMarker = (e, substation) => {
    e.preventDefault();
    e.stopPropagation();
    findDirections(substation, userLocation);
    findEnclosingPolygon(substation);
    dispatch(
      setFilterValues({
        source: substation?.Source,
        voltageLine: substation?.VoltageLine,
      })
    );
    dispatch(setSelectedSubstation(substation));
    if (filters.assetType === "EV Infrastructure") {
      findNearestNcpr(substation)
    } else {
      findLocalPlanningClosestPoints(substation);
    }
    dispatch(setFeatues([]));
  };

  const handleSecondaryMarkerClick = (e, substation) => { 
    e.preventDefault();
    e.stopPropagation();
    const substationData = {
      Lattitude: substation[1],
      Longitute: substation[0]
    }
      findDirections(substationData, userLocation);
  };

  const handlePolygonLayer = (data) => {
    setPopupModal(!popupModal);
    setNpsData(data);
  };

  const handleDragEnd = () => {
    const newCenter = mapRef.current.getMap().getCenter();
    const newViewport = {
      ...viewport,
      latitude: newCenter.lat,
      longitude: newCenter.lng,
    };
    dispatch(setCurrentCenter(newCenter));
    setViewport(newViewport);
  };

  const onMapLoad = () => {
    console.log("Inside OnLoad");
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      setTimeout(() => {
        // map.addSprite('vqSprite', '/vqSprites/vqSprite');
        // console.log("Map loaded:", map.getSprite(), map.getStyle().layers);
        if (!map.hasImage("ncpr_image")) {
          map.loadImage(
            '/icons/ev-Icon-filled.png',
            (error, image) => {
              if (error) throw error;
              map.addImage("ncpr_image", image);
            }
          );
        }
        if (!map.hasImage("progressing_image")) {
          map.loadImage(
            "/icons/hammer-loader-pin+3.png",
            (error, image) => {
              if (error) throw error;
              map.addImage("progressing_image", image);
            }
          );
        }
        if (!map.hasImage("completed_image")) {
          map.loadImage(
            "/icons/hammer+loader-pin+4.png",
            (error, image) => {
              if (error) throw error;
              map.addImage("completed_image", image);
            }
          );
        }
        if (!map.hasImage("planning_image")) {
          map.loadImage(
            "/icons/doc-icon.png",
            (error, image) => {
              if (error) throw error;
              map.addImage("planning_image", image);
            }
          );
        }
      }, 2000)
      if (
        userLocation !== null &&
        userLocation.lng !== undefined &&
        userLocation.lat !== undefined
      ) {
        const latitude = userLocation.lat;
        const longitude = userLocation.lng;
        const zoom = 12;
        setViewport({ ...viewport, zoom, longitude, latitude });
        map.flyTo({ center: [longitude, latitude] });
        // } else if (substations === null || userLocation === null) {
        //   const latitude = 51.5072178;
        //   const longitude = -0.1275862;
        //   const zoom = 10;
        //   setViewport({ ...viewport, zoom, longitude, latitude });
        //   map.flyTo({ center: [longitude, latitude] });
      } else if (currentCenter === null && userLocation === null && substations === null) {
        const newViewport = {
          ...viewport,
          latitude: -0.1275862,
          longitude: 51.5072178,
        };
        setViewport(newViewport);
        map.setCenter([-0.1275862, 51.5072178]);
      }

      // map.on('click', 'nps', (e) => {
      //   map.getCanvas().style.cursor = 'pointer';
      //   if (e.features.length > 0) {
      //     console.log("Nps title: ", e.features[0].properties);
      //     const title_number = e.features[0].properties.title_no;
      //     const record_status = e.features[0].properties.rec_status;
      //     const properties = {
      //       title_number,
      //       record_status
      //     };
      //     const data = {properties};
      //     handlePolygonLayer(data);
      //   }
      // });
      // map.on('click', 'wimd', (e) => {
      //   map.getCanvas().style.cursor = 'pointer';
      //   if (e.features.length > 0) {
      //     console.log("wimd: ", e.features[0]);
      //     const data = e.features[0];
      //     handlePolygonLayer(data);
      //   }
      // });
      // map.on('click', 'ccod_nps', (e) => {
      //   map.getCanvas().style.cursor = 'pointer';
      //   if (e.features.length > 0) {
      //     console.log("ccod: ", e.features[0]);
      //     const data = e.features[0];
      //     handlePolygonLayer(data);
      //   }
      // });
      // map.on('click', 'ocod_nps', (e) => {
      //   map.getCanvas().style.cursor = 'pointer';
      //   if (e.features.length > 0) {
      //     console.log("ocod: ", e.features[0]);
      //     const data = e.features[0];
      //     handlePolygonLayer(data);
      //   }
      // });
      // map.on('mouseenter', 'nps', (e) => {
      //     map.getCanvas().style.cursor = 'pointer';
      //     if (e.features.length > 0) {
      //       if (hoveredStateId) {
      //         map.removeFeatureState(
      //           {source: 'nps', sourceLayer: 'nps', id: hoveredStateId},
      //           {hover: false}
      //         );
      //       }
      //       hoveredStateId = e.features[0].id;
      //       map.setFeatureState({
      //         source: 'nps',
      //         sourceLayer: 'nps',
      //         id: e.features[0].id,
      //       }, {
      //         hover: true
      //       });
      //       const polygon = turf.polygon([e.features[0].geometry.coordinates[0]]);
      //       const centr = turf.centroid(polygon);
      //       const description = e.features[0].properties.title_no;
      //       hoverPopup.setLngLat(centr.geometry.coordinates).setHTML(description).addTo(map);
      //     }
      // });
      // map.on('mouseleave', 'nps', () => {
      //     map.getCanvas().style.cursor = '';
      //     hoverPopup.remove();
      //     if (hoveredStateId) {
      //       map.setFeatureState(
      //           {source: 'nps', sourceLayer: 'nps', id: hoveredStateId},
      //           {hover: false}
      //       );
      //     }
      //     hoveredStateId = null;
      // });
      // add3DBuildings(map);
    }
  };

  const onMapClick = (e) => {
    e.preventDefault();
  };

  const onRouteLoaded = (route) => {
    setDirDist(route?.totalDis);
    setDirMidWay(route?.pathMidway);
    setDirections(route?.path);
    if (route?.secSubs?.length > 0) {
      const secSubs = route?.secSubs;
      const secSubsPoints = [];
      secSubs.forEach((sub) => {
        const subPoint = JSON.parse(sub.subPoints);
        const subCoords = JSON.parse(subPoint);
        const coords = subCoords.coordinates;
        secSubsPoints.push([coords[0], coords[1]]);
      })
      dispatch(setSecondarySubstations(secSubsPoints));
    }
    if (Array.isArray(route?.clipedRoute) && route?.clipedRoute?.length > 0) {
      const clipping = route?.clipedRoute[0]?.clippedRoute;
      const clipped_coordinates = JSON.parse(clipping);
      setClippedDir(clipped_coordinates?.coordinates);
    } else {
      const clipping = route?.path;
      const clipped_coordinates = clipping.map(point => [point.longitude, point.latitude]);
      setClippedDir(clipped_coordinates);
    }
};

  const onMapIdle = () => {
    console.log("Map Idle detected", idle);
    if (idle === false) setIdle(true);
  };

  const handleConfirm = () => {
    const map = mapRef.current.getMap();
    deleteAllFromParent();
    if (currentMode === 'draw_polygon' && searchedPolygon) {
      dispatch(setDrawnPolygon(null));
      dispatch(setPolygonCentroid(null));
    } else if (currentMode === 'draw_line_string' && drawnLine) {
      dispatch(setDrawnLine(null));
    }
    setDrawFeatures({});
    setDeleteModal(false);
    drawControlRef.current.handleMode();
  };

  const handleCancel = () => {
    setDeleteModal(false);
    drawControlRef.current.handleMode();
  };

  const handleDraw = useCallback((e) => {
    if (e.mode === 'draw_polygon' && searchedPolygon) {
      setCurrentMode(e.mode);
      setDeleteModal(true);
    } else if (e.mode === 'draw_line_string' && drawnLine) {
      setCurrentMode(e.mode);
      setDeleteModal(true);
    }
  }, [searchedPolygon, drawnLine]);

  const deleteAllFromParent = () => {
    drawControlRef.current.deleteAllDrawings();
  };

  const exportMapAsPNG = (map) => {
    const target = document.getElementById("mapCanvas1");
    const controls = document.querySelector(".maplibregl-control-container");
    html2canvas(target, {
      allowTaint: true,
      useCORS: true,
      logging: false,
      ignoreElements: (element) => controls.contains(element),
    }).then(async (canvas) => {
      const imgSrc = canvas.toDataURL();
      // const link = document.createElement('a');
      // link.href = imgSrc;
      // link.download = "size.png";
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      dispatch(setMapImage(imgSrc));
      dispatch(setTakeImage(false));
    });
  };

  const updateEnabledLayers = () => {
    let body = {
      updatedLayers: activeLayers
    }
    updateActiveLayers(body).then((res) => {
      console.log("Layers updated successfully")
    })
  };

  // useEffect(() => {
  //   console.log("Inside useEffect for map idle");
  //   if (mapRef.current) {
  //     const map = mapRef.current.getMap();
  //     setTimeout(()=>{
  //       add3DBuildings(map);
  //     }, 5000);
  //   }
  // }, [showSat]); // eslint-disable-line react-hooks/exhaustive-deps

  // const [geojsonData, setGeojsonData] = useState(null);

  // useEffect(() => {
  //   // Fetch the GeoJSON data from S3
  //   const fetchGeoJSON = async () => {
  //     try {
  //       const response = await fetch('https://voltquant-images.s3.eu-west-2.amazonaws.com/wimd2019_merged.geojson');
  //       const data = await response.json();
  //       setGeojsonData(data);
  //       console.log("GeoJSON data fetched successfully");
  //     } catch (error) {
  //       console.error('Error fetching GeoJSON:', error);
  //     }
  //   };

  //   fetchGeoJSON();
  // }, []);

  useEffect(() => {
    const delayedLoad = () => {
      setClippedDir(null);
      if (mapRef.current && Object.keys(route).length > 0) {
        onRouteLoaded(route);
      }
    };
    const timeoutId = setTimeout(delayedLoad, 50);
    return () => clearTimeout(timeoutId);
  }, [JSON.stringify(route)]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if (mapRef.current && takeImage === true) {
      const map = mapRef.current.getMap();
      console.log("Calling exportMapAsPNG");
      exportMapAsPNG(map);
    }
  }, [takeImage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.on('draw.modechange', handleDraw);
    }
    return () => {
      const map = mapRef?.current?.getMap();
      map?.off('draw.modechange', handleDraw);
    }
  }, [drawFeatures, searchedPolygon, drawnLine]);

  useEffect(() => {
    const oim_layers = style_oim_power.concat(style_oim_petroleum);
    oim_layers.sort((a, b) => {
      if (a["zorder"] < b["zorder"]) return -1;
      if (a["zorder"] > b["zorder"]) return 1;
      return 0;
    });
    setVQInfraLayers(oim_layers);
  }, []);

  // useEffect(() => {
  //   if (!activeLayers?.includes('large_projects')) {
  //     dispatch(appendNewLayer({ layerName: 'large_projects' }));
  //   } else {
  //     dispatch(removeLayer({ layerName: 'large_projects' }));
  //   }
  // }, [viewLarge]);

  useEffect(() => {
    if (substations.length > 0 && mapRef.current) {
      const map = mapRef.current.getMap();
      let bounds = new LngLatBounds();
      substations.forEach((sub) => {
        if (sub.Lattitude && sub.Longitute) {
          bounds.extend([sub.Longitute, sub.Lattitude]);
        } else if (sub.location_x && sub.location_y) {
          bounds.extend([sub.location_x, sub.location_y]);
        }
      });
      map.fitBounds(bounds, { padding: 150 });
    }
  }, [substations, mapRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (substations[0]?.location_x && substations[0]?.location_y) {
      setIsPlanningStations(true);
    }
  }, [JSON.stringify(substations)]); 

  useEffect(() => {
    findClosestSubstations();
  }, [substation.isFindClosestPoints]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (projects?.leftSidebarOpen && projects?.rightSidebarOpen) {
      setMapWidth(835);
    } else if (projects?.leftSidebarOpen && !projects?.rightSidebarOpen) {
      setMapWidth(460);
    } else if (!projects?.leftSidebarOpen && projects?.rightSidebarOpen) {
      setMapWidth(495);
    } else {
      setMapWidth(120);
    }
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.resize();
    }
  }, [projects?.leftSidebarOpen, projects?.rightSidebarOpen, mapRef.current]);

  useEffect(() => {
    if (
      selectedSubstation === null &&
      substations.length > 0 &&
      mapRef.current
    ) {
      const map = mapRef.current.getMap();
      let bounds = new LngLatBounds();
      substations.forEach((sub) => {
        if (sub.Lattitude && sub.Longitute) {
          console.log("Not Planning")
          bounds.extend([sub.Longitute, sub.Lattitude]);
        } else if (sub.location_x && sub.location_y) {
          console.log("Planning")
          bounds.extend([sub.location_x, sub.location_y]);
        }
      });
      map.fitBounds(bounds, { padding: 150 });
      // map.flyTo({ center: [viewport.longitude, viewport.latitude] })
    }
    if (selectedSubstation && userLocation) {
      const lineString = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              properties: {},
              coordinates: [
                [userLocation.lng, userLocation.lat],
                [selectedSubstation?.location_x ? selectedSubstation?.location_x : selectedSubstation.Longitute, selectedSubstation?.location_y ? selectedSubstation?.location_y : selectedSubstation.Lattitude],
              ],
            },
          },
        ],
      };
      setLine(lineString);
      if (mapRef.current) {
        const map = mapRef.current.getMap();
        const coordinates = lineString.features[0].geometry.coordinates;
        const bounds = coordinates.reduce((bounds, coord) => {
          return bounds.extend(coord);
        }, new LngLatBounds(coordinates[0], coordinates[0]));
        map.fitBounds(bounds, {
          padding: 200,
        });
      }
    }
  }, [selectedSubstation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const delayedLoad = () => {
      if (mapRef.current) {
        onMapLoad();
      }
    };
    const timeoutId = setTimeout(delayedLoad, 10);
    return () => clearTimeout(timeoutId);
  }, [mapRef.current, userLocation, activeLayers, mapLoader]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (substation.substations.length > 0 || userLocation) {
      let listOfLayers = Object.values(links);
      const { userLocation, substations } = substation;
      const farthestSubstationDistance =
        substations[substations.length - 1]?.distance;
      listOfLayers = listOfLayers.concat(activeRenewableLayers)
      const body = {
        longitude: userLocation?.lng,
        latitude: userLocation?.lat,
        bufferRadius: farthestSubstationDistance + 2,
        layers: listOfLayers,
      };
      getLayers(body).then((response) => {
        if (response.success) {
          dispatch(setSelectedLayers(response.data));
        } else {
          dispatch(setSelectedLayers([]));
        }
      });
      findGeoSpatialRiskLayers()
    }
  }, [getStations]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prompt && mapRef.current) {
      ToastNotification('info', "Searching Please wait..");
      const map = mapRef.current.getMap();;
      const bounds = map.getBounds();
      const location = "" + bounds.getSouthWest().lat + "," + bounds.getSouthWest().lng + "," + bounds.getNorthEast().lat + "," + bounds.getNorthEast().lng + "";
      const body = {
        location: location,
        prompt: prompt
      };
      getGptOsmData(body, { timeout: 100000 }).then((response) => {
        if (response.success) {
          if (response?.data.geojson?.features?.length > 0) {
            const newOsmData = [...osmData]
            const newObject = {
              geojson: response?.data?.geojson,
              name: response?.data?.layerName,
              show: true,
              color: osmDataColor[osmData?.length]
            }
            newOsmData.push(newObject)
            dispatch(setOsmData(newOsmData))
            ToastNotification('success', "Query Run Succesfully");
            ToastNotification('success', `No. of Features Found:${response?.data.geojson?.features?.length}`);
            dispatch(setGptPrompt(null))
          } else {
            ToastNotification('warning', 'No Features Found, Try Again');
            dispatch(setGptPrompt(null))
          }
        } else {
          ToastNotification('error', 'Failed search, report a bug and refresh page')
          dispatch(setGptPrompt(null))
        }
      });
    }
  }, [prompt]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeLayers?.length > 0) {
      updateEnabledLayers()
    }
  }, [activeLayers])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (JSON.stringify(userLocation).length > 0) {
      findHabitatLayers(userLocation);
    }
  }, [JSON.stringify(userLocation)])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div id="mapCanvas" className="mapArea" style={{ padding: "20px" }}>
        {mapLoader ? (
          <Loader />
        ) : (
          <>
            {shouldRenderSpeedLegend && (
              <div className="legend-container">
                <SpeedLegend />
              </div>
            )}
            <Map
              id="mapCanvas1"
              ref={mapRef}
              key={JSON.stringify(showSat)}
              reuseMaps={true}
              onLoad={onMapLoad}
              styleDiffing={true}
              onClick={onMapClick}
              onMapIdle={onMapIdle}
              onDragEnd={handleDragEnd}
              onZoomEnd={() => {
                const currentZoom = mapRef.current.getMap().getZoom();
                setMapZoom(currentZoom);
                if (currentZoom < 11 && viewLarge ) {
                  setViewLarge(false);
                  dispatch(removeLayer({ layerName: 'large_projects' }));
                } else if (currentZoom >= 11 && !viewLarge && largeProjects) {
                  setViewLarge(true);
                  dispatch(appendNewLayer({ layerName: 'large_projects' }));
                }
              }}
              // onMouseMove={onMouseMove}
              // onMouseLeave={onMouseLeave}
              // onMouseMove={onHover}
              // interactiveLayerIds={['nps']}
              initialViewState={viewport}
              onViewportChange={setViewport}
              style={{
                width: `calc(100vw - ${mapWidth}px)`,
                borderRadius: "20px",
                paddingRight: "5px",
                marginLeft: projects?.leftSidebarOpen ? "420px" : "80px",
                height: "calc(100vh - 106px)",
              }}
              minZoom={7}
              maxZoom={18}
              maxBounds={[
                [-10.76418, 49.528423],
                [1.9134116, 61.331151],
              ]}
              // mapStyle={mapStyle}
              mapStyle={showSat ? mapStyle : map_style}
              transformRequest={(url, resourceType) => {
                if (url.includes("voltquant-images"))
                  return {
                    url: url,
                  };
                if (url.includes("mapbox"))
                  return {
                    url: url,
                  };
                if (url.includes("vqinframap"))
                  return {
                    url: url,
                  };
                if (url.includes("vqprivatelandmap"))
                  return {
                    url: url,
                  };
                if (url.includes("vqnpsmap"))
                  return {
                    url: url,
                  };
                if (url.includes("/tegola/"))
                  return {
                    url: url,
                  };
                if (url.includes("/geoserver/"))
                  return {
                    url: url,
                  };
                if (url.includes("//mt1.google.com/"))
                  return {
                    url: url,
                  };
                if (!/[?&]key=/.test(url)) {
                  url += "?key=" + myAPIKey;
                  return {
                    url: url + "&srs=3857",
                  };
                }
                if (resourceType === 'Tile' && url.startsWith('https://api.os.uk/maps/raster/v1/zxy/Light_3857')) {
                  const match = url.match(/Light_3857\/(\d+)\/(\d+)\/(\d+)\.png/);
                  if (match) {
                    const z = match[1];
                    const x = match[2];
                    const y = match[3];
                    const proxyUrl = `${baseURL}/map/map-tiles/${z}/${x}/${y}.png`;
                    return { url: proxyUrl };
                  }
                }
              }}
            >
              <LayerToggleControl
                onMapLoad={onMapLoad}
                visible={visible}
                setVisible={setVisible}
              />
              <NavigationControl position={"top-left"} />
              <BasemapControl setShowSat={setShowSat} showSat={showSat} />
              {/* <LargeProjetLayerControl
                viewLarge={viewLarge}
                setViewLarge={setViewLarge}
              /> */}
              <LargeProjetLayerControl
                mapZoom={mapZoom}
                viewLarge={viewLarge}
                setViewLarge={setViewLarge}
                setLargeProjects={setLargeProjects}
              />
              <ReinforcementControl
                setShowHeatmap={setShowHeatmap}
                showHeatmap={showHeatmap}
                setShowCompleted={setShowCompleted}
                showCompleted={showCompleted}
                showProgressing={showProgressing}
                setShowProgressing={setShowProgressing}
              />
              <NpsControl key={JSON.stringify(mapZoom)} mapZoom={mapZoom} setShowNPS={setShowNPS} showNPS={showNPS} setShowCOD={setShowCOD} showCOD={showCOD} showPrivate={showPrivate} setShowPrivate={setShowPrivate} />
              {/* {geojsonData && (
                <Source
                  id="wimd"
                  type="geojson"
                  data={geojsonData}
                >
                  <Layer {...wimdLayer} />
                </Source>
              )} */}
              <DrawControl
                position="top-left"
                ref={drawControlRef}
                onCreate={(e) => onUpdate(e)}
                onUpdate={(e) => onUpdate(e)}
                onDelete={onDelete}
              />
              {/* {viewLarge && (
                <Source
                  id={Large_Project_Layer.id}
                  type="vector"
                  tiles={[`https://backend.voltquant.uk/tegola/maps/${Large_Project_Layer.id}/{z}/{x}/{y}.pbf?`]}
                >
                  <Layer {...Large_Project_Layer} />
                </Source>
              )} */}
              {planningLPA && projects?.lpSidebarOpen && (
              <Source
                id="LPA"
                type="geojson"
                data={LPAPlanningData}
              >
                <Layer {...Lpa_Layer} />
                <Layer {...Lpa_Line} />
              </Source>
            )}
              {showCompleted && (
                <Source
                  id="completed_reinforcement"
                  type="geojson"
                  data={rein_completed}
                >
                  <Layer {...Reinforcement_Completed_Layer} />
                </Source>
              )}
              {showProgressing && (
                <Source
                  id="progressing_reinforcement"
                  type="geojson"
                  data={rein_inprogress}
                >
                  <Layer {...Reinforcement_Progressing_Layer} />
                </Source>
              )}
              {showHeatmap && (
                <Source
                  id="heatmap_reinforcement"
                  type="geojson"
                  data={heatmap_data}
                >
                  <Layer {...Heatmap_Layer} />
                </Source>
              )}
              {!showSat ?
                <Source
                  id="3d-buildings"
                  type="vector"
                  url="https://api.os.uk/maps/vector/v1/vts"
                >
                  <Layer {..._3dLayer} />
                </Source> : ''}
              {searchedPolygon || drawnLine ? <AreaCalculator /> : ''}
              {searchedParcel?.data?.features?.length > 0 && (
                <Source
                  id="Searched_Parcel"
                  type="geojson"
                  data={searchedParcel?.data}
                >
                  <Layer {...Searched_Parcel_Fill} />
                  <Layer {...Searched_Parcel_Line} />
                </Source>
              )}
              {showCOD && (
                <Source
                  id="cod"
                  type="vector"
                  tiles={["https://vqspatial.voltquant.uk/maps/vqnpsmap/{z}/{x}/{y}.pbf"]}
                >
                  <Layer {...ocodLayer} />
                  <Layer {...ccodLayer} />
                </Source>
              )}
              {showPrivate && (
                <Source
                  id="privateLand"
                  type="vector"
                  tiles={["https://vqspatial.voltquant.uk/maps/vqprivatelandmap/{z}/{x}/{y}.pbf"]}
                >
                  <Layer {...privateLandLayer} />
                </Source>
              )}
              {showNPS && (
                <Source
                  id="nps"
                  type="vector"
                  tiles={["https://vqspatial.voltquant.uk/maps/vqnpsmap/{z}/{x}/{y}.pbf"]}
                >
                  <Layer {...npsFillLayer} />
                  {/* <Layer {...npsLineLayer} /> */}
                </Source>
              )}
              {map_layers.map((lyr, key) => {
                if (activeLayers.includes(lyr.id)) {
                  let beforeLayerId = null;
                  const index = activeLayers.indexOf(lyr.id);
                  if (index !== activeLayers.length - 1 && mapRef.current) {
                    const map = mapRef.current.getMap();
                    // if (activeLayers.includes("National_Charge_Point_Register")){
                    if (!map.hasImage("planning_image")) {
                      map.loadImage(
                        "/icons/doc-icon.png",
                        (error, image) => {
                          if (error) throw error;
                          map.addImage("planning_image", image);
                        }
                      );
                    }
                    if (map.hasImage("ncpr_image") === false) {
                      map.loadImage(
                        '/icons/ev-Icon-filled.png',
                        (error, image) => {
                          if (error) throw error;
                          map.addImage('ncpr_image', image);
                        }
                      );
                    }
                    // }
                    if (map.getLayer(activeLayers[index + 1])) {
                      beforeLayerId = activeLayers[index + 1];
                    }
                  }
                  if (showSat === true) {
                    if (beforeLayerId !== null) {
                      return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={beforeLayerId} key={key} /></Source>;
                    } else {
                      return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} key={key} /></Source>;
                    }
                  } else {
                    const map = mapRef?.current?.getMap();
                    if (beforeLayerId !== null) {
                      return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={beforeLayerId} key={key} /></Source>;
                    } else if (map?.getLayer("OS/Railway_stations/Light Rapid Transit Station And London Underground Station")) {
                      return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={"OS/Railway_stations/Light Rapid Transit Station"} key={key} /></Source>;
                    } else {
                      return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} key={key} /></Source>;
                    }
                  }
                }
              })}
              {/* {activeLayers?.length > 0 && (
                <Source
                  id="wms"
                  type="raster"
                  tileSize={256}
                  tiles={[
                    `https://backend.voltquant.uk/geoserver/volt-quant/wms?service=WMS&version=1.1.1&request=GetMap&layers=${activeLayers?.join(
                      ","
                    )}&styles=&format=image/png&transparent=true&height=256&width=256&srs=EPSG:3857&bbox={bbox-epsg-3857}`,
                  ]}
                >
                  <Layer {...wmsLayer} />
                </Source>
              )} */}
              {visible && (
                <Source
                  id="vqinframap"
                  key={JSON.stringify(showSat)+'1'}
                  type="vector"
                  minZoom={2}
                  maxZoom={18}
                  // url={vqStyleUrl}
                  sprite="/vqSprites"
                  glyphs="/vq-hackathon/fonts/{fontstack}/{range}.pbf"
                  tiles= {["https://vqspatial.voltquant.uk/maps/vqinframap/{z}/{x}/{y}.pbf"]}
                >
                  {vQInfralayers.map((layer) => {
                    if (showSat === true) {
                      return <Layer {...layer} />;
                    } else {
                      const map = mapRef?.current?.getMap();
                      if (map?.getLayer("OS/Railway_stations/Light Rapid Transit Station And London Underground Station")) {
                        return <Layer {...layer} beforeId={"OS/Railway_stations/Light Rapid Transit Station And London Underground Station"} />;
                      } else {
                        return <Layer {...layer} />;
                      }
                    }
                  })}
                </Source>
              )}
              {activeLayers?.length > 0 || searchedParcel?.data?.features?.length > 0 || osmData?.length > 0 || visible || showNPS || showCOD || showHeatmap|| showCompleted || showProgressing || viewLarge ? <MapEvents showNPS={showNPS} showCOD={showCOD} showHeatmap={showHeatmap} showPrivate={showPrivate} showCompleted={showCompleted} showProgressing={showProgressing} viewLarge={viewLarge} searchedParcel={searchedParcel} /> : ''}
              {!selectedSubstation && isPlanningStations && (
                <>
                  {substations?.map((substation, index) => {
                    // console.log("SUB Data: ", substation)
                    return (
                      <Marker
                        key={index}
                        longitude={Number(substation?.location_x)}
                        latitude={Number(substation?.location_y)}
                      >
                        <div
                          className={"planningMarker"}
                          onClick={(e) => handleClickMarker(e, substation)}
                        ></div>
                      </Marker>
                    );
                  })}
                </>
              )}
              {!selectedSubstation && !isPlanningStations && (
                <>
                  {substations?.map((substation, index) => {
                    const isSelectedSubstation =
                      selectedSubstation?.SubstationID ===
                      substation?.SubstationID;
                    const isRedIcon =
                      substation?.generationExtraCapacity < 0 ||
                      substation?.demandExtraCapacity < 0;
                    return (
                      <Marker
                        key={index}
                        longitude={Number(substation?.Longitute)}
                        latitude={Number(substation?.Lattitude)}
                      >
                        <div
                          className={
                            isSelectedSubstation
                              ? isRedIcon
                                ? "redMarker"
                                : "greenMarker"
                              : isRedIcon
                                ? "redMarker"
                                : "greenMarker"
                          }
                          onClick={(e) => handleClickMarker(e, substation)}
                        ></div>
                      </Marker>
                    );
                  })}
                </>
              )}
              {allowSecSubs && selectedSubstation && secondarySubstations?.length > 0 && filters?.minCapacity < 12 && (
                <>
                  {secondarySubstations?.map((substation, index) => {
                    return (
                      <Marker
                        key={index}
                        longitude={Number(substation[0])}
                        latitude={Number(substation[1])}
                      >
                        <div
                          className="greenCircle"
                          onClick={(e) => handleSecondaryMarkerClick(e, substation)}
                        ></div>
                      </Marker>
                    );
                  })}
                </>
              )}
              {userLocation && (
                <Marker
                  longitude={userLocation.lng}
                  latitude={userLocation.lat}
                  // anchor="center"
                  offset={[0, -91 / 3.8]}
                >
                  <img
                    src={
                      filters?.assetTypeUrl ===
                        "/icons/ev_search.png"
                        ? evSearch
                        : filters?.assetTypeUrl ===
                          "/icons/battery_search.png"
                          ? bsSearch
                          : filters?.assetTypeUrl ===
                            "/icons/renewable_search.png"
                            ? rneSearch
                            : reSearch
                    }
                    alt="Search location"
                  />
                  {/* <img src={filters?.assetTypeUrl} alt="asset type" /> */}
                </Marker>
              )}
              {selectedSubstation && (
                <>
                  <Marker
                    longitude={selectedSubstation?.location_x ? selectedSubstation?.location_x : Number(selectedSubstation?.Longitute)}
                    latitude={selectedSubstation?.location_y ? selectedSubstation?.location_y : Number(selectedSubstation?.Lattitude)}
                    anchor="center"
                  >
                    <div
                      id="selectedSubstation"
                      className={
                        selectedSubstation?.generationExtraCapacity < 0 ||
                          selectedSubstation?.demandExtraCapacity < 0
                          ? "redMarker"
                          : "greenMarker"
                      }
                    ></div>
                  </Marker>
                </>
              )}
              {userLocation && searchedPolygon && search_by === 'Draw Polygon' && (
                <Source type="geojson" data={searchedPolygon}>
                  <Layer
                    id={`aapolygon`}
                    type="fill"
                    paint={{
                      'fill-color': `#b0f542`,
                      'fill-opacity': 0.6,
                    }}
                  />
                </Source>
              )}
              {userLocation && drawnLine && (
                <Source type="geojson" data={drawnLine}>
                  <Layer
                    id={`aaLine`}
                    type="line"
                    paint={{
                      "line-width": 4,
                      "line-color": "#008000",
                    }}
                  />
                </Source>
              )}
              {clippedDir && (
                <Source
                  id="clipped_route"
                  type="geojson"
                  data={{
                    type: 'Feature',
                    properties: {},
                    geometry: {
                      type: 'LineString',
                      coordinates: clippedDir?.map(point => [point[0], point[1]]),
                    }
                  }}
                >
                  <Layer
                    id="clipped_route_line"
                    type="line"
                    source="clipped_route"
                    layout={{
                      'line-join': 'round',
                      'line-cap': 'round',
                    }}
                    paint={{
                      'line-color': "#ff1744",
                      'line-width': 5,
                    }}
                  />
                </Source>
              )}
              {/* {userLocation && selectedSubstation && line && (
                <>
                  <Source type="geojson" data={line}>
                    <Layer

                      id="lineLayer"
                      type="line"
                      paint={{
                        "line-width": 4,
                        "line-color": "#008000",
                      }}
                    />
                  </Source>
                  <Marker
                    longitude={
                      (userLocation.lng +
                        Number(selectedSubstation.Longitute)) /
                      2
                    }
                    latitude={
                      (userLocation.lat +
                        Number(selectedSubstation.Lattitude)) /
                      2
                    }
                  // offset={[0, -91 / 2]}
                  >
                    <div id="userLocation" className="lineMarker">
                      {`${filters?.radiusUnit === "Miles" ? fixed(kilometersToMiles(selectedSubstation?.distance), 2) : fixed(selectedSubstation?.distance, 2)} ${filters?.radiusUnit === "Miles"
                        ? filters?.radiusUnit
                        : "km"
                        }`}
                    </div>
                  </Marker>
                </>
              )}
              {directions && (
                <>
                  <Source
                    id="route"
                    type="geojson"
                    data={{
                      type: 'Feature',
                      properties: {},
                      geometry: {
                        type: 'LineString',
                        coordinates: directions.map(point => [point.longitude, point.latitude]),
                      },
                    }}
                  >
                    <Layer
                      id="route"
                      type="line"
                      source="route"
                      layout={{
                        'line-join': 'round',
                        'line-cap': 'round',
                      }}
                      paint={{
                        'line-color': '#388e3c',
                        'line-width': 6,
                      }}
                    />
                  </Source>
                  <Marker
                    longitude={
                      dirMidWay.longitude
                    }
                    latitude={
                      dirMidWay.latitude
                    }
                  // offset={[0, -91 / 2]}
                  >
                    <div id="userLocation" className="lineMarker">
                      {`${filters?.radiusUnit === "Miles" ? fixed(kilometersToMiles(dirDist / 1000), 2) : fixed((dirDist / 1000), 2)} ${filters?.radiusUnit === "Miles"
                        ? filters?.radiusUnit
                        : "km"
                        }`}
                    </div>
                  </Marker>
                </>
              )}
              {shouldRenderRenewableLayers &&
                activeRenewableLayers?.length > 0 && (
                  <Source
                    id="wmsRenewable"
                    type="raster"
                    tileSize={256}
                    tiles={[
                      `https://backend.voltquant.uk/geoserver/volt-quant/wms?service=WMS&version=1.1.1&request=GetMap&layers=${activeRenewableLayers?.join(
                        ","
                      )}&styles=&format=image/png&transparent=true&height=256&width=256&srs=EPSG:3857&bbox={bbox-epsg-3857}`,
                    ]}
                  >
                    <Layer {...renewableLayer} />
                  </Source>
                )} */}
              {(shouldRenderRenewableLayers &&
                activeRenewableLayers?.length > 0) ? (
                map_layers.map((lyr, key) => {
                  if (activeRenewableLayers.includes(lyr.id)) {
                    let beforeLayerId = null;
                    const index = activeRenewableLayers.indexOf(lyr.id);
                    if (index !== activeRenewableLayers.length - 1 && mapRef.current) {
                      const map = mapRef.current.getMap();
                      if (map.getLayer(activeRenewableLayers[index + 1])) {
                        beforeLayerId = activeRenewableLayers[index + 1];
                      }
                    }
                    if (showSat === true) {
                      if (beforeLayerId !== null) {
                        return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={beforeLayerId} key={key} /></Source>;
                      } else {
                        return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} key={key} /></Source>;
                      }
                    } else {
                      const map = mapRef?.current?.getMap();
                      if (beforeLayerId !== null) {
                        return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={beforeLayerId} key={key} /></Source>;
                      } else if (map?.getLayer("OS/Railway_stations/Light Rapid Transit Station And London Underground Station")) {
                        return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} beforeId={"OS/Railway_stations/Light Rapid Transit Station"} key={key} /></Source>;
                      } else {
                        return <Source id={lyr.id} type="vector" tiles={[`https://backend.voltquant.uk/tegola/maps/${lyr.id}/{z}/{x}/{y}.pbf?`]}><Layer {...lyr} key={key} /></Source>;
                      }
                    }
                  }
                })
              ) : ('')}
              {
                osmData.length > 0 && osmData.map((item, i) => {
                  if (item.show) {
                    return (
                      <Source key={i} type="geojson" data={item.geojson}>
                        <Layer

                          filter={['==', '$type', 'LineString']}
                          id={item.name}
                          type="line"
                          paint={{
                            'line-color': `${item.color}`,
                            'line-width': 3,
                          }}
                        />
                        <Layer
                          id={`${item.name}point`}
                          type='circle'
                          paint={{
                            'circle-radius': 4,
                            'circle-stroke-width': 0.5,
                            'circle-color': `${item.color}`,
                            'circle-stroke-color': 'black'
                          }}

                          filter={['==', '$type', 'Point']}
                        />
                        <Layer
                          id={`${item.name}polygon`}
                          type="fill"
                          paint={{
                            'fill-color': `${item.color}`,
                            'fill-opacity': 0.6,
                          }}
                          filter={['==', '$type', 'Polygon']}

                        />
                      </Source>
                    )
                  }
                })
              }
            </Map>
          </>
        )}

        {projects?.promethousModal &&
          !projects?.leftSidebarOpen &&
          projects?.rightSidebarOpen && (
            <div
              style={{
                zIndex: 11,
                position: "absolute",
                bottom: "0px",
                // left: '103px',
                left: projects?.leftSidebarOpen ? "440px" : "100px",
                width: `calc(100vw - ${mapWidth - 5}px)`,
                // height: '200px',
                background: "white",
                // borderRadius: '20px',
                padding: "10px",
                boxSizing: "border-box",
                boxShadow: "rgba(0,0,0,0.2)",
              }}
            >
              <div className={styles.prometheusAIModal}>
                <div className={styles.headingContainer}>
                  <h1 className={styles.heading}>Choose an option</h1>
                  <OffOutlineClose onClick={handleClose} />
                </div>

                <div className={styles.buttonContainer}>
                  {buttonTextArray?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          buttonText === item
                            ? styles.buttonActivediv
                            : styles.buttondiv
                        }
                        onClick={() => {
                          setButtonText(item);
                          dispatch(setTransmission(item));
                          // setOpen(false);
                        }}
                      >
                        <RadioActive
                          border={buttonText === item ? "#0AED24" : "#E2E2E2"}
                          fillColor={buttonText === item ? "#0AED24" : "#fff"}
                        />
                        <div>
                          <h1>{item}</h1>
                          {/* <p>{item === "Private Wire Scheme" ? "Recommended" : ""}</p> */}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className={styles.analysisContainer}>
                  {analysisArray?.map((item, index) => {
                    return (
                      <div className={styles.analysis} key={index}>
                        <div className={styles.iconContainer}>
                          <item.type />
                          <PrometheuesMinus />
                        </div>

                        <p>{item.text}</p>

                        {item?.points?.map((data, index) => {
                          return (
                            <div className={styles.textContainer} key={index}>
                              <PrometheuesCircleCheck />
                              <h3>{data}</h3>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
      </div>
      <PolygonFeatureModal
        open={popupModal}
        setOpen={setPopupModal}
        data={npsData}
      />
      <PolygonPopup
        open={polygonPopup}
        setOpen={togglePolygonPopup}
        data={selectedPolygonData}
        topPosition={0}
        leftPosition={0}
      />
      <DeleteProjectModal
        open={deleteModal}
        setOpen={setDeleteModal}
        onClose={handleCancel}
        handleDetele={handleConfirm}
        title={`Delete existing ${currentMode === 'draw_line_string' ? 'line' : 'polygon'} drawing?`}
        text={`Currently only one ${currentMode === 'draw_line_string' ? 'line' : 'polygon'} can be drawn at a time, are you sure you want to delete the previous drawing and start a new one?`}
      />
    </>
  );
};

export default React.memo(MapArea);
