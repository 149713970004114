import React from "react";
import AppTextBox from "../../Common/AppTextBox/AppTextBox";
import AppButton from "../../Common/Button/AppButton";
import styles from "./styles.module.scss";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import PowerPlantLegend from "../../Map/PowerPlantLegend";
import { List, ListItemText, Typography } from "@mui/material";
import PowerLegend from "../../Map/PowerLegend";
import PowerLinesLegend from "../../Map/PowerLinesLegend";
import TelecomLegend from "../../Map/TelecomLegend";

const SymbolicIcons = () => {
  return (
    <div className={styles.assets_container}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Power Plants
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PowerPlantLegend />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {" "}
            Other Power
          </Typography>
   
        </AccordionSummary>
        <AccordionDetails>
          <PowerLegend />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Power Lines
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PowerLinesLegend />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Telecoms
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TelecomLegend />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SymbolicIcons;
