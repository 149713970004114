import axios from "axios";
import { ToastNotification } from "../Utils/ToastNotifications";

export const baseURL = "https://backend.voltquant.uk/api/v1" 
// export const baseURL = "https://dev.backend.voltquant.uk/api/v1"
// export const baseURL = "http://localhost:3000/api/v1"

export const instance = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const makeRequest = async (type, path, body, options = {}) => {
  body = {
    ...body,
  };
  const config = {
    timeout: 30000,
    ...options,
  };

  const res = instance[type](path, body, config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error, "error");
      if (error.code === 401) {
        ToastNotification("error", "Session expired. Please login again");
      } else if (error.code === "ECONNABORTED") {
        ToastNotification("error", "Request timed out");
      }
      return error;
    });
  return res;
};

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      const userData = JSON.parse(token);
      config.headers.Authorization = `Bearer ${userData?.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error?.response?.status === 401) {
      // window.location.reload(true);
      window.location.href = "/login";
      window.localStorage.clear();
    }
    const code = error?.response && error?.response?.status;
    return Promise.reject({
      code,
    });
  }
);
