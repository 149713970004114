import { createSlice } from "@reduxjs/toolkit";

const selectedSubstationSlice = createSlice({
  name: "selectedSubstation",
  initialState: {
    substation: null,
    allowSecSubs: false,
    secondarySubstations: [],
    route: {},
  },
  reducers: {
    setSelectedSubstation: (state, action) => {
      state.substation = action.payload;
    },
    setSecondarySubstations: (state, action) => {
      state.secondarySubstations = action.payload;
    },
    setAllowSecSubs: (state, action) => {
      state.allowSecSubs = action.payload;
    },
    setRoute: (state, action) => {
      state.route = action.payload;
    },
  },
});

export const { setSelectedSubstation, setSecondarySubstations, setAllowSecSubs, setRoute } = selectedSubstationSlice.actions;
export default selectedSubstationSlice.reducer;
