export const layers = [
  {
    id: "GreenBelt",
    title: "Green Belt",
    imageLink: "/layersCard/Green Belt.png",
    category: "ENGLAND",
    name: "Green_Belt",
    checked: false,
  },
  {
    id: "AncientWoodland",
    title: "Ancient Woodland",
    imageLink: "/layersCard/Ancient Woodland.png",
    category: "ENGLAND",
    name: "Ancient_Woodland",
    checked: false,
  },
  {
    id: "AreaOfOutstandingNaturalBeauty",
    title: "Area Of Outstanding Natural Beauty",
    imageLink: "/layersCard/Area Of Outstanding Natural Beauty.png",
    category: "ENGLAND",
    name: "Area_Of_Outstanding_Natural_Beauty",
    checked: false,
  },
  {
    id: "ConservationArea",
    title: "Conservation Area",
    imageLink: "/layersCard/Conservation Area.png",
    category: "ENGLAND",
    name: "Conservation_Area",
    checked: false,
  },
  {
    id: "NationalPark",
    title: "National Park",
    imageLink: "/layersCard/National Park.png",
    category: "ENGLAND",
    name: "National_Park",
    checked: false,
  },
  {
    id: "RamsarSite",
    title: "Ramsar Site",
    category: "ENGLAND",
    name: "Ramsar_Site",
    checked: false,
    imageLink: "/layersCard/Ramsar Site.png",
  },
  {
    id: "SpecialAreaofConservation",
    title: "Special Area of Conservation",
    category: "ENGLAND",
    name: "Special_Area_of_Conservation",
    checked: false,
    imageLink: "/layersCard/Special Area of Conservation.png",
  },
  {
    id: "SpecialProtectionArea",
    title: "Special Protection Area",
    category: "ENGLAND",
    name: "Special_Protection_Area",
    checked: false,
    imageLink: "/layersCard/Special Protection Area.png",
  },
  {
    id: "TreeZones",
    title: "Tree (Zones)",
    category: "ENGLAND",
    name: "Tree_Zones",
    checked: false,
    imageLink: "/layersCard/Tree (Zones).png",
  },
  {
    id: "TreePreservationZone",
    title: "Tree Preservation Zone",
    category: "ENGLAND",
    name: "Tree_Preservation_Zone",
    checked: false,
    imageLink: "/layersCard/Tree Preservation Zone.png",
  },
  {
    id: "HeritageAtRisk",
    title: "Heritage At Risk",
    imageLink: "/layersCard/Heritage At Risk.png",
    category: "ENGLAND",
    name: "Heritage_At_Risk",
    checked: false,
  },
  {
    id: "HeritageCoast",
    title: "Heritage Coast",
    imageLink: "/layersCard/Heritage Coast.png",
    category: "ENGLAND",
    name: "Heritage_Coast",
    checked: false,
  },
  {
    id: "HistoricParksAndGardens",
    title: "Historic Parks and Gardens",
    imageLink: "/layersCard/Historic Parks and Gardens.png",
    category: "ENGLAND",
    name: "Historic_Parks_and_Gardens",
    checked: false,
  },
  {
    id: "ArticleDirectionArea",
    title: "Article 4 Direction Area",
    imageLink: "/layersCard/Article 4 Direction Area.png",
    category: "ENGLAND",
    name: "Article_4_Direction_Area",
    checked: false,
  },
  {
    id: "BrownfieldLand",
    title: "Brownfield Land",
    imageLink: "/layersCard/Brownfield Land.png",
    category: "ENGLAND",
    name: "Brownfield_Land",
    checked: false,
  },
  {
    id: "BrownfieldSite",
    title: "Brownfield Site",
    imageLink: "/layersCard/Brownfield Site.png",
    category: "ENGLAND",
    name: "Brownfield_Site",
    checked: false,
  },
  {
    id: "BuildingPreservationNotice",
    title: "Building Preservation Notice",
    imageLink: "/layersCard/Building Preservation Notice.png",
    category: "ENGLAND",
    name: "Building_Preservation_Notice",
    checked: false,
  },
  {
    id: "CentralActivitiesZone",
    title: "Central Activities Zone",
    imageLink: "/layersCard/Central Activities Zone.png",
    category: "ENGLAND",
    name: "Central_Activities_Zone",
    checked: false,
  },
  {
    id: "CertificateOfImmunity",
    title: "Certificate Of Immunity",
    imageLink: "/layersCard/Certificate Of Immunity.png",
    category: "ENGLAND",
    name: "Certificate_Of_Immunity",
    checked: false,
  },
  {
    id: "CivilParish",
    title: "Civil Parish",
    imageLink: "/layersCard/Civil Parish.png",
    category: "ENGLAND",
    name: "Civil_Parish",
    checked: false,
  },
  {
    id: "DesignCodeArea",
    title: "Design Code Area",
    imageLink: "/layersCard/Design Code Area.png",
    category: "ENGLAND",
    name: "Design_Code_Area",
    checked: false,
  },
  {
    id: "ListedBuilding",
    title: "Listed Building",
    imageLink: "/layersCard/Listed Building.png",
    category: "ENGLAND",
    name: "Listed_Building",
    checked: false,
  },
  {
    id: "LocalAuthorityDistrict",
    title: "Local Authority District",
    imageLink: "/layersCard/Local Authority District.png",
    category: "ENGLAND",
    name: "Local_Authority_District",
    checked: false,
  },
  {
    id: "LocalPlanningAuthority",
    title: "Local Planning Authority",
    imageLink: "/layersCard/Local Planning Authority.png",
    category: "ENGLAND",
    name: "Local_Planning_Authority",
    checked: false,
  },
  {
    id: "LocallyListedBuilding",
    title: "Locally Listed Building",
    imageLink: "/layersCard/Locally Listed Building.png",
    category: "ENGLAND",
    name: "Locally_Listed_Building",
    checked: false,
  },
  {
    id: "ProtectedWreckSite",
    title: "Protected Wreck Site",
    imageLink: "/layersCard/Protected Wreck Site.png",
    category: "ENGLAND",
    name: "Protected_Wreck_Site",
    checked: false,
  },
  {
    id: "Region",
    title: "Region",
    category: "ENGLAND",
    name: "Region",
    checked: false,
    imageLink: "/layersCard/Region.png",
  },
  {
    id: "RegisteredBattlefields",
    title: "Registered Battlefields",
    category: "ENGLAND",
    name: "Registered_Battlefields",
    checked: false,
    imageLink: "/layersCard/Registered Battlefields.png",
  },
  {
    id: "RegisteredParks",
    title: "Registered Parks",
    category: "ENGLAND",
    name: "Registered_Parks",
    checked: false,
    imageLink: "/layersCard/Registered Parks.png",
  },
  {
    id: "ScheduledMonuments",
    title: "Scheduled Monuments",
    category: "ENGLAND",
    name: "Scheduled_Monuments",
    checked: false,
    imageLink: "/layersCard/Scheduled Monuments.png",
  },
  {
    id: "SiteOfSpecialScientificInterest",
    title: "Site Of Special Scientific Interest",
    category: "ENGLAND",
    name: "Site_Of_Special_Scientific_Interest",
    checked: false,
    imageLink: "/layersCard/Site Of Special Scientific Interest.png",
  },
  {
    id: "WorldHeritageSite",
    title: "World Heritage Site",
    category: "ENGLAND",
    name: "World_Heritage_Site",
    checked: false,
    imageLink: "/layersCard/World Heritage Site.png",
  },
  {
    id: "WorldHeritageSiteBufferZone",
    title: "World Heritage Site Buffer Zone",
    category: "ENGLAND",
    name: "World_Heritage_Site_Buffer_Zone",
    checked: false,
    imageLink: "/layersCard/World Heritage Site Buffer Zone.png",
  },
  {
    id: "SubstationsQueue",
    title: "Substations Queue",
    category: "NGED",
    name: "Substations_Queue",
    checked: false,
    imageLink: "/layersCard/clearview.png",
  },
  {
    id: "NationalChargePointRegister",
    title: "Existing EV Infrastructure",
    name: "National_Charge_Point_Register",
    checked: false,
    category: "EV",
    imageLink: "/layersCard/ncpr-new-image.png",
  },
  {
    id: "DataCentres",
    title: "Data Centres",
    name: "Data_Centres",
    checked: false,
    category: "DATA CENTERS",
    imageLink: "/layersCard/data_centers.png",
  },
  {
    id: "Exchange_Assets",
    title: "Exchanges",
    name: "Exchange_Assets",
    checked: false,
    category: "DATA CENTERS",
    imageLink: "/layersCard/exchange_assets.png",
  },
  {
    id: "Pop_Assets",
    title: "Point-of-Presence (PoP)",
    name: "Pop_Assets",
    checked: false,
    category: "DATA CENTERS",
    imageLink: "/layersCard/pop_assets.png",
  },
  {
    id: "FibreCable(NEOS)",
    title: "Fibre Cables (NEOS)",
    name: "Network_Cables",
    checked: false,
    category: "DATA CENTERS",
    imageLink: "/layersCard/network_cables.png",
  },
  {
    id: "FloodRiskZone",
    title: "Flood Risk Zones",
    imageLink: "/layersCard/Flood_Risk_Zones.png",
    category: "ENGLAND",
    name: "Flood_Risk_Zone",
    checked: false,
  },
  {
    id: "AgricultureLandClassification",
    title: "Agriculture Land Classification",
    name: "Agriculture_Land_Classification",
    checked: false,
    category: "ENGLAND",
    imageLink: "/layersCard/aggriculture-land-classification.png",
  },
  {
    id: "CanmoreMaritime",
    title: "Canmore Maritime (heritage)",
    name: "Canmore_Maritime_Heritage",
    checked: false,
    category: "SCOTLAND",
    imageLink: "/layersCard/hes-canmore-maritime.png",
  },
  {
    id: "CanmorePoints",
    title: "Canmore Points (heritage)",
    name: "Canmore_Points_Heritage",
    checked: false,
    category: "SCOTLAND",
    imageLink: "/layersCard/hes-canmore-points.png",
  },
  {
    id: "ConservationAreasScotland",
    title: "Conservation Areas (Scotland)",
    name: "Conservation_Areas_Scotland",
    checked: false,
    category: "SCOTLAND",
    imageLink: "/layersCard/hes-conservation-areas.png",
  },
  {
    id: "GardensAndDesignLandscapes",
    title: "Gardens and Design Landscapes",
    name: "Gardens_And_Design_Landscapes",
    checked: false,
    category: "SCOTLAND",
    imageLink: "/layersCard/hes-gardens-and-design-landscapes.png",
  },
  {
    id: "HistoricProtectedAreas",
    title: "Historic Protected Areas",
    name: "Historic_Protected_Areas",
    checked: false,
    category: "SCOTLAND",
    imageLink: "/layersCard/hes-historic-protected-areas.png",
  },
  {
    id: "ListedBuildingsScotland",
    title: "Listed Buildings (Scotland)",
    name: "Listed_Buildings_Scotland",
    checked: false,
    category: "SCOTLAND",
    imageLink: "/layersCard/hes-listed-buildings.png",
  },
  {
    id: "ListedBuildingsBoundariesScotland",
    title: "Listed Buildings Boundaries (Scotland)",
    name: "Listed_Buildings_Boundaries_Scotland",
    checked: false,
    category: "SCOTLAND",
    imageLink: "/layersCard/hes-listed-buildings-boundaries.png",
  },
  {
    id: "PropertiesInCare",
    title: "Properties in Care",
    name: "Properties_In_Care",
    checked: false,
    category: "SCOTLAND",
    imageLink: "/layersCard/hes-properties-in-care.png",
  },
  {
    id: "ScheduledMonumentsScotland",
    title: "Scheduled Monuments (Scotland)",
    name: "Scheduled_Monuments_Scotland",
    checked: false,
    category: "SCOTLAND",
    imageLink: "/layersCard/hes-scheduled-monuments.png",
  },
  {
    id: "WorldHeritageSitesScotland",
    title: "World Heritage Sites (Scotland)",
    name: "World_Heritage_Sites_Scotland",
    checked: false,
    category: "SCOTLAND",
    imageLink: "/layersCard/hes-world-heritage-sites.png",
  },
  {
    id: "AncientWoodlandsWales",
    title: "Ancient Woodlands (Wales)",
    name: "Ancient_Woodlands_Wales",
    checked: false,
    category: "WALES",
    imageLink: "/layersCard/dmw-ancient-woodland.png",
  },
  {
    id: "AreaOfNaturalOutstandingBeautyWales",
    title: "Area of Natural Outstanding Beauty (Wales)",
    name: "Area_Of_Natural_Outstanding_Beauty_Wales",
    checked: false,
    category: "WALES",
    imageLink: "/layersCard/dmw-aonb.png",
  },
  {
    id: "ConservationAreaWales",
    title: "Conservation Area (Wales)",
    name: "Conservation_Area_Wales",
    checked: false,
    category: "WALES",
    imageLink: "/layersCard/dmw-conservation-area.png",
  },
  {
    id: "FloodRiskFromRivers",
    title: "Flood Risk from Rivers",
    name: "Flood_Risk_From_Rivers",
    checked: false,
    category: "WALES",
    imageLink: "/layersCard/dmw-floodrisksfromrivers.png",
  },
  {
    id: "ListedBuildingsWales",
    title: "Listed Buildings (Wales)",
    name: "Listed_Buildings_Wales",
    checked: false,
    category: "WALES",
    imageLink: "/layersCard/dmw-listed-buildings.png",
  },
  {
    id: "PreAssessedAreasForWindEnergy",
    title: "Pre-assessed Areas for Wind Energy",
    name: "Pre_Assessed_Areas_For_Wind_Energy",
    checked: false,
    category: "WALES",
    imageLink: "/layersCard/dmw-pre-assessed-areas-for-wind-energy.png",
  },
  {
    id: "RamsarWales",
    title: "Ramsar (Wales)",
    name: "Ramsar_Wales",
    checked: false,
    category: "WALES",
    imageLink: "/layersCard/dmw-ramsar.png",
  },
  {
    id: "ScheduledMonumentsWales",
    title: "Scheduled Monuments (Wales)",
    name: "Scheduled_Monuments_Wales",
    checked: false,
    category: "WALES",
    imageLink: "/layersCard/dmw-scheduled-monuments.png",
  },
  {
    id: "FloodRiskFromSea",
    title: "Flood Risk from Sea",
    name: "Flood_Risk_From_Sea",
    checked: false,
    category: "WALES",
    imageLink: "/layersCard/dmw-seafloodriskfromsea.png",
  },
  {
    id: "SiteOfSpecialScientificInterestWales",
    title: "Site of Special Scientific Interest (Wales)",
    name: "Site_Of_Special_Scientific_Interest_Wales",
    checked: false,
    category: "WALES",
    imageLink: "/layersCard/dmw-sssi.png",
  },
  {
    id: "WorldHeritageSitesWales",
    title: "World Heritage Sites (Wales)",
    name: "World_Heritage_Sites_Wales",
    checked: false,
    category: "WALES",
    imageLink: "/layersCard/dmw-world-heritage-sites.png",
  },
  {
    id: "NationalGridCables",
    title: "National Grid Cables",
    name: "National_Grid_Cables",
    checked: false,
    category: "NATIONAL GRID",
    imageLink: "/layersCard/national-grid-cables.png",
  },
  {
    id: "NationalGridOverheadLines",
    title: "National Grid Overhead Lines",
    name: "National_Grid_Overhead_Lines",
    checked: false,
    category: "NATIONAL GRID",
    imageLink: "/layersCard/national-grid-overheadlines.png",
  },
  {
    id: "NationalGridSubstations",
    title: "National Grid Substations",
    name: "National_Grid_Substations",
    checked: false,
    category: "NATIONAL GRID",
    imageLink: "/layersCard/national-grid-substations.png",
  },
  {
    id: "NationalGridTowers",
    title: "National Grid Towers",
    name: "National_Grid_Towers",
    checked: false,
    category: "NATIONAL GRID",
    imageLink: "/layersCard/national-grid-towers.png",
  },
  {
    id: "NationalGasPipe",
    title: "National Gas Pipe",
    name: "National_Gas_Pipe",
    checked: false,
    category: "NATIONAL GAS",
    imageLink: "/layersCard/national-gas-pipe.png",
  },
  {
    id: "NationalGasSite",
    title: "National Gas Site",
    name: "National_Gas_Site",
    checked: false,
    category: "NATIONAL GAS",
    imageLink: "/layersCard/national-gas-site.png",
  },
  {
    id: "UKPN11kVHVOverheadLines",
    title: "UKPN 11kV HV Overhead Lines",
    name: "UKPN_11kV_HV_Overhead_Lines",
    checked: false,
    category: "UKPN",
    imageLink: "/layersCard/ukpn-11kv-hv-overhead-new.png",
  },
  {
    id: "UKPN132kVOverheadLines",
    title: "UKPN 132kV Overhead Lines",
    name: "UKPN_132kV_Overhead_Lines",
    checked: false,
    category: "UKPN",
    imageLink: "/layersCard/ukpn-132kv-overhead-lines-new.png",
  },
  {
    id: "UKPN33kVOverheadLines",
    title: "UKPN 33kV Overhead Lines",
    name: "UKPN_33kV_Overhead_Lines",
    checked: false,
    category: "UKPN",
    imageLink: "/layersCard/ukpn-33kv-overhead-lines-new.png",
  },
  {
    id: "UKPN66kVOverheadLines",
    title: "UKPN 66kV Overhead Lines",
    name: "UKPN_66kV_Overhead_Lines",
    checked: false,
    category: "UKPN",
    imageLink: "/layersCard/ukpn-66kv-overhead-lines-shapefile-new.png",
  },
  {
    id: "UKPNLicenseAreaGridSupplyPoints",
    title: "UKPN License Area Grid Supply Points",
    name: "UKPN_License_Area_Grid_Supply_Points",
    checked: false,
    category: "UKPN",
    imageLink: "/layersCard/ukpn-licence-are-grid-supply-point-areas.png",
  },
  {
    id: "UKPNLowVoltageOverheadLines",
    title: "UKPN Low Voltage Overhead Lines",
    name: "UKPN_Low_Voltage_Overhead_Lines",
    checked: false,
    category: "UKPN",
    imageLink: "/layersCard/ukpn-low-voltasge.png",
  },
  {
    id: "UKPNPrimarySubstationDistributionAreas",
    title: "UKPN Primary Substation Distribution Areas",
    name: "UKPN_Primary_Substation_Distribution_Areas",
    checked: false,
    category: "UKPN",
    imageLink: "/layersCard/ukpn-primary-substation-distribution-areas.png",
  },
  {
    id: "UKPNSecondarySites",
    title: "UKPN Secondary Sites",
    name: "UKPN_Secondary_Sites",
    checked: false,
    category: "UKPN",
    imageLink: "/layersCard/ukpn-secondary-sites.png",
  },
  {
    id: "SSEN11kVCables",
    title: "SSEN 11kV Cables",
    name: "SSEN_11kV_Cables",
    checked: false,
    category: "SSEN",
    imageLink: "/layersCard/ssen-11kv-cables.png",
  },
  {
    id: "SSEN230400VCables",
    title: "SSEN 230/400V Cables",
    name: "SSEN_230_400V_Cables",
    checked: false,
    category: "SSEN",
    imageLink: "/layersCard/ssen-230to400v-cables-new.png",
  },
  {
    id: "SSEN33kVCables",
    title: "SSEN 33kV Cables",
    name: "SSEN_33kV_Cables",
    checked: false,
    category: "SSEN",
    imageLink: "/layersCard/ssen-33kv-cables-new.png",
  },
  {
    id: "NPG132kVOverhead",
    title: "NPG 132kV Overhead",
    name: "NPG_132kV_Overhead",
    checked: false,
    category: "NPG",
    imageLink: "/layersCard/npg-132kv-overhead-new.png",
  },
  {
    id: "NPG132kVUnderground",
    title: "NPG 132kV Underground",
    name: "NPG_132kV_Underground",
    checked: false,
    category: "NPG",
    imageLink: "/layersCard/npg-132kv-underground-new.png",
  },
  {
    id: "NPG33kVOverhead",
    title: "NPG 33kV Overhead",
    name: "NPG_33kV_Overhead",
    checked: false,
    category: "NPG",
    imageLink: "/layersCard/npg-33kv-overhead-new.png",
  },
  {
    id: "NPG33kVUnderground",
    title: "NPG 33kV Underground",
    name: "NPG_33kV_Underground",
    checked: false,
    category: "NPG",
    imageLink: "/layersCard/npg-33kv-underground-new.png",
  },
  {
    id: "NPG66kVOverhead",
    title: "NPG 66kV Overhead",
    name: "NPG_66kV_Overhead",
    checked: false,
    category: "NPG",
    imageLink: "/layersCard/npg-66kv-overground-new.png",
  },
  {
    id: "NPG66kVUnderground",
    title: "NPG 66kV Underground",
    name: "NPG_66kV_Underground",
    checked: false,
    category: "NPG",
    imageLink: "/layersCard/npg-66kv-underground-new.png",
  },
  {
    id: "NGED11kVOverheadLines",
    title: "NGED 11kV Overhead Lines",
    name: "NGED_11kV_Overhead_Lines",
    checked: false,
    category: "NGED",
    imageLink: "/layersCard/nged-11kv-overheadlines-new.png",
  },
  {
    id: "NGED11kVUnderground",
    title: "NGED 11kV Underground",
    name: "NGED_11kV_Underground",
    checked: false,
    category: "NGED",
    imageLink: "/layersCard/nged-11kv-underground-new.png",
  },
  {
    id: "NGED132kVOverheadLines",
    title: "NGED 132kV Overhead Lines",
    name: "NGED_132kV_Overhead_Lines",
    checked: false,
    category: "NGED",
    imageLink: "/layersCard/nged-132kv-overheadlines-new.png",
  },
  {
    id: "NGED132kVUnderground",
    title: "NGED 132kV Underground",
    name: "NGED_132kV_Underground",
    checked: false,
    category: "NGED",
    imageLink: "/layersCard/nged-132kv-undergroundlines-new.png",
  },
  {
    id: "NGED33kVOverheadLines",
    title: "NGED 33kV Overhead Lines",
    name: "NGED_33kV_Overhead_Lines",
    checked: false,
    category: "NGED",
    imageLink: "/layersCard/nged-33kv-overheadlines-new.png",
  },
  {
    id: "NGED33kVUnderground",
    title: "NGED 33kV Underground",
    name: "NGED_33kV_Underground",
    checked: false,
    category: "NGED",
    imageLink: "/layersCard/nged-33kv-underground-new.png",
  },
  {
    id: "NGED66kVOverhead",
    title: "NGED 66kV Overhead",
    name: "NGED_66kV_Overhead",
    checked: false,
    category: "NGED",
    imageLink: "/layersCard/nged-66kv-overhead-new.png",
  },
  {
    id: "NGED66kVUndergroundNew",
    title: "NGED 66kV Underground",
    name: "NGED_66kV_Underground_New",
    checked: false,
    category: "NGED",
    imageLink: "/layersCard/nged-66kv-underground-new.png",
  },
  {
    id: "ENWL11kVOverhead",
    title: "ENWL 11kV Overhead",
    name: "ENWL_11kV_Overhead",
    checked: false,
    category: "ENWL",
    imageLink: "/layersCard/enwl-11kv-overhead.png",
  },
  {
    id: "ENWL11kVUnderground",
    title: "ENWL 11kV Underground",
    name: "ENWL_11kV_Underground",
    checked: false,
    category: "ENWL",
    imageLink: "/layersCard/enwl-11kv-underground.png",
  },
  {
    id: "ENWL66kVConductorHeadroom",
    title: "ENWL 6.6kV Conductor Headroom",
    name: "ENWL_6kV_Headroom",
    checked: false,
    category: "ENWL",
    imageLink: "/layersCard/enwl-6point6kv-conductor-headroom.png",
  },
  {
    id: "ENWLCountyPolygons",
    title: "ENWL County Polygons",
    name: "ENWL_County_Polygons",
    checked: false,
    category: "ENWL",
    imageLink: "/layersCard/enwl-county-polygons.png",
  },
  {
    id: "ENWLDistributionSubstationHeadroom",
    title: "ENWL Distribution Substation Headroom",
    name: "ENWL_Distribution_Substation_Headroom",
    checked: false,
    category: "ENWL",
    imageLink: "/layersCard/enwl-distribution-substation-headroom.png",
  },
  {
    id: "ENWLGeneralBoundaries",
    title: "ENWL General Boundaries",
    name: "ENWL_General_Boundaries",
    checked: false,
    category: "ENWL",
    imageLink: "/layersCard/enwl-general-boundaries.png",
  },
  {
    id: "road_speed",
    title: "Road Speed",
    name: "road_speed",
    checked: false,
    imageLink: "/layersCard/road-traffic.png",
  },
  {
    id: "SP_Manweb_Grid_Polygons",
    title: "SP Manweb Grid Polygons",
    name: "SP_Manweb_Grid_Polygons",
    checked: false,
    category: "SPEN",
    imageLink: "/layersCard/sp_m_g.png",
  },
  {
    id: "SP_Manweb_Primary_Polygons",
    title: "SP Manweb Primary Polygons",
    name: "SP_Manweb_Primary_Polygons",
    checked: false,
    category: "SPEN",
    imageLink: "/layersCard/sp_m_p.png",
  },
  {
    id: "SP_Distribution_Primary_Polygons",
    title: "SP Distribution Primary Polygons",
    name: "SP_Distribution_Primary_Polygons",
    checked: false,
    category: "SPEN",
    imageLink: "/layersCard/sp_d_p.png",
  },
  {
    id: "HabitatInventoryEngland",
    title: "Habitat Inventory England",
    imageLink: "/layersCard/habitat.png",
    category: "ENGLAND",
    name: "Habitat_Inventory_England",
    checked: false,
  },
  {
    id: "HabitatInventoryScotland",
    title: "Habitat Inventory Scotland",
    imageLink: "/layersCard/habitat.png",
    category: "SCOTLAND",
    name: "Habitat_Inventory_Scotland",
    checked: false,
  },
  {
    id: "HabitatInventoryWales",
    title: "Habitat Inventory Wales",
    imageLink: "/layersCard/habitat.png",
    category: "WALES",
    name: "Habitat_Inventory_Wales",
    checked: false,
  },
  {
    id: "NGEDSecondarySites",
    title: "NGED Secondary Sites",
    name: "NGED_Secondary_Sites",
    checked: false,
    category: "NGED",
    imageLink: "/layersCard/nged_sec_subs.png",
  },
  {
    id: "ENWLSecondarySites",
    title: "ENWL Secondary Sites",
    name: "ENWL_Secondary_Sites",
    checked: false,
    category: "ENWL",
    imageLink: "/layersCard/enwl_sec_subs.png",
  },
  {
    id: "NPGSecondarySites",
    title: "NPG Secondary Sites",
    name: "NPG_Secondary_Sites",
    checked: false,
    category: "NPG",
    imageLink: "/layersCard/npg_sec_subs.png",
  },
  {
    id: "NPGLowVoltageFeeders",
    title: "NPG Low Voltage Feeders",
    name: "NPG_Low_Voltage_Feeders",
    checked: false,
    category: "NPG",
    imageLink: "/layersCard/npg_lv_oh_feeders.png",
  },
  {
    id: "NPG33kVFeeders",
    title: "NPG 33kV Feeders",
    name: "NPG_33kV_Feeders",
    checked: false,
    category: "NPG",
    imageLink: "/layersCard/npg_33kv_feeders.png",
  },
  {
    id: "NPG66kVFeeders",
    title: "NPG 66kV Feeders",
    name: "NPG_66kV_Feeders",
    checked: false,
    category: "NPG",
    imageLink: "/layersCard/npg_66kv_feeders.png",
  },
  {
    id: "NPG132kVFeeders",
    title: "NPG 132kV Feeders",
    name: "NPG_132kV_Feeders",
    checked: false,
    category: "NPG",
    imageLink: "/layersCard/npg_132kv_feeders.png",
  },
  {
    id: "NGN_Tees_Mains_Pipelines",
    title: "NGN Tees - Mains and Pipelines",
    name: "NGN_Tees_Mains_Pipelines",
    checked:false,
    category: "NGN",
    imageLink:
      "/layersCard/ngn_t_m_p.png",
  },
  {
    id: "NGN_Central_Mains_Pipelines",
    title: "NGN Central - Mains and Pipelines",
    name: "NGN_Central_Mains_Pipelines",
    checked:false,
    category: "NGN",
    imageLink:
      "/layersCard/ngn_c_m_p.png",
  },
  {
    id: "NGN_West_Yorkshire_Mains_Pipelines",
    title: "NGN  West Yorkshire - Mains and Pipelines",
    name: "NGN_West_Yorkshire_Mains_Pipelines",
    checked:false,
    category: "NGN",
    imageLink:
      "/layersCard/ngn_w_y_m_p.png",
  },
  {
    id: "NGN_The_North_Mains_Pipelines",
    title: "NGN The North - Mains and Pipelines",
    name: "NGN_The_North_Mains_Pipelines",
    checked:false,
    category: "NGN",
    imageLink:
      "/layersCard/ngn_n_m_p.png",
  },
  {
    id: "NGN_The_Ridings_Mains_Pipelines",
    title: "NGN The Ridings - Mains and Pipelines",
    name: "NGN_The_Ridings_Mains_Pipelines",
    checked:false,
    category: "NGN",
    imageLink:
      "/layersCard/ngn_r_m_p.png",
  },
  {
    id: "SP_Manweb_Grid_Polygons",
    title: "SP Manweb Grid Polygons",
    name: "SP_Manweb_Grid_Polygons",
    checked:false,
    category: "SPEN",
    imageLink:
      "/layersCard/sp_m_g.png",
  },
  {
    id: "SP_Manweb_Primary_Polygons",
    title: "SP Manweb Primary Polygons",
    name: "SP_Manweb_Primary_Polygons",
    checked:false,
    category: "SPEN",
    imageLink:
      "/layersCard/sp_m_p.png",
  },
  {
    id: "SP_Distribution_Primary_Polygons",
    title: "SP Distribution Primary Polygons",
    name: "SP_Distribution_Primary_Polygons",
    checked:false,
    category: "SPEN",
    imageLink:
      "/layersCard/sp_d_p.png",
  },
  {
    id: "HabitatInventoryEngland",
    title: "Habitat Inventory England",
    imageLink:
      "/layersCard/habitat.png",
    category: "ENGLAND",
    name: "Habitat_Inventory_England",
    checked:false,
  },
  {
    id: "HabitatInventoryScotland",
    title: "Habitat Inventory Scotland",
    imageLink:
      "/layersCard/habitat.png",
    category: "SCOTLAND",
    name: "Habitat_Inventory_Scotland",
    checked:false,
  },
  {
    id: "HabitatInventoryWales",
    title: "Habitat Inventory Wales",
    imageLink:
      "/layersCard/habitat.png",
    category: "WALES",
    name: "Habitat_Inventory_Wales",
    checked:false,
  }
];
