import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MapWrapper from "../Components/Wrapper/MapWrapper";
import ProjectWrapper from "../Components/Wrapper/ProjectWrapper";
import MapArea from "../Components/Map";
import Projects from "../Pages/Projects";
import SharedProjects from "../Pages/SharedProjects";
import DeletedProjects from "../Pages/DeletedProjects";

// import LoginRedirect from "./../Pages/Auth/LoginRedirect";
// import StripeRedirect from "../Pages/Auth/StripeRedirect";
import SignupForm from "../Pages/Auth/SighUpSignIn/SignupForm";
import LoginForm from "../Pages/Auth/SighUpSignIn/LoginForm";
import ResetPassword from "../Pages/Auth/SighUpSignIn/ResetPassword";
import ProjectDetail from "../Pages/ProjectDetail";
import ExternalProjectDetail from "../Pages/ExternalProjectDetail";
import SharedCollection from "../Pages/SharedCollection";
import SubmitRequest from "../Pages/Auth/SubmitRequest";
// import Collaboration from "../Pages/Collaboration";
import {
  setIsLoading,
  setUserDetail,
  setUserProfile,
} from "../Components/Redux/reducers/usersSlice";
import { getUserProfile } from "../Components/Api/API";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
// import Chat from "../Pages/Chat";
// import UploadFile from "../Pages/Upload";
import Gleap from "gleap";
import BulkImportProject from "../Pages/BulkImportProject";

// import Infra3Home from "../Pages/Shell/Infra3/Home";
// import Infra3Thread from "../Pages/Shell/Infra3/Thread";
// import Infra3ThreadList from "../Pages/Shell/Infra3/ThreadList";

// import Infra3TenderHome from "../Pages/Shell/Infra3Tender/Home";
// import Infra3TenderThread from "../Pages/Shell/Infra3Tender/Thread";
// import Infra3TenderThreadList from "../Pages/Shell/Infra3Tender/ThreadList";
// import Infra3TenderTextEditor from "../Pages/Shell/Infra3Tender/TextEditor";
// import Chat from "../Pages/Chat";
// import UploadFile from "../Pages/Upload"

// import Infra3Home from "../Pages/Shell/Infra3/Home"
// import Infra3Thread from "../Pages/Shell/Infra3/Thread"
// import Infra3ThreadList from "../Pages/Shell/Infra3/ThreadList"

// import Infra3TenderHome from "../Pages/Shell/Infra3Tender/Home"
// import Infra3TenderThread from "../Pages/Shell/Infra3Tender/Thread"
// import Infra3TenderThreadList from "../Pages/Shell/Infra3Tender/ThreadList"
// import Infra3TenderTextEditor from "../Pages/Shell/Infra3Tender/TextEditor"

const AppRoutes = () => {
  const dispatch = useDispatch();
  const { userDetail, isLoading } = useSelector((state) => state.users);
  const isAuthenticated = userDetail?.token?.length > 0;
  const isPaidUser =
    userDetail?.paid_user?.subscriptions?.data?.length > 0 &&
    (userDetail?.paid_user?.subscriptions?.data[0]?.status === "trialing" ||
      userDetail?.paid_user?.subscriptions?.data[0]?.status === "active");

  if (isAuthenticated) {
    // Please make sure to call this method only once!
    Gleap.initialize("J6hJVZzYbjW0NCdGHR7PoK21aJGrNDdX");
    Gleap.identify(
      userDetail?.user.id,
      {
        name: userDetail?.user?.first_name,
        email: userDetail?.user?.email,
        companyName: userDetail?.user?.company,
        createdAt: new Date(),
      },
      userDetail?.gleapHash
    );
    Gleap.setReplayOptions({
      recordCanvas: true,
      sampling: {
        canvas: 15,
      },
      // optional image format settings
      dataURLOptions: {
        type: "image/webp",
        quality: 0.6,
      },
    });
  }

  const fetchUserDetails = (id) => {
    getUserProfile(id)
      .then((res) => {
        if (res.data) {
          dispatch(setUserProfile(res.data));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const parsedData = JSON.parse(token);
      dispatch(setUserDetail(parsedData));
      fetchUserDetails(parsedData?.user?.id);
      dispatch(setIsLoading(false));
    }
    dispatch(setIsLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BrowserRouter>
      {isLoading !== null && (
        <Routes>
          {/* Apply the ProtectedRoute for routes that require the user to be authenticated and paid */}
          <Route path="/shared/project" element={<ExternalProjectDetail />} />
          {isAuthenticated && !isPaidUser && (
            <Route path="/submitRequest" element={<SubmitRequest />} />
          )}
          <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignupForm />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                isAuthenticated={isAuthenticated}
                isPaidUser={isPaidUser}
              />
            }
          >
            <Route
              exact
              path="/"
              element={
                <MapWrapper>
                  <MapArea />
                </MapWrapper>
              }
            />
            <Route
              exact
              path="/bulk-import"
              element={
                <ProjectWrapper>
                  <BulkImportProject />
                </ProjectWrapper>
              }
            />
            <Route
              path="/projects"
              element={
                <ProjectWrapper>
                  <Projects />
                </ProjectWrapper>
              }
            />
            <Route
              path="/shared-projects"
              element={
                <ProjectWrapper>
                  <SharedProjects />
                </ProjectWrapper>
              }
            />
            <Route
              path="/deleted-projects"
              element={
                <ProjectWrapper>
                  <DeletedProjects />
                </ProjectWrapper>
              }
            />
            {/* <Route path="/collaboration" element={<Chat />} /> */}
            {/* <Route path="/upload" element={<UploadFile />} /> */}

            <Route
              path="/project/:id"
              element={
                <ProjectWrapper>
                  <ProjectDetail />
                </ProjectWrapper>
              }
            />
            <Route
              path="/share-collection/:id"
              element={
                <ProjectWrapper>
                  <SharedCollection />
                </ProjectWrapper>
              }
            />
            {/* <Route exact path="/llm" element={<Infra3Home />} />
            <Route exact path="/llm-thread" element={<Infra3Thread />} />
            <Route
              exact
              path="/llm-threadlist"
              element={<Infra3ThreadList />}
            />

            <Route exact path="/llm-tender" element={<Infra3TenderHome />} />
            <Route exact path="/llm-tender-thread" element={<Infra3TenderThread />} />
            <Route exact path="/llm-tender-threadlist" element={<Infra3TenderThreadList />} />
            <Route exact path="/llm-tender-textEditor" element={<Infra3TenderTextEditor />} /> */}
          </Route>
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default AppRoutes;
