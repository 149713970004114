import React, { useEffect, useState } from "react";
// import { TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";

import styles from "./LayerSideBar.module.css";

import { layers } from "../../../Common/MapFilterCard/layerCard.config";
import { links } from "../../../Common/MapFilterCard/layer.config";
import LayerCard from "./LayerCard";
import { resetLayersData } from "../../../Redux/reducers/datasetSlice";
import { ChevronDown } from "../../../SVG";
import { setCategorizedLayers, setFilteredLayers, setLayersButton } from "../../../Redux/reducers/selectedLayers";

const LayerSideBar = () => {
  const [activeTab, setActiveTab] = useState("Land");
  const [selectedOption, setSelectedOption] = useState({
    id: "ALL",
    text: "All",
  });
  // const [layerButtons, setLayersButton] = useState([]);
  // const [categorizedLayers, setCategorizedLayers] = useState([]);
  // const [filteredLayers, setFilteredLayers] = useState([]);
  const [statusMenu, setStatusMenu] = useState(null);

  // const [callUpdatedLayers, setCallUpdatedLayers] = useState(false);

  const dispatch = useDispatch();

  const { activeCard } = useSelector((state) => state.dataset);
  const layerButtons = useSelector(
    (state) => state.selectedLayers.layerButtons
  );
  const selectedLayers = useSelector(
    (state) => state.selectedLayers.selectedLayers
  );
  const categorizedLayers = useSelector(
    (state) => state.selectedLayers.categorizedLayers
  );
  const substation = useSelector((state) => state.substations);
  const autoLayers = useSelector((state) => state.filters.enableLayers);

  const open = Boolean(statusMenu);
  const handleClick = (event) => {
    setStatusMenu(event.currentTarget);
  };
  const handleClose = () => {
    setStatusMenu(null);
  };

  const handleDropdownClick = (item) => {
    setSelectedOption(item);
    handleClose();
  };

  const handleClearAllLayers = (e) => {
    e.preventDefault();
    const layers = JSON.parse(JSON.stringify(categorizedLayers));
    dispatch(resetLayersData());
    Object.keys(layers).forEach((category) => {
      layers[category] = layers[category].map((item) => ({
        ...item,
        checked: false,
      }));
    });
    dispatch(setFilteredLayers(layers));
    dispatch(setCategorizedLayers(layers));
  };

  const buttonsArray = [
    { id: "ACTIVE", text: "Active" },
    { id: "ENGLAND", text: "England" },
    { id: "SCOTLAND", text: "Scotland" },
    { id: "WALES", text: "Wales" },
    { id: "EV", text: "EV" },
    { id: "DATA CENTERS", text: "Data Centers" },
    { id: "NATIONAL GRID", text: "National Grid" },
    { id: "NATIONAL GAS", text: "National Gas" },
    { id: "MASS PLANNING VIEW", text: "Mass Planning View" },
    { id: "UKPN", text: "DNO:UKPN" },
    { id: "SSEN", text: "DNO:SSEN" },
    { id: "SPEN", text: "DNO:SPEN" },
    { id: "NPG", text: "DNO:NPG" },
    { id: "NGED", text: "DNO:NGED" },
    { id: "ENWL", text: "DNO:ENWL" },
    { id: "NGN", text: "GDN: NGN" },
  ];

  const allLayers = layers.filter((item) => links[item.name]);

  const namesOfSelectedLayers = Object.entries(links)?.map(([key, value]) => {
    if (selectedLayers?.includes(value)) {
      return key;
    }
  });

  const finalSelectedLayers = allLayers.filter((item) =>
    namesOfSelectedLayers.includes(item.name)
  );

  const categorizeLayers = (layers, categories, substation, autoLayers) => {
    const categorizedLayers = {};

    for (const category of categories) {
      categorizedLayers[category] =
        substation.userLocation && autoLayers
          ? finalSelectedLayers.filter((item) => item.category === category)
          : layers.filter((layer) => layer.category === category);
    }
    const filteredLayerButtons = buttonsArray.filter(
      (button) =>
        categorizedLayers[button.id.replace("_", " ")] &&
        categorizedLayers[button.id.replace("_", " ")].length > 0
    );
    const updatedButtonsArray = [
      { id: "ACTIVE", text: "Active" },
      { id: "ALL", text: "All" },
      ...filteredLayerButtons,
    ];
    dispatch(setLayersButton(updatedButtonsArray));

    return categorizedLayers;
  };

  const categories = [
    "ENGLAND",
    "SCOTLAND",
    "WALES",
    "EV",
    "DATA CENTERS",
    "NATIONAL GRID",
    "NATIONAL GAS",
    "MASS PLANNING VIEW",
    "UKPN",
    "SSEN",
    "SPEN",
    "NPG",
    "NGED",
    "ENWL",
    "NGN", // Add other categories if necessary
  ];

  useEffect(() => {
    // Categorize all layers
    const categorizedLayers = categorizeLayers(
      allLayers,
      categories,
      substation,
      autoLayers
    );
    dispatch(setCategorizedLayers(categorizedLayers));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const layers = { ...categorizedLayers };

    if (Object?.keys(layers)?.length > 0) {
      if (activeTab === "Land" && selectedOption.text === "All") {
        const filterCategories = ["ENGLAND", "SCOTLAND", "WALES"];
        const filteredData = Object.keys(layers)
          .filter((key) => filterCategories.includes(key))
          .reduce((obj, key) => {
            obj[key] = layers[key];
            return obj;
          }, {});

        dispatch(setFilteredLayers(filteredData));
      } else if (
        activeTab === "Infrastructure" &&
        selectedOption.text === "All"
      ) {
        // const filterCategories = ["EV", "Data Centres", "NATIONAL GRID", "NATIONAL GAS", "UKPN", "SSEN", "NPG", "NGED", "ENWL",];
        const filterCategories = ["ENGLAND", "SCOTLAND", "WALES"];

        const filteredData = Object.keys(layers)
          .filter((key) => !filterCategories.includes(key))
          .reduce((obj, key) => {
            obj[key] = layers[key];
            return obj;
          }, {});
        dispatch(setFilteredLayers(filteredData));
      } else if (activeTab === "Active") {
        const dataObject = {
          ["Active"]: activeCard,
        };
        dispatch(setFilteredLayers(dataObject));
      } else {
        const filteredData = layers[selectedOption?.id];
        const dataObject = {
          [selectedOption?.id]: filteredData,
        };
        dispatch(setFilteredLayers(dataObject));
      }
    }
  }, [selectedOption, activeTab, categorizedLayers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form className={styles.layersidebar}>
      <div className={styles.filters}>
        <p className={styles.layers}>Layers</p>
        <button
          className={styles.clearFilter}
          onClick={handleClearAllLayers}
          disabled={activeCard?.length > 0 ? false : true}
          style={{
            cursor: `${activeCard?.length === 0 ? "not-allowed" : "pointer"}`,
          }}
        >
          <div className={styles.clearButton}>
            <a className={styles.clearAll}>Clear all</a>
          </div>
        </button>
      </div>
      <section className={styles.content}>
        <header className={styles.frameParent}>
          <div
            className={
              activeTab === "Land"
                ? styles.landWrapper
                : styles.infrastructureWrapper
            }
            onClick={() => {
              setActiveTab("Land");
              setSelectedOption({ id: "ALL", text: "All" });
            }}
          >
            <a
              className={
                activeTab === "Land" ? styles.land : styles.infrastructure
              }
            >
              Land
            </a>
          </div>
          <div
            className={
              activeTab === "Infrastructure"
                ? styles.landWrapper
                : styles.infrastructureWrapper
            }
            onClick={() => {
              setActiveTab("Infrastructure");
              setSelectedOption({ id: "ALL", text: "All" });
            }}
          >
            <a
              className={
                activeTab === "Infrastructure"
                  ? styles.land
                  : styles.infrastructure
              }
            >
              Infrastructure
            </a>
          </div>
          <div
            className={
              activeTab === "Active"
                ? styles.landWrapper
                : styles.infrastructureWrapper
            }
            onClick={() => setActiveTab("Active")}
          >
            <a
              className={
                activeTab === "Active" ? styles.land : styles.infrastructure
              }
            >
              Active
            </a>
          </div>
        </header>
        {activeTab !== "Active" && (
          <div className={styles.englandParent} onClick={handleClick}>
            <a className={styles.england}>{selectedOption?.text}</a>
            <ChevronDown fill="#151616" />
          </div>
        )}
      </section>
      {/* <div className={styles.separator} /> */}
      {/* <TextField
                className={styles.search}
                placeholder="Search layers"
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <Searchmd />
                    ),
                }}
                sx={{
                    "& fieldset": { borderColor: "#afb2b2" },
                    "& .MuiInputBase-root": {
                        height: "40px",
                        backgroundColor: "#fff",
                        paddingLeft: "12px",
                        borderRadius: "8px",
                        fontSize: "12px",
                    },
                    "& .MuiInputBase-input": { paddingLeft: "8px", color: "#afb2b2" },
                }}
            /> */}

      <LayerCard
        // filteredLayers={filteredLayers}
        // setCategorizedLayers={setCategorizedLayers}
        // categorizedLayers={categorizedLayers}
      />
      <Menu
        id="basic-menu"
        anchorEl={statusMenu}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            width: "307px", // Optional: Adjust the menu width
          },
        }}
      >
        {activeTab === "Land" &&
          layerButtons?.map(
            (item) =>
              (item.text === "England" ||
                item.text === "Scotland" ||
                item.text === "Wales" ||
                item.text === "All") && (
                <MenuItem
                  key={item.LayerSideBar}
                  onClick={() => handleDropdownClick(item)}
                  sx={{
                    backgroundColor:
                      item.text === selectedOption?.text
                        ? "#556561"
                        : "inherit", // Replace with the active color
                    color: item === selectedOption?.text ? "#fff" : "inherit", // Optional: Change text color for active items
                  }}
                >
                  {item.text}
                </MenuItem>
              )
          )}

        {activeTab === "Infrastructure" &&
          layerButtons?.map(
            (item) =>
              item.text !== "England" &&
              item.text !== "Scotland" &&
              item.text !== "Wales" &&
              item.text !== "Active" && (
                <MenuItem
                  key={item?.id}
                  onClick={() => handleDropdownClick(item)}
                  sx={{
                    backgroundColor:
                      item.text === selectedOption?.text
                        ? "#556561"
                        : "inherit", // Replace with the active color
                    color: item === selectedOption?.text ? "#fff" : "inherit", // Optional: Change text color for active items
                  }}
                >
                  {item.text}
                </MenuItem>
              )
          )}
      </Menu>
    </form>
  );
};
export default LayerSideBar;
