import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useControl, useMap } from "react-map-gl";
import "./myMap.css";
import { getEnclosingPolygonById } from '../Api/API';
import { setSearchedParcel } from '../Redux/reducers/substationSlice';
import { ToastNotification } from "../Utils/ToastNotifications";
import SearchIcon from "@mui/icons-material/Search";
import {
    Autocomplete,
    TextField,
    IconButton,
    CircularProgress
  } from "@mui/material";

  const NpsControl = ({ mapZoom, setShowNPS, showNPS, setShowCOD, showCOD, showPrivate, setShowPrivate }) => {
    const map = useMap();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchId, setSearchId] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    // const [mapZoom, setMapZoom] = useState(0);
    const modalRef = useRef(null);

    const handleCheckboxChange = (setter) => () => {
        setter(prev => !prev);
    };

    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };

    const searchSuggestions = async (value) => {
        console.log("Search Land Parcel", value);
    };

    const handleSearchKeyDown = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          searchLandParcel();
        }
      };

    const searchLandParcel = async () => {
        if (searchId && searchId.length > 2) {
            setLoading(true);
            let body = {id: searchId};
            getEnclosingPolygonById(body)
            .then((response) => {
                if (response.success && response?.data?.data?.features?.length > 0) {
                console.log("Enclosing Api response", response?.data);
                dispatch(setSearchedParcel(response?.data));
                setLoading(false);
                } else {
                ToastNotification("warn", "Parcel not found!");
                dispatch(setSearchedParcel([]));
                setLoading(false);
                }
            })
            .catch((err) => {
                dispatch(setSearchedParcel([]));
                console.log(err);
            });
        } else {
            ToastNotification("warn", "Unable to search land parcels");
            setLoading(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (mapZoom > 13) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    }, [mapZoom]);

    const control = {
        onAdd(map) {
            this._map = map;
            // setMapZoom(map.getZoom());
            this._container = document.createElement("div");
            this._container.className = "mapboxgl-ctrl";
            const button = document.createElement("button");
            button.classList.add("npsButton");
            button.textContent = "Land Boundaries";
            const button2 = document.createElement("button");
            button2.textContent = "Land Boundaries";
            button2.classList.add("npsButton2");
            // if (map.getZoom() < 13) {
            //     // button.title = "Zoom in further to see land ownership parcels";
            //     button.disabled = true;
            // } else {
            //     // button.title = "Toggle land ownership parcels";
            //     button.disabled = false;
            // }
            button.onclick = () => {
                // if (map.getZoom() >= 13) {
                    setShowButtons(true);
                // } else {
                //     setShowButtons(false);
                // }
                setShowModal((prev)=> !prev);
            };
            button2.onclick = () => {
                    ToastNotification("warn", "Zoom in further to see land ownership parcels");
            };
            
            if (map.getZoom() < 13) {
                this._container.appendChild(button2);
            } else {
                this._container.appendChild(button);
            }
            return this._container;
        },
        onRemove() {
            this._container.parentNode.removeChild(this._container);
            this._map = undefined;
        },
    };

    useControl(() => control);

    return (
        <div ref={modalRef}>
            {/* {(showModal && !showButtons) && (
                <p className="npsModalWarning">
                    Zoom in further to see land ownership parcels.
                </p>
            )} */}
            {showModal && showButtons && (
                <div className="npsModal">
                    <div className="modalContent">
                        <label>
                            <input type="checkbox" checked={showNPS} onChange={handleCheckboxChange(setShowNPS)} />
                            All Land
                        </label>
                        <label>
                            <input type="checkbox" checked={showPrivate} onChange={handleCheckboxChange(setShowPrivate)} />
                            Privately owned Land
                        </label>
                        <label>
                            <input type="checkbox" checked={showCOD} onChange={handleCheckboxChange(setShowCOD)} />
                            Company-owned Land
                        </label>
                        <TextField
                            id="outlined-search"
                            label="Parcel Id"
                            margin="normal"
                            variant="outlined"
                            value={searchId}
                            placeholder='Search Land Parcel'
                            onChange={(e) => setSearchId(e.target.value)}
                            onKeyDown={handleSearchKeyDown}
                            InputProps={{
                                startAdornment: (
                                    <React.Fragment>
                                        {loading ? 
                                            <CircularProgress color="inherit" size={20} /> : 
                                            <IconButton 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    searchLandParcel()}
                                                }
                                                sx={{padding: "0px"}}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        }
                                    </React.Fragment>
                                )
                            }}
                            sx={{
                                width: "200px",
                                "& .MuiOutlinedInput-root": {
                                    padding: "2px",
                                    font: "12px/20px Helvetica Neue, Arial, Helvetica, sans-serif",
                                    "&.MuiInputBase-root fieldset": {
                                        borderColor: "green",
                                    },
                                },
                                "& .MuiOutlinedInput-input": {
                                    padding: "2px",
                                    font: "12px/20px Helvetica Neue, Arial, Helvetica, sans-serif",
                                },
                            }}
                        />
                        {/* <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            options={options}
                            // getOptionLabel={(option) => option.id}
                            // onChange={(_, newValue) => {
                            //     if (newValue) {
                            //         searchLandParcel(newValue);
                            //     }
                            // }}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    setOptions(newValue);
                                    setSearchId(newValue);
                                }
                            }}
                            // onInputChange={(_, value) => {
                            //     searchSuggestions(value);
                            //   }}
                            value={searchId}
                            onKeyDown={handleSearchKeyDown}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Land"
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? 
                                                    <CircularProgress color="inherit" size={20} /> : 
                                                    <IconButton onClick={searchLandParcel}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                }
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                        // endAdornment: (
                                        //     <InputAdornment position="end">
                                        //         <SearchIcon />
                                        //     </InputAdornment>
                                        // ),
                                    }}
                                />
                            )}
                        /> */}
                        
                    </div>
                </div>
            )}
        </div>
    );
};

export default NpsControl;
