import "./../Map/myMap.css";
import "maplibre-gl/dist/maplibre-gl.css";
import Loader from "../Common/Loader/Loader";
import { useState, useRef, useEffect } from "react";
import Map, { Marker } from "react-map-gl/maplibre";
import bsSearch from "../../Images/battery_search.png";
import rneSearch from "../../Images/renewable_search.png";
const SatView = ({ data, type }) => {
  const proMapRef = useRef();

  const [loading, setLoading] = useState(true);

  const [viewport, setViewport] = useState({
    // longitude: type === "trans" ? data?.Longitute : data?.lng ? data.lng : -0.1275862,
    // latitude: type === "trans" ? data?.Lattitude : data?.lat  ? data.lat : 51.5072178,
    longitude: data?.Longitute ? data?.Longitute : -0.1275862,
    latitude: data?.Lattitude ? data?.Lattitude : 51.5072178,
    zoom: 15,
    bearing: 0,
    pitch: 0,
  });

  const myGoogleKey = "AIzaSyCQFqXwp4QZGkJuCQr6SjPwiTr8QHckksg";

  const mapStyle = {
    version: 8,
    sources: {
      "google-satellite": {
        type: "raster",
        tiles: [
          "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=" +
            myGoogleKey,
        ],
        tileSize: 256,
      },
    },
    bounds: [-10.76418, 50.528423, 1.9134116, 60.331151],
    center: [-0.1275862, 51.5072178, 10],
    layers: [
      {
        id: "google-satellite",
        type: "raster",
        source: "google-satellite",
        layout: { visibility: "visible" },
        paint: { "raster-opacity": 1 },
        minzoom: 0,
        maxzoom: 22,
      },
    ],
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 900);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //   const onMapLoad = () => {
  //     if(proMapRef?.current){
  //         const map = proMapRef?.current.getMap();
  //         if(type === "trans") {
  //             map.setCenter(data?.Longitute, data?.Lattitude);
  //         }
  //     }
  //   };
  return (
    <div id="satView" style={{ width: "100%", height: "200px" }}>
      {loading ? (
        <Loader />
      ) : (
        <Map
          ref={proMapRef}
          //   onLoad={onMapLoad}
          initialViewState={viewport}
          onViewportChange={(nextViewport) => setViewport(nextViewport)}
          minZoom={15}
          maxZoom={15}
          mapStyle={mapStyle}
          transformRequest={(url) => {
            if (url.includes("voltquant-images"))
              return {
                url: url,
              };
          }}
          style={{ borderRadius: "10px" }}
        >
          {data && (
            <Marker
              longitude={data?.Longitute}
              latitude={data?.Lattitude}
              anchor="center"
            >
              <img
                src={type === "SecAqu" ? bsSearch : rneSearch}
                alt="Wire location"
                style={{ width: 30, height: 30 }}
              />
            </Marker>
          )}
        </Map>
      )}
    </div>
  );
};

export default SatView;
