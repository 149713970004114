import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useSelector, useDispatch } from "react-redux";
import { links } from "../../Common/MapFilterCard/layer.config";
import { layers } from "../../Common/MapFilterCard/layerCard.config";
import { findTitleFromLayerName } from "./utils";
import {
  appendActiveCard,
  appendNewLayer,
  removeLayer,
  setActiveCard,
} from "../../Redux/reducers/datasetSlice";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { setCategorizedLayers, setLayersButton } from "../../Redux/reducers/selectedLayers";
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" color="success" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export const SpatialCard = (props) => {
  const { text } = props;
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);

  const categorizedLayers = useSelector(
    (state) => state.selectedLayers.categorizedLayers
  );

  const selectedLayers = useSelector(
    (state) => state.selectedLayers.selectedLayers
  );

  const { activeCard } = useSelector((state) => state.dataset);
  const substation = useSelector((state) => state.substations);
  const autoLayers = useSelector((state) => state.filters.enableLayers);
  const allLayers = layers.filter((item) => links[item.name]);
  const namesOfSelectedLayers = Object.entries(links)?.map(([key, value]) => {
    if (selectedLayers?.includes(value)) {
      return key;
    }
  });

  const finalSelectedLayers = allLayers.filter((item) =>
    namesOfSelectedLayers.includes(item.name)
  );

  const categories = [
    "ENGLAND",
    "SCOTLAND",
    "WALES",
    "EV",
    "DATA CENTERS",
    "NATIONAL GRID",
    "NATIONAL GAS",
    "MASS PLANNING VIEW",
    "UKPN",
    "SSEN",
    "SPEN",
    "NPG",
    "NGED",
    "ENWL",
    "NGN", // Add other categories if necessary
  ];

  const buttonsArray = [
    { id: "ACTIVE", text: "Active" },
    { id: "ENGLAND", text: "England" },
    { id: "SCOTLAND", text: "Scotland" },
    { id: "WALES", text: "Wales" },
    { id: "EV", text: "EV" },
    { id: "DATA CENTERS", text: "Data Centers" },
    { id: "NATIONAL GRID", text: "National Grid" },
    { id: "NATIONAL GAS", text: "National Gas" },
    { id: "MASS PLANNING VIEW", text: "Mass Planning View" },
    { id: "UKPN", text: "DNO:UKPN" },
    { id: "SSEN", text: "DNO:SSEN" },
    { id: "SPEN", text: "DNO:SPEN" },
    { id: "NPG", text: "DNO:NPG" },
    { id: "NGED", text: "DNO:NGED" },
    { id: "ENWL", text: "DNO:ENWL" },
    { id: "NGN", text: "GDN: NGN" },
  ];

  React.useEffect(() => {
    // Categorize all layers
    const categorizedLayers = categorizeLayers(
      allLayers,
      categories,
      substation,
      autoLayers
    );
    dispatch(setCategorizedLayers(categorizedLayers));
  }, []);

  const categorizeLayers = (layers, categories, substation, autoLayers) => {
    const categorizedLayers = {};

    for (const category of categories) {
      categorizedLayers[category] =
        substation.userLocation && autoLayers
          ? finalSelectedLayers.filter((item) => item.category === category)
          : layers.filter((layer) => layer.category === category);
    }
    const filteredLayerButtons = buttonsArray.filter(
      (button) =>
        categorizedLayers[button.id.replace("_", " ")] &&
        categorizedLayers[button.id.replace("_", " ")].length > 0
    );
    const updatedButtonsArray = [
      { id: "ACTIVE", text: "Active" },
      { id: "ALL", text: "All" },
      ...filteredLayerButtons,
    ];
    dispatch(setLayersButton(updatedButtonsArray));

    return categorizedLayers;
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const geoSpatialRisk = useSelector((state) => state.selectedLayers.geoSpatialRisk);

  const handleCheckboxChange = (value, layer) => {
    // making checkbox true or false for specific layer
    const updatedLayersWitchCheckedValue = { ...categorizedLayers };
    updatedLayersWitchCheckedValue[layer.category] =
      updatedLayersWitchCheckedValue[layer.category].map((item) => {
        if (item.id === layer.id) {
          return { ...item, checked: value };
        }
        return item;
      });
      console.log("Updated: ", updatedLayersWitchCheckedValue);
    dispatch(setCategorizedLayers(updatedLayersWitchCheckedValue));

    const layerName = links[layer.name];
    console.log("Clicked right: ", layerName, layer);
    const updatedLayer = { ...layer, checked: value };
    if (layerName && value) {
      dispatch(appendNewLayer({ layerName: layerName }));
      dispatch(appendActiveCard(updatedLayer));
    } else if (layerName && !value) {
      const newActiveCard = activeCard.filter(
        (card) => !card.name.includes(layer.name)
      );
      if (layerName) {
        dispatch(removeLayer({ layerName: layerName }));
        dispatch(setActiveCard(newActiveCard));
      }
    } else {
      ToastNotification("warn", "Data unavailable");
    }
  };

  return (
   
    <div className={styles.planningCardz}>
      <Accordion
        expanded={expanded === "panel"}
        onChange={handleChange("panel")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "40px", height: "40px", marginRight: "10px" }}
              src="https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/spatial-analysis-icon.svg"
              alt="localplanning"
            />
            <div>
              <p className={styles.planningCardz__heading}>{text}</p>
              <p className={styles.planningCardz__heading__sub}>
                {geoSpatialRisk?.length > 1
                  ? `${geoSpatialRisk?.length} Potential Risks`
                  : geoSpatialRisk?.length === 1
                  ? "1 Potential Risk"
                  : "No Risk"}
              </p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.planningCardz__danger}>
            <img
              src="https://voltquant-images.s3.eu-west-2.amazonaws.com/icons/cross-icon.svg"
              alt="icon"
            />
            <div>
              <h1 className={styles.planningCardz__alert__heading}>
                Geospatial Risk
              </h1>
              <p className={styles.planningCardz__alert__para}>
                Major geospatial risks identified.{" "}
              </p>
              <ul>
                {
                  geoSpatialRisk.map((item,i)=>{
                    const name = findTitleFromLayerName(links,layers, item);
                    const layerObj = layers.filter((item) => item.title === name);
                    const match = activeCard?.filter(
                      (data) => data?.title === name
                    );
                    const checked = match?.length > 0 ? match[0].checked : false;
                    console.log("Layer geo: ", item, layerObj, name, checked);
                    if(name) {
                      return (
                      <li key={i}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px'}}>
                          <label>{name}</label>
                          {checked ? 
                            <VisibilityOutlinedIcon fontSize="small" color="success"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCheckboxChange(!checked, layerObj[0])
                              }}
                              /> : 
                              <VisibilityOffOutlinedIcon fontSize="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCheckboxChange(!checked, layerObj[0])
                                }}
                              />
                          }
                        </div>
                      </li>)
                    }
                  })
                }
              </ul>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
    
  );
};
