const rein_inprogress = {
"type": "FeatureCollection",
"name": "rein_inprogress",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "Licence": "EMIDS", "Grid Supply Point": "Willington", "Substation or Circuit": "Derby South", "Planned Work": "Install additional 132/33kV transformer and swg", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2024", "Lat": 52.856387, "Lon": -1.548794 }, "geometry": { "type": "Point", "coordinates": [ -1.548794, 52.856387 ] } },
{ "type": "Feature", "properties": { "Licence": "EMIDS", "Grid Supply Point": "Grendon", "Substation or Circuit": "Sharnbrook", "Planned Work": "Install additional 33/11kV transformer and new 33kV circuit", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2025", "Lat": 52.243426, "Lon": -0.725638 }, "geometry": { "type": "Point", "coordinates": [ -0.725638, 52.243426 ] } },
{ "type": "Feature", "properties": { "Licence": "EMIDS", "Grid Supply Point": "Chesterfield", "Substation or Circuit": "Mansfield to Clipstone", "Planned Work": "132kV cable reinforcement", "Impact on Network Capability": "Increase firm capacity", "Status": "In-Progress", "Completion": "2024/2025", "Lat": 53.224985, "Lon": -1.386818 }, "geometry": { "type": "Point", "coordinates": [ -1.386818, 53.224985 ] } },
{ "type": "Feature", "properties": { "Licence": "SWALES", "Grid Supply Point": "Rassau 132kV", "Substation or Circuit": "Llandrindod Wells to Rhayader 11 kV circuit", "Planned Work": "Reinforce 11kV circuit from Llandrindod Wells to Rhayader.", "Impact on Network Capability": "Increase firm capacity", "Status": "In progress", "Completion": "2024", "Lat": 51.808942, "Lon": -3.225411 }, "geometry": { "type": "Point", "coordinates": [ -3.225411, 51.808942 ] } },
{ "type": "Feature", "properties": { "Licence": "SWALES", "Grid Supply Point": "Cardiff East / Aberthaw 132kV", "Substation or Circuit": "Aberthaw to Cardiff West 132kV Circuits", "Planned Work": "Re-profile to operate at 75 deg C", "Impact on Network Capability": "Increase Firm capacity", "Status": "In progress", "Completion": "2025", "Lat": 51.388753, "Lon": -3.402137 }, "geometry": { "type": "Point", "coordinates": [ -3.402137, 51.388753 ] } },
{ "type": "Feature", "properties": { "Licence": "SWALES", "Grid Supply Point": "Cardiff East / Aberthaw 132kV", "Substation or Circuit": "Cardiff North 132/33kV S/S", "Planned Work": "Install two additional 33kV circuits from Cardiff North BSP to Heath Primary Substation", "Impact on Network Capability": "Increase firm capacity", "Status": "In progress", "Completion": "2025", "Lat": 51.388753, "Lon": -3.402137 }, "geometry": { "type": "Point", "coordinates": [ -3.402137, 51.388753 ] } },
{ "type": "Feature", "properties": { "Licence": "SWALES", "Grid Supply Point": "Swansea North 132kV", "Substation or Circuit": "Pantyffynon 33kV Circuit Re-profiling", "Planned Work": "Reprofile the existing 33kV OHL Circuits to 75 deg C between Ammanford BSP and Pantyffynon   ", "Impact on Network Capability": "Increase firm capacity", "Status": "In progress", "Completion": "2025", "Lat": 51.683165, "Lon": -3.960809 }, "geometry": { "type": "Point", "coordinates": [ -3.960809, 51.683165 ] } },
{ "type": "Feature", "properties": { "Licence": "SWALES", "Grid Supply Point": "Cardiff East / Aberthaw 132kV", "Substation or Circuit": "Ashgrove Primary Reinforcement", "Planned Work": "Upgrade existing Transformer from 10MVA to 23MVA", "Impact on Network Capability": "Increase firm capacity", "Status": "In progress", "Completion": "2026", "Lat": 51.388753, "Lon": -3.402137 }, "geometry": { "type": "Point", "coordinates": [ -3.402137, 51.388753 ] } },
{ "type": "Feature", "properties": { "Licence": "SWALES", "Grid Supply Point": "Pembroke 132kV", "Substation or Circuit": "Haverfordwest to Brawdy 33kV Circuit", "Planned Work": "Install additional 33kV Circuit from Haverfordwest to Brawdy", "Impact on Network Capability": "Increase firm capacity", "Status": "In progress", "Completion": "2028", "Lat": 51.68228, "Lon": -4.986936 }, "geometry": { "type": "Point", "coordinates": [ -4.986936, 51.68228 ] } },
{ "type": "Feature", "properties": { "Licence": "SWALES", "Grid Supply Point": "Swansea North 132kV", "Substation or Circuit": "Aberaeron Primary Reinforcement", "Planned Work": "Install second 33kV Circuit and 33/11kV Transformer at Aberaeron", "Impact on Network Capability": "Increase firm capacity", "Status": "In progress", "Completion": "2028", "Lat": 51.683165, "Lon": -3.960809 }, "geometry": { "type": "Point", "coordinates": [ -3.960809, 51.683165 ] } },
{ "type": "Feature", "properties": { "Licence": "SWALES", "Grid Supply Point": "Swansea North 132kV", "Substation or Circuit": "Llanfyrnach Primary Reinforcement", "Planned Work": "Install second 33kV Circuit and 33/11kV Transformer at Llanfyrnach", "Impact on Network Capability": "Increase firm capacity", "Status": "In progress", "Completion": "2028", "Lat": 51.683165, "Lon": -3.960809 }, "geometry": { "type": "Point", "coordinates": [ -3.960809, 51.683165 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Abham_Exeter _Landulph", "Substation or Circuit": "Cowley Road", "Planned Work": "Replace primary transformers", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2023", "Lat": 50.472347, "Lon": -3.729791 }, "geometry": { "type": "Point", "coordinates": [ -3.729791, 50.472347 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Alverdiscott_Indian Queens", "Substation or Circuit": "Holsworthy Primary", "Planned Work": "Two new 12/24 transformers will be installed and two 33kV line breakers.", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2023", "Lat": 51.00633, "Lon": -4.136448 }, "geometry": { "type": "Point", "coordinates": [ -4.136448, 51.00633 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Alverdiscott_Indian Queens", "Substation or Circuit": "Victoria Primary", "Planned Work": "New 33/11kV single s/s - s/s works", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2023", "Lat": 51.00633, "Lon": -4.136448 }, "geometry": { "type": "Point", "coordinates": [ -4.136448, 51.00633 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Abham_Exeter _Landulph", "Substation or Circuit": "Landulph 33kV/St Germans 33kV", "Planned Work": "Install additional GT(1) at Landulph BSP to run on hot stand by until 33kV splits between Landulph and St Germans are confirmed", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2024", "Lat": 50.472347, "Lon": -3.729791 }, "geometry": { "type": "Point", "coordinates": [ -3.729791, 50.472347 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Bridgwater_Taunton", "Substation or Circuit": "Dunwear BSP", "Planned Work": "132kv only Constraint - New s/s works", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2024", "Lat": 51.018222, "Lon": -3.154331 }, "geometry": { "type": "Point", "coordinates": [ -3.154331, 51.018222 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Seabank", "Substation or Circuit": "Bournville Primary", "Planned Work": "T1 and T2 replaced with 12/24MVA units", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2024", "Lat": 51.537053, "Lon": -2.669737 }, "geometry": { "type": "Point", "coordinates": [ -2.669737, 51.537053 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Abham_Exeter _Landulph", "Substation or Circuit": "Tiverton", "Planned Work": "GT changes", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2025", "Lat": 50.472347, "Lon": -3.729791 }, "geometry": { "type": "Point", "coordinates": [ -3.729791, 50.472347 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Alverdiscott_Indian Queens", "Substation or Circuit": "Indian Queens 132kV - Camborne 132kV & Hayle 132kV - Rame 132kV circuits", "Planned Work": "Rame - Hayle tee split. New 132kV Circuit from Rame to Camborne.", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2025", "Lat": 51.00633, "Lon": -4.136448 }, "geometry": { "type": "Point", "coordinates": [ -4.136448, 51.00633 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Alverdiscott_Indian Queens", "Substation or Circuit": "Newquay Trencreek", "Planned Work": "33/11kV single s/s - s/s works", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2023/24", "Lat": 51.00633, "Lon": -4.136448 }, "geometry": { "type": "Point", "coordinates": [ -4.136448, 51.00633 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Abham_Exeter _Landulph", "Substation or Circuit": "Gunnislake", "Planned Work": "T1 and T2 replaced with 7.5/15MVA units", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2024/25", "Lat": 50.472347, "Lon": -3.729791 }, "geometry": { "type": "Point", "coordinates": [ -3.729791, 50.472347 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Oldbury", "Substation or Circuit": "Oldbury", "Planned Work": "New SGT and 132kV switchgear", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2023", "Lat": 52.498932, "Lon": -2.022126 }, "geometry": { "type": "Point", "coordinates": [ -2.022126, 52.498932 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Lea marston", "Substation or Circuit": "Lea Marston – Copt Heath 132kV   OHL", "Planned Work": "Reconductor the circuit ", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2025", "Lat": 52.5276, "Lon": -1.7082 }, "geometry": { "type": "Point", "coordinates": [ -1.7082, 52.5276 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Bishops Wood", "Substation or Circuit": "Stockton", "Planned Work": "STP- add second transformer & install a second CCT for 11km O/H(assumed 70/30 split of OH/UG respectively)", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2026", "Lat": 52.313039, "Lon": -2.243147 }, "geometry": { "type": "Point", "coordinates": [ -2.243147, 52.313039 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Bishops Wood", "Substation or Circuit": "Stourport 132 66 & Upton Warren 132 66", "Planned Work": "Cable approx 600m at Cutnall Green and 200m(OH line uprate)of Jumper at Stourport BSP", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2024", "Lat": 52.313039, "Lon": -2.243147 }, "geometry": { "type": "Point", "coordinates": [ -2.243147, 52.313039 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Shrewsbury", "Substation or Circuit": "Shrewsbury SGT", "Planned Work": "Install a new SGT at Shrewsbury", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2026", "Lat": 52.709173, "Lon": -2.69341 }, "geometry": { "type": "Point", "coordinates": [ -2.69341, 52.709173 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Cellarhead", "Substation or Circuit": "Hill Chorlton", "Planned Work": "add second transformer and circuit 9km 70/30 split UG and O/H", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2025", "Lat": 53.04134, "Lon": -2.085629 }, "geometry": { "type": "Point", "coordinates": [ -2.085629, 53.04134 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Shrewsbury", "Substation or Circuit": "Shelton", "Planned Work": "Install a new 33/11kV substation at Shelton", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2028", "Lat": 52.709173, "Lon": -2.69341 }, "geometry": { "type": "Point", "coordinates": [ -2.69341, 52.709173 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Iron Acton", "Substation or Circuit": "Chipping sodbury BSP", "Planned Work": "Install a new 132/11kV Tx ", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2028", "Lat": 51.570415, "Lon": -2.480174 }, "geometry": { "type": "Point", "coordinates": [ -2.480174, 51.570415 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Cellarhead", "Substation or Circuit": "Meaford BSP", "Planned Work": "new 132/33kV Tx, 1lm x 132kV cable, 33kV switchboard", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2028", "Lat": 53.04134, "Lon": -2.085629 }, "geometry": { "type": "Point", "coordinates": [ -2.085629, 53.04134 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Feckenham", "Substation or Circuit": "Feckenham", "Planned Work": "New Tx Feckenham 66/11kV", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2025", "Lat": 52.252827, "Lon": -1.97183 }, "geometry": { "type": "Point", "coordinates": [ -1.97183, 52.252827 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Bishops Wood", "Substation or Circuit": "Ledbury", "Planned Work": "Upgrade Tx. on site. No physical room", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2024", "Lat": 52.313039, "Lon": -2.243147 }, "geometry": { "type": "Point", "coordinates": [ -2.243147, 52.313039 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Bushbury", "Substation or Circuit": "Stafford Bushbury", "Planned Work": "upgrade OHL (19)km approx", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2025", "Lat": 52.636594, "Lon": -2.114402 }, "geometry": { "type": "Point", "coordinates": [ -2.114402, 52.636594 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Lea marston", "Substation or Circuit": "Lea Marston – Elmdon 132kV   OHL", "Planned Work": "reconductor the circuit", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2025", "Lat": 52.5276, "Lon": -1.7082 }, "geometry": { "type": "Point", "coordinates": [ -1.7082, 52.5276 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Ironbridge", "Substation or Circuit": "Star Aluminium 33/11kV", "Planned Work": "Lay approx 20.5km of 33kV cable between Ironbridge and Star Aluminium.", "Impact on Network Capability": "Increase firm capacity", "Status": "In Progress", "Completion": "2026", "Lat": 52.632623, "Lon": -2.509588 }, "geometry": { "type": "Point", "coordinates": [ -2.509588, 52.632623 ] } }
]
};

export default rein_inprogress;
