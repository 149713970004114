const rein_completed = {
"type": "FeatureCollection",
"name": "rein_completed",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "Licence": "EMIDS", "Grid Supply Point": "Berkswell", "Substation or Circuit": "Berkswell", "Planned Work": "Additional SGT and 132kV swg", "Impact on Network Capability": "Increase firm capacity", "Status": "Complete", "Completion": 2023, "Latitude": 52.386945, "Longitude": -1.613835 }, "geometry": { "type": "Point", "coordinates": [ -1.613835, 52.386945 ] } },
{ "type": "Feature", "properties": { "Licence": "EMIDS", "Grid Supply Point": "Chesterfield", "Substation or Circuit": "Alfreton", "Planned Work": "Upgrade 132/33kV grid transformers ", "Impact on Network Capability": "Increase firm capacity", "Status": "Complete", "Completion": 2023, "Latitude": 53.224985, "Longitude": -1.386818 }, "geometry": { "type": "Point", "coordinates": [ -1.386818, 53.224985 ] } },
{ "type": "Feature", "properties": { "Licence": "EMIDS", "Grid Supply Point": "Chesterfield", "Substation or Circuit": "Chesterfield", "Planned Work": "132kV Fault Level works", "Impact on Network Capability": "Increase Fault Level capability", "Status": "Complete", "Completion": 2023, "Latitude": 53.224985, "Longitude": -1.386818 }, "geometry": { "type": "Point", "coordinates": [ -1.386818, 53.224985 ] } },
{ "type": "Feature", "properties": { "Licence": "EMIDS", "Grid Supply Point": "East Claydon", "Substation or Circuit": "Bradwell Abbey - Newport Pagnell", "Planned Work": "SWALES", "Impact on Network Capability": "Increase firm capacity", "Status": "Complete", "Completion": 2023, "Latitude": 51.927273, "Longitude": -0.907298 }, "geometry": { "type": "Point", "coordinates": [ -0.907298, 51.927273 ] } },
{ "type": "Feature", "properties": { "Licence": "EMIDS", "Grid Supply Point": "Staythorpe", "Substation or Circuit": "Staythorpe B", "Planned Work": "Replacement of 132kV switchgear", "Impact on Network Capability": "Fault Level capability", "Status": "Complete", "Completion": 2023, "Latitude": 53.076936, "Longitude": -0.865477 }, "geometry": { "type": "Point", "coordinates": [ -0.865477, 53.076936 ] } },
{ "type": "Feature", "properties": { "Licence": "EMIDS", "Grid Supply Point": "Walpole", "Substation or Circuit": "Bourne - West Deeping", "Planned Work": "33kV circuit reinforcement", "Impact on Network Capability": "Increase firm capacity", "Status": "Complete", "Completion": 2023, "Latitude": 52.727802, "Longitude": 0.199163 }, "geometry": { "type": "Point", "coordinates": [ 0.199163, 52.727802 ] } },
{ "type": "Feature", "properties": { "Licence": "EMIDS", "Grid Supply Point": "Walpole", "Substation or Circuit": "Stamford - Wittering, West Deeping", "Planned Work": "33kV circuit reinforcement", "Impact on Network Capability": "Increase firm capacity", "Status": "Complete", "Completion": 2023, "Latitude": 52.727802, "Longitude": 0.199163 }, "geometry": { "type": "Point", "coordinates": [ 0.199163, 52.727802 ] } },
{ "type": "Feature", "properties": { "Licence": "EMIDS", "Grid Supply Point": "West Burton", "Substation or Circuit": "Lincoln - Anderson Lane/South Carlton", "Planned Work": "33kV circuit reinforcement", "Impact on Network Capability": "Increase firm capacity", "Status": "Complete", "Completion": 2023, "Latitude": 53.360439, "Longitude": -0.807142 }, "geometry": { "type": "Point", "coordinates": [ -0.807142, 53.360439 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Abham_Exeter _Landulph", "Substation or Circuit": "Exeter Main to Ottery St Mary", "Planned Work": "Reprofile 33kV overhead line", "Impact on Network Capability": "Increase firm capacity", "Status": "Completed", "Completion": 2023, "Latitude": 50.472347, "Longitude": -3.729791 }, "geometry": { "type": "Point", "coordinates": [ -3.729791, 50.472347 ] } },
{ "type": "Feature", "properties": { "Licence": "SWEST", "Grid Supply Point": "Alverdiscott_Indian Queens", "Substation or Circuit": "Truro BSP", "Planned Work": "132/33kV single s/s - s/s works", "Impact on Network Capability": "Increase firm capacity", "Status": "Completed", "Completion": 2023, "Latitude": 51.00633, "Longitude": -4.136448 }, "geometry": { "type": "Point", "coordinates": [ -4.136448, 51.00633 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Cellarhead", "Substation or Circuit": "Whitfield", "Planned Work": "Uprate existing Whitfield - Congleton 33kV circuit", "Impact on Network Capability": "Increase firm capacity", "Status": "Completed", "Completion": 2023, "Latitude": 53.04134, "Longitude": -2.085629 }, "geometry": { "type": "Point", "coordinates": [ -2.085629, 53.04134 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Iron Acton", "Substation or Circuit": "Camp / Cherrington", "Planned Work": "New 33kV circuit between Camp and Cherrington", "Impact on Network Capability": "Increase firm capacity", "Status": "Completed", "Completion": 2023, "Latitude": 51.570415, "Longitude": -2.480174 }, "geometry": { "type": "Point", "coordinates": [ -2.480174, 51.570415 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Ironbridge", "Substation or Circuit": "Donnington", "Planned Work": "New Tx at Donnington and new 33kV circuit between Ketley and Donnington", "Impact on Network Capability": "Increase firm capacity", "Status": "Completed", "Completion": 2023, "Latitude": 52.632623, "Longitude": -2.509588 }, "geometry": { "type": "Point", "coordinates": [ -2.509588, 52.632623 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Penn", "Substation or Circuit": "Penn", "Planned Work": "Replacement of 132kV switchgear", "Impact on Network Capability": "Fault Level capability", "Status": "Completed", "Completion": 2023, "Latitude": 52.556628, "Longitude": -2.208297 }, "geometry": { "type": "Point", "coordinates": [ -2.208297, 52.556628 ] } },
{ "type": "Feature", "properties": { "Licence": "WMIDS", "Grid Supply Point": "Port Ham", "Substation or Circuit": "Hereford (Walham)", "Planned Work": "Installation of a new 132/66kV transformer", "Impact on Network Capability": "Fault Level capability", "Status": "Completed", "Completion": 2023, "Latitude": 51.871123, "Longitude": -2.265373 }, "geometry": { "type": "Point", "coordinates": [ -2.265373, 51.871123 ] } }
]
};

export default rein_completed;