import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useSelector, useDispatch } from "react-redux";
import { setRoute, setAllowSecSubs } from "../../Redux/reducers/selectedSubstation";
import { links } from "../../Common/MapFilterCard/layer.config";
import {
  appendActiveCard,
  appendNewLayer,
  removeLayer,
  setActiveCard,
} from "../../Redux/reducers/datasetSlice";
import {
  setCategorizedLayers,
  setLayersButton,
} from "../../Redux/reducers/selectedLayers";
import { ToastNotification } from "../../Utils/ToastNotifications";
import { layers } from "../../Common/MapFilterCard/layerCard.config";
import findStyle from "../../Utils/MapColors";
import ConvertToTitleCase from "../../Utils/TitleCaseConvertor";
import { RightSideDot, RightSideLogo } from "../../SVG";
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" color="success" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export const RouteCard = (props) => {
  const dispatch = useDispatch();
  const { text } = props;
  const [costPerMeter, setCostPerMeter] = React.useState(50);
  const [averageScore, setAverageScore] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);

  const capacity = useSelector((state) => state.filters.minCapacity);

  const intersectionData = useSelector(
    (state) => state.selectedSubstation.route
  );

  const categorizedLayers = useSelector(
    (state) => state.selectedLayers.categorizedLayers
  );

  const selectedLayers = useSelector(
    (state) => state.selectedLayers.selectedLayers
  );
  
  const allowSecSubs = useSelector(
    (state) => state.selectedLayers.allowSecSubs
  );

  const { activeCard } = useSelector((state) => state.dataset);
  const substation = useSelector((state) => state.substations);
  const autoLayers = useSelector((state) => state.filters.enableLayers);
  const allLayers = layers.filter((item) => links[item.name]);
  const namesOfSelectedLayers = Object.entries(links)?.map(([key, value]) => {
    if (selectedLayers?.includes(value)) {
      return key;
    }
  });

  const finalSelectedLayers = allLayers.filter((item) =>
    namesOfSelectedLayers.includes(item.name)
  );

  const categories = [
    "ENGLAND",
    "SCOTLAND",
    "WALES",
    "EV",
    "DATA CENTERS",
    "NATIONAL GRID",
    "NATIONAL GAS",
    "MASS PLANNING VIEW",
    "UKPN",
    "SSEN",
    "SPEN",
    "NPG",
    "NGED",
    "ENWL",
    "NGN", // Add other categories if necessary
  ];

  const buttonsArray = [
    { id: "ACTIVE", text: "Active" },
    { id: "ENGLAND", text: "England" },
    { id: "SCOTLAND", text: "Scotland" },
    { id: "WALES", text: "Wales" },
    { id: "EV", text: "EV" },
    { id: "DATA CENTERS", text: "Data Centers" },
    { id: "NATIONAL GRID", text: "National Grid" },
    { id: "NATIONAL GAS", text: "National Gas" },
    { id: "MASS PLANNING VIEW", text: "Mass Planning View" },
    { id: "UKPN", text: "DNO:UKPN" },
    { id: "SSEN", text: "DNO:SSEN" },
    { id: "SPEN", text: "DNO:SPEN" },
    { id: "NPG", text: "DNO:NPG" },
    { id: "NGED", text: "DNO:NGED" },
    { id: "ENWL", text: "DNO:ENWL" },
    { id: "NGN", text: "GDN: NGN" },
  ];

  useEffect(() => {
    // Categorize all layers
    const categorizedLayers = categorizeLayers(
      allLayers,
      categories,
      substation,
      autoLayers
    );
    dispatch(setCategorizedLayers(categorizedLayers));
  }, []);

  useEffect(() => {
    calculateAverage();
  }, [intersectionData]);

  const categorizeLayers = (layers, categories, substation, autoLayers) => {
    const categorizedLayers = {};

    for (const category of categories) {
      categorizedLayers[category] =
        substation.userLocation && autoLayers
          ? finalSelectedLayers.filter((item) => item.category === category)
          : layers.filter((layer) => layer.category === category);
    }
    const filteredLayerButtons = buttonsArray.filter(
      (button) =>
        categorizedLayers[button.id.replace("_", " ")] &&
        categorizedLayers[button.id.replace("_", " ")].length > 0
    );
    const updatedButtonsArray = [
      { id: "ACTIVE", text: "Active" },
      { id: "ALL", text: "All" },
      ...filteredLayerButtons,
    ];
    dispatch(setLayersButton(updatedButtonsArray));

    return categorizedLayers;
  };

  const handleInputChange = (e, index) => {
    const newValue = parseFloat(e.target.valueAsNumber);
    const updatedData = { ...intersectionData };
    const updatedScore = intersectionData?.intersectedLayers.map((layer, i) =>
      i === index ? { ...layer, riskScore: newValue } : layer
    );
    updatedData.intersectedLayers = updatedScore;
    dispatch(setRoute(updatedData));
  };

  const calculateAverage = () => {
    const extra_cost_for_intersected_length =
      intersectionData?.intersectedLayers.reduce(
        (sum, layer) =>
          sum + layer.totalLengthInside * layer.riskScore * costPerMeter,
        0
      );
    const cable_length = intersectionData?.clipedRoute[0]?.clippedLength;
    const nominalcost = cable_length * costPerMeter;
    const estimated_cost = nominalcost + extra_cost_for_intersected_length;
    setAverageScore(estimated_cost);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSecSubCheckboxChange = (value) => {
    dispatch(setAllowSecSubs(value));
  }

  const handleCheckboxChange = (value, layer) => {
    // making checkbox true or false for specific layer
    const updatedLayersWitchCheckedValue = { ...categorizedLayers };
    updatedLayersWitchCheckedValue[layer.category] =
      updatedLayersWitchCheckedValue[layer.category].map((item) => {
        if (item.id === layer.id) {
          return { ...item, checked: value };
        }
        return item;
      });
    dispatch(setCategorizedLayers(updatedLayersWitchCheckedValue));

    const layerName = links[layer.name];
    const updatedLayer = { ...layer, checked: value };
    if (layerName && value) {
      dispatch(appendNewLayer({ layerName: layerName }));
      dispatch(appendActiveCard(updatedLayer));
    } else if (layerName && !value) {
      const newActiveCard = activeCard.filter(
        (card) => !card.name.includes(layer.name)
      );
      if (layerName) {
        dispatch(removeLayer({ layerName: layerName }));
        dispatch(setActiveCard(newActiveCard));
      }
    } else {
      ToastNotification("warn", "Data unavailable");
    }
  };

  return (
    <div className={styles.planningCardz}>
      <Accordion
        expanded={expanded === "panel"}
        onChange={handleChange("panel")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <RightSideLogo />
            <div>
              <p className={styles.planningCardz__heading}>{text}</p>
              <p className={styles.planningCardz__heading__sub}>
                {intersectionData?.intersectedLayers.length > 1
                  ? `${intersectionData?.intersectedLayers.length} Intersecting Layers`
                  : intersectionData?.intersectedLayers.length === 1
                  ? "1 Intersecting Layer"
                  : "No Layer Intersects"}
              </p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.planningCardz__route}>
            <div>
              {/* <h1 className={styles.planningCardz__alert__heading}>
                Route Analysis
              </h1> */}
              {capacity < 12 && !allowSecSubs && (
                <>
                  <input 
                    type="checkbox" 
                    id="secSubs" 
                    name="secSubs" 
                    value={allowSecSubs}
                    onChange={
                      (e) => handleSecSubCheckboxChange(e.target.checked)
                    } 
                  />
                  <label for="secSubs" style={{fontSize: 15}}> Allow re-routing to secondary substations</label>
                </>
              )}
              {intersectionData?.clipedRoute[0]?.connectingLayer === null && (
                <p className={styles.planningCardz__route__para}>
                  There is no existing infrastructure for this route.
                </p>
              )}
              {intersectionData?.clipedRoute[0]?.connectingLayer !== null &&
                intersectionData?.clipedRoute.map((lyr) => {
                  let intersectingayerName;
                  if (lyr.connectingLayer === 'npg_low_voltage_overhead_feeders') {
                    intersectingayerName = "NPG_Low_Voltage_Feeders"
                  } else {
                    intersectingayerName = Object.keys(links).find(
                      (key) => links[key] === lyr.connectingLayer
                    );
                  }
                  const layerObj = layers.filter(
                    (item) => item.name === intersectingayerName
                  );
                  const match = activeCard?.filter(
                    (data) => data?.name === intersectingayerName
                  );
                  const checked = match?.length > 0 ? match[0].checked : false;
                  const color = findStyle(lyr?.connectingLayer);

                  return (
                    <>
                      <p className={styles.planningCardz__alert__para}>
                        This cable can connect to closer existing
                        infrastructure. The closest cable is:
                      </p>

                      <div
                        className={styles.planningCardz__route__card}
                        style={{ justifyContent: "space-between" }}
                      >
                        {/* <div className={styles.planningCardz__route__card__dot} style={{ background: color }}></div> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <RightSideDot fill={color} />
                          <div
                            className={
                              styles.planningCardz__route__card__headingContainer
                            }
                          >
                            <h3>{ConvertToTitleCase(intersectingayerName)}</h3>
                          </div>
                        </div>
                        {checked ? (
                          <VisibilityOutlinedIcon
                            fontSize="small"
                            color="success"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCheckboxChange(!checked, layerObj[0]);
                            }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            fontSize="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCheckboxChange(!checked, layerObj[0]);
                            }}
                          />
                        )}
                      </div>

                      {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
                        <label>{intersectingayerName}</label>
                        {checked ?
                          <VisibilityOutlinedIcon fontSize="small" color="success"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCheckboxChange(!checked, layerObj[0])
                            }}
                          /> :
                          <VisibilityOffOutlinedIcon fontSize="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCheckboxChange(!checked, layerObj[0])
                            }}
                          />
                        }
                      </div> */}
                    </>
                  );
                })}
              <p className={styles.planningCardz__route__para1}>
                Cable Total Length:{" "}
                <span>
                  {intersectionData?.clipedRoute[0]?.clippedLength.toFixed(2)} m
                </span>
              </p>
              {intersectionData?.intersectedLayers.length > 0 && (
                <>
                  {intersectionData?.intersectedLayers.map((layer, index) => {
                    const layerName = Object.keys(links).find(
                      (key) => links[key] === layer.layerName
                    );
                    const layerObj = layers.filter(
                      (item) => item.name === layerName
                    );
                    const match = activeCard?.filter(
                      (data) => data?.name === layerName
                    );
                    const checked =
                      match?.length > 0 ? match[0].checked : false;
                    const color = findStyle(layer?.layerName);
                    return (
                      <div
                        key={index}
                        className={styles.planningCardz__route__card}
                      >
                        {/* <div className={styles.planningCardz__route__card__dot} style={{ background: color }}></div> */}
                        <RightSideDot fill={color} />
                        <div
                          className={
                            styles.planningCardz__route__card__headingContainer
                          }
                        >
                          <h3>{ConvertToTitleCase(layerName)}</h3>
                          <p>{layer.totalLengthInside.toFixed(2)} m</p>
                        </div>
                        <input
                          className={styles.planningCardz__route__card__value}
                          type="number"
                          min={1.0}
                          max={2.0}
                          value={layer?.riskScore}
                          onChange={(e) => handleInputChange(e, index)}
                          step="0.01"
                        />

                        {checked ? (
                          <VisibilityOutlinedIcon
                            fontSize="small"
                            color="success"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCheckboxChange(!checked, layerObj[0]);
                            }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            fontSize="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCheckboxChange(!checked, layerObj[0]);
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </>
              )}

              {/* {intersectionData?.intersectedLayers.length > 0 && (
                <table className={styles.styledRouteTable}>
                  <thead>
                    <tr>
                      <th>Geospatial Layer</th>
                      <th>Length (meters)</th>
                      <th>De-Rating Factor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {intersectionData?.intersectedLayers.map((layer, index) => {
                      const layerName = Object.keys(links).find(key => links[key] === layer.layerName);
                      const layerObj = layers.filter((item) => item.name === layerName);
                      const match = activeCard?.filter(
                        (data) => data?.name === layerName
                      );
                      const checked = match?.length > 0 ? match[0].checked : false;

                      console.log("layer", layer)
                      return (
                        <tr key={index}>

                          <td>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <label>
                                {(layerName)}
                              </label>
                              {checked ?
                                <VisibilityOutlinedIcon fontSize="small" color="success"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCheckboxChange(!checked, layerObj[0])
                                  }}
                                /> :
                                <VisibilityOffOutlinedIcon fontSize="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCheckboxChange(!checked, layerObj[0])
                                  }}
                                />
                              }
                            </div>
                          </td>
                          <td>{layer.totalLengthInside.toFixed(2)}</td>
                          <td>
                            <input
                              type="number"
                              min={1.0}
                              max={2.0}
                              value={layer?.riskScore}
                              onChange={(e) => handleInputChange(e, index)}
                              step="0.01"
                              style={{
                                border: 'none',
                                textAlign: 'left'
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )} */}

              <div className={styles.planningCardz__route__costContainer}>
                <h3>Cost per meter (£):</h3>
                <input
                  className={
                    styles.planningCardz__route__costContainer__finalCost
                  }
                  type="number"
                  value={costPerMeter}
                  onChange={(e) => setCostPerMeter(e.target.value)}
                  step="1"
                />
              </div>
              <div className={styles.save_project} onClick={calculateAverage}>
                <p>Calculate</p>
              </div>
              {/* <div>Total Cost Estimation: £ {averageScore.toFixed(2)}</div> */}
              <div className={styles.planningCardz__route__totalCost}>
                <h4>
                  Total Cost Estimation:{" "}
                  <span>
                    £{" "}
                    {averageScore.toLocaleString("en-GB", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
