const colors = {
  "habitat_inventory_wales": {
    fillOpacity: 0.5,
    fillColor: "#fdd835",
    color: "#cce2d8",
  },
  "habitat_inventory_scotland": {
    fillOpacity: 0.5,
    fillColor: "#fdd835",
    color: "#cce2d8",
  },
  "habitat_inventory_england": {
    fillOpacity: 0.5,
    fillColor: "#fdd835",
    color: "#cce2d8",
  },
  "ancient_woodland": {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#cce2d8",
    strokeColor: "#00703c",
    color: "#cce2d8",
    opacity: 0.2,
  },
  "area-of-outstanding-natural-beauty": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#e688b3",
    strokeColor: "#d94c8d",
    color: "#e688b3",
    opacity: 0.2,
  },
  "article-4-direction-area": {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#6683ae",
    strokeColor: "#003078",
    color: "#6683ae",
    opacity: 0.2,
  },
  "brownfield-land": {
    fillOpacity: 0.25,
    strokeOpacity: 0.2,
    fillColor: "#ac9a7f",
    strokeColor: "#745729",
    color: "#ac9a7f",
    opacity: 0.8,
  },
  "brownfield-site": {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#ac9a7f",
    strokeColor: "#745729",
    color: "#ac9a7f",
    opacity: 0.2,
  },
  "building-preservation-notice": {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#fb8fdd",
    strokeColor: "#f944c7",
    color: "#fb8fdd",
    opacity: 0.2,
  },
  "central-activities-zone": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#6683ae",
    strokeColor: "#003078",
    color: "#6683ae",
    opacity: 0.2,
  },
  "certificate-of-immunity": {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#e8ad6e",
    strokeColor: "#d8760d",
    color: "#e8ad6e",
    opacity: 0.8,
  },
  "civil-parish": {
    fillOpacity: 0.25,
    strokeOpacity: 0.2,
    fillColor: "#9abfdf",
    strokeColor: "#5694ca",
    color: "#9abfdf",
    opacity: 0.2,
  },
  "conservation-area": {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#aecc66",
    strokeColor: "#78aa00",
    color: "#aecc66",
    opacity: 0.2,
  },
  "design-code-area": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#6683ae",
    strokeColor: "#003078",
    color: "#6683ae",
    opacity: 0.2,
  },
  "flood-areas": {
    fillOpacity: 0.25,
    strokeOpacity: 0.2,
    fillColor: "#6683ae",
    strokeColor: "#003078",
    color: "#6683ae",
    opacity: 0.2,
  },
  "green_belt": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#b6c293",
    strokeColor: "#85994b",
    color: "#b6c293",
    opacity: 0.2,
  },
  "heritage-at-risk": {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#bbabcf",
    strokeColor: "#8d73af",
    color: "#bbabcf",
    opacity: 0.2,
  },
  "heritage-coast": {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#bd80b8",
    strokeColor: "#93308b",
    color: "#bd80b8",
    opacity: 0.2,
  },
  "park-and-garden": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#6ed597",
    strokeColor: "#0eb951",
    color: "#6ed597",
    opacity: 0.2,
  },
  "listed-building-outline": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#fbdd8f",
    strokeColor: "#f9c744",
    color: "#fbdd8f",
    opacity: 0.8,
  },
  "local-authority-district": {
    fillOpacity: 0.25,
    strokeOpacity: 0.2,
    fillColor: "#e7e7e7",
    strokeColor: "#0b0c0c",
    color: "#e7e7e7",
    opacity: 0.2,
  },
  "local-planning-authority": {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#6683ae",
    strokeColor: "#4c6ea0",
    color: "#6683ae",
    opacity: 0.2,
  },
  "locally-listed-building": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#fbdd8f",
    strokeColor: "#f9c744",
    color: "#fbdd8f",
    opacity: 0.2,
  },
  "national-park": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#8bc980",
    strokeColor: "#5bb34d",
    color: "#8bc980",
    opacity: 0.2,
  },
  "protected-wreck-site": {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#6d6d6d",
    strokeColor: "#0b0c0c",
    color: "#6d6d6d",
    opacity: 0.2,
  },
  "ramsar-site": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#b2e1ff",
    strokeColor: "#7fcdff",
    color: "#b2e1ff",
    opacity: 0.2,
  },
  region: {
    fillOpacity: 0.25,
    strokeOpacity: 0.2,
    fillColor: "#6683ae",
    strokeColor: "#40649a",
    color: "#6683ae",
    opacity: 0.2,
  },
  "registered-battlefields": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#dbd4d9",
    strokeColor: "#4d2942",
    color: "#dbd4d9",
    opacity: 0.2,
  },
  "Registered_Parks_and_Gardens": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#008000",
    strokeColor: "#008000",
    color: "#008000",
    opacity: 0.2,
  },
  "scheduled-monument": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#6fc4e9",
    strokeColor: "#0f9cda",
    color: "#6fc4e9",
    opacity: 0.2,
  },
  "site-of-special-scientific-interest": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#83bccd",
    strokeColor: "#308fac",
    color: "#83bccd",
    opacity: 0.2,
  },
  "special-area-of-conservation": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#afb769",
    strokeColor: "#afb769",
    color: "#afb769",
    opacity: 0.2,
  },
  "special-protection-area": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#6683ae",
    strokeColor: "#7a8705",
    color: "#6683ae",
    opacity: 0.2,
  },
  tree: {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#898f8b",
    strokeColor: "#585c59",
    color: "#898f8b",
    opacity: 0.8,
  },
  "tree-preservation-zone": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#6683ae",
    strokeColor: "#003078",
    color: "#6683ae",
    opacity: 0.2,
  },
  "world-heritage-site": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#f378ef",
    strokeColor: "#eb1ee5",
    color: "#f378ef",
    opacity: 0.2,
  },
  "world-heritage-site-buffer-zone": {
    fillOpacity: 0.5,
    strokeOpacity: 0.2,
    fillColor: "#f378ef",
    strokeColor: "#eb1ee5",
    color: "#f378ef",
    opacity: 0.2,
  },
  clearview: {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#E77148",
    strokeColor: "#E77148",
    color: "#E77148",
    opacity: 0.75,
  },
  ncpr: {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#E77148",
    strokeColor: "#E77148",
    color: "#E77148",
    opacity: 0.75,
  },
  "agriculture-land-classification2": {
    fillOpacity: 0.75,
    strokeOpacity: 0.2,
    fillColor: "#E77148",
    strokeColor: "#E77148",
    color: "#E77148",
    opacity: 0.75,
  },

  "Advanced-conversion-technologies": {
    fillColor: '#b61d14'
  },
  "anaerobic-digestion": {
    fillColor: '#421986'
  },
  "battery": {
    fillColor: '#9776cf'
  },
  "biomass-cofiring": {
    fillColor: '#65b5f4'
  },
  "biomass-dedicated": {
    fillColor: '#00569b'
  },
  "compressed-air-energy-storage": {
    fillColor: '#4ECEE3'
  },
  "efw-incineration": {
    fillColor: '#004c3f'
  },
  "flywheels": {
    fillColor: '#83c586'
  },
  "fuel-cell-hydrogen": {
    fillColor: '#32691e'
  },
  "geothermal": {
    fillColor: '#040200'
  },
  "hot-dry-rocks": {
    fillColor: '#d55802'
  },
  "hydrogen": {
    fillColor: '#070000'
  },
  "landfill-gas": {
    fillColor: '#ff837f'
  },
  "large-hydro": {
    fillColor: '#3e2425'
  },
  "liquid-air-energy-storage": {
    fillColor: '#636700'
  },
  "pumped-storage-hydroelectricity": {
    fillColor: '#04fed1'
  },
  "sewage-sludge-digestion": {
    fillColor: '#281ef8'
  },
  "shoreline-wave": {
    fillColor: '#e502f9'
  },
  "small-hydro": {
    fillColor: '#7f0e69'
  },
  "solar-photovoltaics": {
    fillColor: '#f5c25d'
  },
  "tidal-lagoon": {
    fillColor: '#070000'
  },
  "tidal-stream": {
    fillColor: '#070000'
  },
  "wind-offshore": {
    fillColor: '#6670d2'
  },
  "wind-onshore": {
    fillColor: '#456783'
  },
  "abandoned-renewable": {
    fillColor: "#b61d14"
  },
  "apeal-lodged": {
    fillColor: "#e502f9"
  },
  "apeal-refused": {
    fillColor: "#421986"
  },
  "apeal-withdrawn": {
    fillColor: "#7f0e69"
  },
  "application-expired": {
    fillColor: "#ff837f"
  },
  "application-refused": {
    fillColor: "red"
  },
  "application-submitted": {
    fillColor: "#65b5f4"
  },
  "application-withdrawn": {
    fillColor: "#9776cf"
  },
  "awaiting-construction": {
    fillColor: "#83c586"
  },
  "decommissioned": {
    fillColor: "#3e2425"
  },
  "finished": {
    fillColor: "#32691e"
  },
  "no-application-required": {
    fillColor: "#040200"
  },
  "operational": {
    fillColor: "#004c3f"
  },
  "planning-permission-expired": {
    fillColor: "#d55802"
  },
  "revised": {
    fillColor: "#4ECEE3"
  },
  "under-construction": {
    fillColor: "#04fed1"
  },
  "flood_risk_zone": {
    fillColor: "#6683ae"
  },
  "dmw-floodriskfromrivers": {
    fillColor: "#6683ae"
  },
  "nps": {
    fillColor: "#ffcc80"
  },
  "private_land": {
    fillColor: "#81c784"
  },
  "ccod_nps": {
    fillColor: "#e83313"
  },
  "ocod_nps": {
    fillColor: "#e83313"
  },
  "dmw-seafloodriskfromsea": {
    fillColor: "#6683ae"
  },
  "SP_Manweb_Grid_Polygons": {
    fillColor: "#dba3af"
  },
  "SP_Manweb_Primary_Polygons": {
    fillColor: "#ccb545"
  },
  "SP_Distribution_Primary_Polygons": {
    fillColor: "#96b772"
  },
  "ngn_central_mains_and_pipelines": {
    fillColor: "#e8718d"
  },
  "ngn_the_north_mains_and_pipelines": {
    fillColor: "#c43c39"
  },
  "ngn_the_ridings_mains_and_pipelines": {
    fillColor: "#85b66f"
  },
  "ngn_west_yorkshire_mains_and_pipelines": {
    fillColor: "#cf36b8"
  },
  "ngn_tees_mains_and_pipelines": {
    fillColor: "#987db7"
  },
  "national-grid-overheadlines": {
    fillColor: "#0000FF"
  },
  "npg-66kv-overhead-new": {
    fillColor: "#b59f10"
  },
  "nged-11kv-overheadlines-new" : {
    fillColor: "#6e97b8"
  }
};

const findStyle = (name) => {
  const layerNames = Object.keys(colors);
  if (name && layerNames.includes(name)) {
    return colors[name].fillColor;
  }
};
export default findStyle;
