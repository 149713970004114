import React from "react";
import Styles from "./styles.module.scss";
import { NavLink, useLocation } from "react-router-dom";

const MapNavBar = () => {
  const location = useLocation();

  return (
    <div id={Styles.mapBar}>
      {/* <div className={Styles.item}>
        <NavLink
          to="/llm"
          className={
            location.pathname === "/ai" ||
            location.pathname === "/order2" ||
            location.pathname === "/llm" ||
            location.pathname === "/llm-thread" ||
            location.pathname === "/llm-threadlist" ||
            location.pathname === "/llm-tender" ||
            location.pathname === "/llm-tender-thread" ||
            location.pathname === "/llm-tender-threadlist" ||
            location.pathname === "/llm-tender-textEditor" ||
            location.pathname === "/llm-1" ||
            location.pathname === "/llm-2" ||
            location.pathname === "/llm-tabular" ||
            location.pathname === "/llm-chat"
              ? Styles.activeLink
              : ""
          }
        >
     
          InfraChat
        </NavLink>
      </div> */}
      <div className={Styles.item}>
        <NavLink
          to="/"
          className={location.pathname === "/" ? Styles.activeLink : ""}
        >
          Search
        </NavLink>
      </div>
      <div className={Styles.item}>
        <NavLink
          to="/projects"
          className={(location.pathname.startsWith("/project/") || location.pathname === "/projects" || location.pathname === "/shared-projects" || location.pathname === "/deleted-projects" || location.pathname === "/bulk-import") ? Styles.activeLink : ""}
        >
          Projects
        </NavLink>
      </div>
      {/* <div className={Styles.item}>
        <NavLink
          to="/collaboration"
          className={location.pathname === "/collaboration" ? Styles.activeLink : ""}
        >
          Collaboration
        </NavLink>
      </div> */}
    </div>
  );
};

export default MapNavBar;
