import React from "react";
import styles from "./styles.module.scss";
import { fixed } from "../../Utils/Formats";
import {
  ArrowDropUp,
  // GreenArrow 
} from "../../SVG";

const Generation = (props) => {
  const { heading, mw, dif } = props;
  return (
    <div className={styles.generation}>
      <p className={styles.generation__heading}>{heading}</p>
      <div className={styles.generation__container}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p className={styles.generation__container__mw}>
            {mw}
          </p>
          <ArrowDropUp fill={dif >= 0 ? "#0AED24" : "red"} rotate={dif >= 0 ? 0 : 180} />
          {/* <GreenArrow /> */}
        </div>
        <p className={styles.generation__container__value}
          style={{
            color: dif >= 0 ? "#0AED24" : "red",
          }}>
          {dif > 0
            ? `${fixed(dif, 3)} MW Extra capacity`
            : `${fixed(dif, 3)} MW required capacity`}
        </p>
      </div>
    </div>
  );
};

export default Generation;
