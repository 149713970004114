import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import Menu from "@mui/material/Menu";
import { Bell, Cancell, LineFilter, Tick } from "../SVG";
import { Avatar } from "@mui/material";
import Button from "../Common/Button/Button";
import { getAllNotifications, acceptOrRejectInvitation, readSingleNotification, readAllNotification } from "../Api/API";
import { timeAgoFind } from "../Utils/Formats";
import ChatDeleteNotification from "../Modals/ChatDeleteNotification";
import { ToastNotification } from "../Utils/ToastNotifications";
import { useDispatch, useSelector } from "react-redux";
import { setFetchUserGroups, setNewNotification } from "../Redux/reducers/chatSlice";
import Fade from '@mui/material/Fade';


const Notifications = () => {
  const [selectedButton, setSelectedButton] = useState("Unread");
  const [unreadMessage, setUnreadMessage] = useState(null);
  const [readMessage, setReadMessage] = useState(null);
  const [notifications, setNotifications] = useState();
  const [updateNotifications, setUpdateNotifications] = useState();
  const [deleteNotification, setDeleteNotification] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const open = Boolean(openMenu);
  const { userDetail } = useSelector((state) => state.users);
  const { newNotification } = useSelector( (state) => state.chat);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
    dispatch(setNewNotification(false));
    fetchAllNotifications()
  };
  const handleClose = () => {
    setOpenMenu(null);
  };

  const fetchAllNotifications = async () => {
    try {
      const response = await getAllNotifications();
      console.log("user notifications", response.data);
      setUpdateNotifications()
      const notifications = response.data;
      const unread = notifications?.filter((item) =>
        item?.read === false
      )
      setUnreadMessage(unread?.length)
      setReadMessage(notifications?.length - unread?.length)
      setNotifications(notifications);
    } catch (error) {
      console.error("Error fetching user notifications:", error);
    }
  };

  // useEffect(() => {
  //   fetchAllNotifications()
  // }, [])

  useEffect(() => {
    if (notifications?.length > 0) {
      if (selectedButton === "Unread") {
        const filteredNotification = notifications?.filter((item) =>
          item?.read === false
        );
        setUpdateNotifications(filteredNotification)
      }
      if (selectedButton === "All") {
        const filteredNotification = notifications?.filter((item) =>
          item?.read === true
        );
        setUpdateNotifications(filteredNotification)
      }
    }
  }, [notifications, selectedButton])

  const handleInvitationResponse = (accept, id, groupId) => {
    let body = {
      groupId: groupId,
      userId: userDetail?.user?.id,
      accepted: accept
    }
    acceptOrRejectInvitation(body).then((res) => {
      if (res?.success) {
        readSingleNotification(id).then((res) => {

        });
        fetchAllNotifications();
        dispatch(setFetchUserGroups(true))
        handleClose();
        ToastNotification("success", res?.message);
      }
    });
  }

  const handleReadAllNotification = () => {
    readAllNotification().then((res) => {
      if (res?.success) {
        fetchAllNotifications();
        handleClose();
        ToastNotification("success", res?.message);
      }
    })
  }

  return (
    <div>
      <div className={styles.dotsContainer} onClick={handleClick}>
        <Bell notifcation={newNotification} />
      </div>

      <Menu
        id="basic-menu"
        anchorEl={openMenu}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionComponent={Fade}
        sx={{
          "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
            gap: 1,
            alignItems: "start",
          },
        }}
      >
        <div className={styles.mainContainer}>
          <div className={styles.heading}>
            <h1> Notifications</h1>
              <Button
                text="Mark all as read"
                height="26px"
                radius="6px"
                bgColor="#fff"
                color="#0E0F0D"
                borderColor="#E2E2E2"
                fontSize="10px"
                disable={unreadMessage > 0 ? false : true}
                handleClick={() => handleReadAllNotification()}
              />
          </div>
          <div className={styles.divider} />
          <div className={styles.filter}>
            <div className={styles.messageCount}>
              <div className={styles.unreadCount}>
                <Button
                  height='23px'
                  bgColor={selectedButton === "Unread" ? '#F3F4F6' : '#fff'}
                  color={selectedButton === "Unread" ? '#1F443A' : '#1E232E'}
                  radius='100px'
                  borderColor={selectedButton === "Unread" ? '#F3F4F6' : '#DCDFE5'}
                  text="Unread"
                  length={unreadMessage}
                  lengthSize="8px"
                  lengthArea="14px"
                  handleClick={() => setSelectedButton("Unread")}
                />
              </div>
              <div className={styles.allCount}>
                <Button
                  height='23px'
                  bgColor={selectedButton === "All" ? '#F3F4F6' : '#fff'}
                  color={selectedButton === "All" ? '#1F443A' : '#1E232E'}
                  radius='100px'
                  borderColor={selectedButton === "All" ? '#F3F4F6' : '#DCDFE5'}
                  text="All"
                  length={readMessage}
                  lengthSize="8px"
                  lengthArea="14px"
                  handleClick={() => setSelectedButton("All")}

                />
              </div>
            </div>
            <LineFilter />
          </div>
          <div className={styles.divider} />
          {updateNotifications?.length > 0 ? (
            <div className={styles.notification}>
              {updateNotifications?.map((item, index) => {
                return (
                  <div key={index}>
                    <div className={styles.profileContainer}>
                      <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                      <div className={styles.profile}>
                        <div className={styles.name}>
                          <h1>{item.message}</h1>
                          <div onClick={() => {
                            setDeleteNotification(true);
                            setSelectedId(item?.id);
                            handleClose()
                          }}>
                            <Cancell />
                          </div>
                        </div>
                        <div className={styles.message}>
                          <h1> Groups</h1>
                          <p>{timeAgoFind(item?.createdAt)}</p>
                          {/* <p>5 mins ago</p> */}
                        </div>
                      </div>
                    </div>
                    {item?.status === "REQUESTED" && (
                      <div className={styles.offerbuttons}>
                        <Button
                          text="Accept"
                          icon={<Tick />}
                          height="26px"
                          radius="6px"
                          bgColor="#1F443A"
                          color="#fff"
                          borderColor="#1F443A"
                          handleClick={() => {
                            handleInvitationResponse(true, item?.id, item?.groupId);
                          }
                          }
                        />
                        <Button
                          text="Decline"
                          icon={<Cancell />}
                          height="26px"
                          radius="6px"
                          bgColor="#fff"
                          color="#1E232E"
                          borderColor="#1e232e33"
                          handleClick={() => {
                            handleInvitationResponse(false, item?.id, item?.groupId);
                          }
                          }
                        />
                      </div>
                    )}
                    {updateNotifications?.length > 0 && index !== (updateNotifications?.length - 1) && (
                      <div className={styles.divider} />
                    )}
                  </div>

                )
              })}

            </div>
          ) : (
            <p className={styles.noNotifcation}>
              There are no notification to show
            </p>
          )}
        </div>
      </Menu>

      <ChatDeleteNotification
        open={deleteNotification}
        setOpen={setDeleteNotification}
        id={selectedId}
        fetchAllNotifications={fetchAllNotifications}
      />
    </div>
  );
};

export default Notifications;
