import React, { useState } from "react";
import {
  power_plant_coal,
  power_plant_battery,
  power_plant_biomass,
  power_plant_geothermal,
  power_plant_hydro,
  power_plant_nuclear,
  power_plant_wind,
  power_plant_solar,
  power_plant_oilgas,
} from "../SVG";
import "./myMap.css";
import { List, ListItem } from "@mui/material";
const powerPlantOptions = [
  {
    name: "Coal",
    image: power_plant_coal,
  },
  {
    name: "Battery",
    image: power_plant_battery,
  },

  {
    name: "Biomass",
    image: power_plant_biomass,
  },

  {
    name: "Geothermal",
    image: power_plant_geothermal,
  },
  {
    name: "Hydro",
    image: power_plant_hydro,
  },
  {
    name: "Nuclear",
    image: power_plant_nuclear,
  },
  {
    name: "Oil",
    image: power_plant_oilgas,
  },
  {
    name: "Gas",
    image: power_plant_oilgas,
  },
  {
    name: "Diesel",
    image: power_plant_oilgas,
  },
  {
    name: "Wind",
    image: power_plant_wind,
  },
  {
    name: "Solar",
    image: power_plant_solar,
  },
];

const PowerPlantLegend = () => {

  return (
    <div className="legend-main">
      {powerPlantOptions.map((option, index) => {
        const PlantImage = option.image;
        return (
          <div
            key={option.name}
            style={{
              // width: "286px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <div>{option.name}</div>
            <div style={{ justifyContent: "flex", marginRight: "5px" }}>
              <PlantImage />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PowerPlantLegend;
