import { createSlice } from "@reduxjs/toolkit";
const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    collections: [],
    selectedCollection: undefined,
    projects: [],
    mapImage: null,
    takeImage: false,
    rightSidebarOpen: false,
    rightSidebarType: "search",
    leftSidebarOpen: true,
    lpSidebarOpen: false,
    projectsLoading: false,
    fetchProjects: null,
    selectedPrivateWireLocation: undefined,
    selectedSecondaryAquisitionLocation: undefined,
    transmission: "Transformer Analysis",
    promethousModal: false,
    privateWireButtonText:"Accepted To Connect"
  },
  reducers: {
    setCollections: (state, action) => {
      return { ...state, collections: action.payload };
    },
    setSelectedCollections: (state, action) => {
      return { ...state, selectedCollection: action.payload };
    },
    setProjects: (state, action) => {
      return { ...state, projects: action.payload };
    },
    setMapImage: (state, action) => {
      return { ...state, mapImage: action.payload };
    },
    setTakeImage: (state, action) => {
      return { ...state, takeImage: action.payload };
    },
    setRightSideBarOpen: (state, action) => {
      return { ...state, rightSidebarOpen: action.payload };
    },
    setRightSideBarType: (state, action) => {
      return { ...state, rightSidebarType: action.payload };
    },
    setLeftSideBarOpen: (state, action) => {
      return { ...state, leftSidebarOpen: action.payload };
    },
    setLpSideBarOpen: (state, action) => {
      return { ...state, lpSidebarOpen: action.payload };
    },
    setProjectsLoading: (state, action) => {
      return { ...state, projectsLoading: action.payload };
    },
    setFetchProjects: (state, action) => {
      return { ...state, fetchProjects: action.payload };
    },
    setSelectedPrivateWireLocation: (state, action) => {
      return { ...state, selectedPrivateWireLocation: action.payload };
    },
    setSelectedSecondaryAquisitionLocation: (state, action) => {
      return { ...state, selectedSecondaryAquisitionLocation: action.payload };
    },    
    setTransmission: (state, action) => {
      return { ...state, transmission: action.payload };
    },
    setPromethousModal: (state, action) => {
      return { ...state, promethousModal: action.payload };
    },
    setButtonText: (state, action) => {
      return { ...state, privateWireButtonText: action.payload };
    },
  },
});
export const {
  setCollections,
  setSelectedCollections,
  setProjects,
  setMapImage,
  setTakeImage,
  setRightSideBarOpen,
  setProjectsAlphabeticSorting,
  setProjectAssetTypeSorting,
  setProjectsLoading,
  setLeftSideBarOpen,
  setLpSideBarOpen,
  setRightSideBarType,
  setFetchProjects,
  setSelectedPrivateWireLocation,
  setSelectedSecondaryAquisitionLocation,
  setTransmission,
  setPromethousModal,
  setButtonText
} = projectsSlice.actions;
export default projectsSlice.reducer;
