import React, { useState } from "react";

import styles from "./LayerSideBar.module.css";
import { links } from "../../../Common/MapFilterCard/layer.config";
import { useSelector, useDispatch } from "react-redux";
import {
  appendActiveCard,
  appendNewLayer,
  removeLayer,
  setActiveCard,
  setRenewableActiveLayersArray,
} from "../../../Redux/reducers/datasetSlice";
import { setCategorizedLayers } from "../../../Redux/reducers/selectedLayers";
import { ToastNotification } from "../../../Utils/ToastNotifications";

const LayerCard = (props) => {
  // const { filteredLayers, setCategorizedLayers, categorizedLayers } = props;

  // const [activeLayersData, setActiveLayersData] = useState([]);

  const { activeLayers, activeCard } = useSelector((state) => state.dataset);
  
  const categorizedLayers = useSelector(
    (state) => state.selectedLayers.categorizedLayers
  );

  const filteredLayers = useSelector(
    (state) => state.selectedLayers.filteredLayers
  );

  const dispatch = useDispatch();

  const roadSpeedLayers = [
    "roadspeed_indicative",
    "roadspeed_avgday",
    "roadspeed_avgnight",
  ];

  const handleCheckboxChange = (value, layer) => {
    // making checkbox true or false for specific layer
    console.log("Layer Data is  : ", value, layer);
    const updatedLayersWitchCheckedValue = { ...categorizedLayers };
    updatedLayersWitchCheckedValue[layer.category] =
      updatedLayersWitchCheckedValue[layer.category].map((item) => {
        if (item.id === layer.id) {
          return { ...item, checked: value };
        }
        return item;
      });
    dispatch(setCategorizedLayers(updatedLayersWitchCheckedValue));

    const layerName = links[layer.name];
    console.log("Clicked left: ", layerName, layer);

    //Doing Checked:True for Active Layer in AppendActiveCard redux
    const updatedLayer = { ...layer, checked: value };

    // Handling the selected and unselected layers case over the map
    if (layerName && value) {
      dispatch(appendNewLayer({ layerName: layerName }));
      dispatch(appendActiveCard(updatedLayer));
    } else if (layerName && !value) {
      let matchingLayer =
        layerName === "roadspeed_indicative"
          ? roadSpeedLayers.find((layer) => activeLayers.includes(layer))
          : null;
      if (matchingLayer) {
        layerName = matchingLayer;
      }
      const newActiveCard = activeCard.filter(
        (card) => !card.name.includes(layer.name)
      );
      if (layerName) {
        dispatch(removeLayer({ layerName: layerName }));
        dispatch(setActiveCard(newActiveCard));
        // setActiveLayersData(newActiveCard);
        // setCallUpdatedLayers(true);
        if (layerName === "tidal-lagoon-renewable") {
          dispatch(
            setRenewableActiveLayersArray({ activeRenewableLayers: [] })
          );
        }
      }
    } else {
      ToastNotification("warn", "Data unavailable");
    }
  };

  return (
    <div>
      <section className={styles.areaDropdown}>
        {Object?.entries(filteredLayers)?.map(([category, items]) => {
          // console.log("Layers Data is  : ", filteredLayers, category, items);
          return (
            <div key={category}>
              {/* <p className={styles.england}>{firstLetterCapital(category)}</p> */}
              {items?.length > 0 && (
                <p className={styles.england}>{category}</p>
              )}

              <div className={styles.greenbeltOptions}>
                {items?.map((item, key) => {
                  const match = activeCard?.filter(
                    (data) => data?.title === item?.title
                  );
                  const checked = match?.length > 0 ? match[0].checked : false;

                  return (
                    <div className={styles.greenbeltToggles} key={key}>
                      <div className={styles.greenbeltRow}>
                        <img
                          className={styles.greenBletIcon}
                          loading="lazy"
                          alt=""
                          src={item?.imageLink}
                        />
                        <div className={styles.activeLayersLabel}>
                          <div className={styles.areaOfOutstanding}>
                            {item.title}
                          </div>
                        </div>
                      </div>

                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={(e) =>
                          handleCheckboxChange(e.target?.checked, item)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
};
export default LayerCard;
