import React, { useState, useEffect, useRef } from 'react';
import { useControl } from "react-map-gl";
import "./myMap.css";


const ReinforcementControl = ({ setShowHeatmap, showHeatmap, setShowCompleted, showCompleted, showProgressing, setShowProgressing }) => {
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);

    const handleCheckboxChange = (setter) => () => {
        setter(prev => !prev);
    };

    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const control = {
        onAdd(map) {
            this._map = map;
            this._container = document.createElement("div");
            this._container.className = "mapboxgl-ctrl";
            const button = document.createElement("button");
            button.classList.add("npsButton");
            button.style.left = '710px';
            button.textContent = "Reinforcement";

            button.onclick = () => {
                setShowModal((prev)=> !prev);
            };

            this._container.appendChild(button);
            return this._container;
        },
        onRemove() {
            this._container.parentNode.removeChild(this._container);
            this._map = undefined;
        },
    };

    useControl(() => control);

    return (
        <div ref={modalRef}>
            {showModal && (
                <div className="reinforceModal">
                    <div className="modalContent">
                        <label>
                            <input type="checkbox" checked={showHeatmap} onChange={handleCheckboxChange(setShowHeatmap)} />
                            Heatmap
                        </label>
                        <label>
                            <input type="checkbox" checked={showProgressing} onChange={handleCheckboxChange(setShowProgressing)} />
                            Progressing
                        </label>
                        <label>
                            <input type="checkbox" checked={showCompleted} onChange={handleCheckboxChange(setShowCompleted)} />
                            Completed
                        </label>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReinforcementControl;
