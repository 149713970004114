import { createSlice } from "@reduxjs/toolkit";
const wireSchemeSlice = createSlice({
  name: "scheme",
  initialState: {
    privateWireButtonText:"Accepted To Connect",
    isLoading:false,
  },
  reducers: {
    setButtonText: (state, action) => {
      return { ...state, privateWireButtonText: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
  },
});
export const {
  setButtonText,
  setLoading,
} = wireSchemeSlice.actions;
export default wireSchemeSlice.reducer;
