import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Switch } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import './myMap.css';
import { appendNewLayer, removeLayer } from "../Redux/reducers/datasetSlice";
import { ToastNotification } from "../Utils/ToastNotifications";

const LargeProjectLayerControl = ({ mapZoom, viewLarge, setViewLarge, setLargeProjects }) => {
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const activeLayers = useSelector((state) => state.dataset.activeLayers);
    const CustomColorSwitch = styled(Switch)(({ theme }) => ({
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: "#13D92A",
            "&:hover": {
                backgroundColor: alpha("#13D92A", theme.palette.action.hoverOpacity),
            },
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#13D92A",
        },
    }));

    // Custom styled FormControlLabel with background
    const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
        width: "160px",
        height: '30px',
        backgroundColor: "#fff",
        padding: 1,
        borderRadius: '8px',
        marginTop: "15px",
        marginLeft: "15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "10px",
        ":disabled": true,
        "& .MuiFormControlLabel-label": {
            fontFamily: 'Eudoxus Sans', // Set font family
            fontSize: "12px", // Set font size to 12px
            fontWeight: 500, // Set font weight
            lineHeight: "16px", // Set line height
            textAlign: "left", // Set text align
        },
    }));

    const handleEVChange = (event) => {
        // if (mapZoom < 11) {
        //     ToastNotification("warn", "You must zoom in to view large projects");
        //     console.log("You must zoom in to view large projects", event);
        //     event.target.checked = false;
        //     return;
        // }
        setViewLarge((prev) => !prev);
        setLargeProjects((prev) => !prev);
        if (!activeLayers?.includes('large_projects')) {
            dispatch(appendNewLayer({ layerName: 'large_projects' }));
          } else {
            dispatch(removeLayer({ layerName: 'large_projects' }));
          }
    };

    return (
        <div ref={modalRef}>
            <div 
                className="largeProjecttoggler"
                title={mapZoom < 11 ? "Zoom in further to see large projects" : ""}
            >
                <CustomFormControlLabel
                    control={
                        <CustomColorSwitch
                            disabled={mapZoom < 11}
                            defaultChecked={viewLarge}
                            onChange={handleEVChange}
                        />
                    }
                    label="Show Large Projects"
                    labelPlacement="start"
                />
            </div>
        </div>
    );
};

export default LargeProjectLayerControl;
